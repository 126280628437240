import { gql } from '@apollo/client';

export const HARDWARE_SALE_LINE_ITEM_FIELDS_FRAGMENT = gql`
  fragment HardwareSaleLineItemFields on HardwareSaleLineItem {
    id
    createdAt
    updatedAt
    deletedAt
    description
    price
    hardwareId
    hardware {
      id
      cost
      physicalId
      hardwareType {
        id
        typeName
      }
    }
  }
`;

export const HARDWARE_SALE_FIELDS_FRAGMENT = gql`
  fragment HardwareSaleFields on HardwareSale {
    id
    updatedAt
    createdAt
    deletedAt
    description
    invoiceDate
    invoiceLink
    itemCount
    totalPrice
    clientId
    client {
      id
      name
    }
    hardwareSaleLineItems {
      nodes {
        ...HardwareSaleLineItemFields
      }
    }
  }
  ${HARDWARE_SALE_LINE_ITEM_FIELDS_FRAGMENT}
`;

export const GET_HARDWARE_SALES = gql`
  query GetHardwareSales(
    $first: Int = 20
    $after: Cursor
    $filter: HardwareSaleFilter
    $orderBy: [HardwareSalesOrderBy!] = [ID_DESC]
  ) {
    hardwareSales(first: $first, after: $after, filter: $filter, orderBy: $orderBy) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...HardwareSaleFields
        }
      }
    }
  }
  ${HARDWARE_SALE_FIELDS_FRAGMENT}
`;

export const GET_HARDWARE_SALE = gql`
  query GetHardwareSale($id: Int!) {
    hardwareSale(id: $id) {
      ...HardwareSaleFields
    }
  }
  ${HARDWARE_SALE_FIELDS_FRAGMENT}
`;

export const CREATE_HARDWARE_SALE = gql`
  mutation CreateHardwareSale($description: String, $clientId: Int!) {
    createHardwareSale(input: { hardwareSale: { description: $description, clientId: $clientId } }) {
      hardwareSale {
        ...HardwareSaleFields
      }
    }
  }
  ${HARDWARE_SALE_FIELDS_FRAGMENT}
`;

export const UPDATE_HARDWARE_SALE = gql`
  mutation UpdateHardwareSale($id: Int!, $description: String, $invoiceDate: Datetime, $invoiceLink: String) {
    updateHardwareSale(
      input: { id: $id, patch: { description: $description, invoiceDate: $invoiceDate, invoiceLink: $invoiceLink } }
    ) {
      hardwareSale {
        ...HardwareSaleFields
      }
    }
  }
  ${HARDWARE_SALE_FIELDS_FRAGMENT}
`;

export const DELETE_HARDWARE_SALE = gql`
  mutation DeleteHardwareSale($id: Int!) {
    deleteHardwareSale(input: { id: $id }) {
      hardwareSale {
        ...HardwareSaleFields
      }
    }
  }
  ${HARDWARE_SALE_FIELDS_FRAGMENT}
`;

export const CREATE_HARDWARE_SALE_LINE_ITEM = gql`
  mutation CreateHardwareSaleLineItem($price: BigFloat!, $hardwareId: Int!, $hardwareSaleId: Int!) {
    createHardwareSaleLineItem(
      input: { hardwareSaleLineItem: { price: $price, hardwareId: $hardwareId, hardwareSaleId: $hardwareSaleId } }
    ) {
      hardwareSaleLineItem {
        ...HardwareSaleLineItemFields
      }
    }
  }
  ${HARDWARE_SALE_LINE_ITEM_FIELDS_FRAGMENT}
`;
export const UPDATE_HARDWARE_SALE_LINE_ITEM = gql`
  mutation UpdateHardwareSaleLineItem($id: Int!, $price: BigFloat!) {
    updateHardwareSaleLineItem(input: { id: $id, patch: { price: $price } }) {
      hardwareSaleLineItem {
        ...HardwareSaleLineItemFields
      }
    }
  }
  ${HARDWARE_SALE_LINE_ITEM_FIELDS_FRAGMENT}
`;

export const DELETE_HARDWARE_SALE_LINE_ITEM = gql`
  mutation DeleteHardwareSaleLineItem($id: Int!) {
    deleteHardwareSaleLineItem(input: { id: $id }) {
      hardwareSaleLineItem {
        ...HardwareSaleLineItemFields
      }
    }
  }
  ${HARDWARE_SALE_LINE_ITEM_FIELDS_FRAGMENT}
`;
