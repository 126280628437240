import React from 'react';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';

import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button } from 'antd';

import { resetPassword as ResetPasswordSchema } from '../../yup-schemas';
import { RESET_PASSWORD } from '../../queries/user';

import FormikInputGroup from './formik-input-group';
import { Input } from './formik-ant';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';

function ResetPasswordForm(props) {
  const { onSuccess } = props;
  const [resetPassword] = useMutation(RESET_PASSWORD);

  async function handleFormSubmit(values, formikProps) {
    formikProps.setSubmitting(true);

    try {
      await resetPassword({ variables: { ...values } });

      formikProps.resetForm();
      notification.success({ message: 'Password reset sent successfully' });
      if (onSuccess) {
        onSuccess();
      }
    } catch (resetPasswordError) {
      notification.error({ message: `Password reset failed with error: ${resetPasswordError.message}` });
      setAnyFormErrorsFromGraphQlError(resetPasswordError, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{}}
      validationSchema={ResetPasswordSchema}
      validateOnChange={false}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        return (
          <Form>
            <FormikInputGroup
              label="Email"
              name="email"
              component={Input}
              placeholder="ex: tim@getmeadow.com"
              formikProps={formikProps}
            />

            <div className="form__actions">
              <span className="form__action">
                <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                  Send Reset Link
                </Button>
              </span>
              <span className="form__action">
                <Link to="/login">Wait, I remembered</Link>
              </span>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default ResetPasswordForm;
