import React from 'react';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';

import { Formik, Form } from 'formik';
import { Button } from 'antd';

import { updateAccount as UpdateAccountSchema } from '../../yup-schemas';
import FormikInputGroup from './formik-input-group';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import { UPDATE_ACCOUNT_PERMISSIONS } from '../../queries/account';
import { FormikAccountPermissionSelector } from '../account-permission-selector';

function AccountPermissionForm(props) {
  const { account, onSuccess } = props;
  const [updateAccountPermissions] = useMutation(UPDATE_ACCOUNT_PERMISSIONS);

  async function handleFormSubmit(values, formikProps) {
    const { id } = account;
    const { setSubmitting } = formikProps;

    setSubmitting(true);
    try {
      const updateAccountResponse = await updateAccountPermissions({
        variables: { id, ...values },
        refetchQueries: ['GetAccounts', 'GetMe']
      });

      notification.success({ message: 'Account updated successfully' });
      if (onSuccess) {
        onSuccess(updateAccountResponse.data?.updateAccountPermissions.account);
      }
    } catch (updateAccountError) {
      notification.error({ message: `Account update failed with error: ${updateAccountError.message}` });
      setAnyFormErrorsFromGraphQlError(updateAccountError, formikProps);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        permissions: account.accountPermissions.nodes.map(({ permission }) => permission)
      }}
      validationSchema={UpdateAccountSchema}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        return (
          <Form>
            <FormikInputGroup
              label="Permissions"
              name="permissions"
              formikProps={formikProps}
              mode="multiple"
              component={FormikAccountPermissionSelector}
            />
            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                Edit Account
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default AccountPermissionForm;
