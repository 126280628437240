import _ from 'lodash';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { notification } from 'antd';

import { Formik, Form } from 'formik';
import { Avatar, Button, Typography } from 'antd';

import { updateClientSeller as ClientSalespersonSchema } from '../../yup-schemas';

import FormikInputGroup from './formik-input-group';
import { InputPercent, Select } from './formik-ant';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';

import { GET_ACCOUNTS } from '../../queries/account';
import {
  GET_CLIENT_SALESPERSONS,
  CREATE_CLIENT_SALESPERSON,
  UPDATE_CLIENT_SALESPERSON
} from '../../queries/client/salesperson.js';

function getRemainingPercent(clientSalespersons, currentClientSalesperson) {
  return _.reduce(
    clientSalespersons,
    (remainingPercent, clientSalesperson) => {
      if (clientSalesperson.accountId === currentClientSalesperson.accountId) {
        return remainingPercent;
      }
      return remainingPercent - Number(clientSalesperson.percent);
    },
    100
  );
}

const defaultValues = {
  percent: 0
};

function ClientSalespersonForm(props) {
  const { clientSalesperson, clientId, onSuccess } = props;
  const getAccountsResponse = useQuery(GET_ACCOUNTS);
  const getClientSalespersonsResponse = useQuery(GET_CLIENT_SALESPERSONS, { variables: { clientId } });
  const [createClientSalesperson] = useMutation(CREATE_CLIENT_SALESPERSON);
  const [updateClientSalesperson] = useMutation(UPDATE_CLIENT_SALESPERSON);
  const isEditing = !_.isEmpty(clientSalesperson);

  if (getAccountsResponse.loading || getClientSalespersonsResponse.loading) {
    return <div>Loading...</div>;
  }

  const accounts = _.get(getAccountsResponse, 'data.accounts.nodes');
  const clientSalespersons = _.map(
    _.get(getClientSalespersonsResponse, 'data.clientSellers.edges'),
    ({ node }) => node
  );

  function validate(values) {
    const errors = {};
    const remainingPercent = getRemainingPercent(clientSalespersons, values);

    if (values.percent > remainingPercent) {
      errors.percent = 'Total percentage of all execs cannot exceed 100%';
    }

    return errors;
  }

  async function handleFormSubmit(values, formikProps) {
    const { accountId, percent } = values;
    const { setSubmitting, resetForm } = formikProps;
    const id = _.get(clientSalesperson, 'id');
    const mutation = isEditing ? updateClientSalesperson : createClientSalesperson;
    const verb = isEditing ? 'update' : 'create';

    setSubmitting(true);

    try {
      const mutationResponse = await mutation({
        variables: { id, clientId, accountId: _.parseInt(accountId), percent },
        refetchQueries: ['GetClientSalespersons']
      });

      notification.success({ message: 'Account Exec updated successfully' });
      if (onSuccess) {
        const clientData = _.get(mutationResponse, `data.${verb}ClientSalesperson.client`);
        onSuccess(clientData);
      }
      if (!isEditing) {
        resetForm();
      }
    } catch (mutationError) {
      notification.error({ message: `Account Exec ${verb} failed with error: ${mutationError.message}` });
      setAnyFormErrorsFromGraphQlError(mutationError, formikProps);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        ...defaultValues,
        ..._.pick(clientSalesperson, ['accountId', 'percent'])
      }}
      validationSchema={ClientSalespersonSchema}
      validate={validate}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        return (
          <Form>
            <Typography.Title level={2}>
              {isEditing && `Edit Salesperson: ${clientSalesperson.account.name}`}
              {!isEditing && 'Create Account Exec'}
            </Typography.Title>

            <FormikInputGroup
              name="accountId"
              label="Team Member"
              component={Select}
              placeholder="Select a team member"
              disabled={isEditing}
              formikProps={formikProps}
            >
              {_.map(accounts, account => {
                const isClientSalesperson = !!_.find(
                  clientSalespersons,
                  clientSalesperson => clientSalesperson.accountId === account.id
                );

                return (
                  <Select.Option value={account.id} key={account.id} disabled={isClientSalesperson}>
                    <Avatar size={16} src={`https://api.adorable.io/avatars/285/${account.id}@adorable.io.png`} />{' '}
                    {account.name}
                  </Select.Option>
                );
              })}
            </FormikInputGroup>

            <FormikInputGroup name="percent" label="Percent" component={InputPercent} formikProps={formikProps} />

            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                {isEditing && 'Edit Account Exec'}
                {!isEditing && 'Create Account Exec'}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default ClientSalespersonForm;
