import _ from 'lodash';
import React, { useState } from 'react';
import { Select } from 'antd';
import { GET_CLIENTS } from '../queries/client/client-queries';
import { GET_HARDWARES } from '../queries/hardware/hardware-stock-queries';
import { GET_HARDWARE_TYPES } from '../queries/hardware/hardware-type-queries';
import { useQuery } from '@apollo/client';
import { wrapFetchMore } from './paginated-query';
import { withFormikAnt } from '../hocs';
import { GET_CLIENT_ORGANIZATIONS } from '../queries/client/client-organization-queries';

function SearchableSelector(props) {
  const { query, variables: staticVariables, renderNode, fieldName, ...passThroughProps } = props;
  const [variables, setVariables] = useState({});
  const result = useQuery(query, {
    variables: { ...staticVariables, ...variables },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  const { data, loading } = result;
  const fetchMore = wrapFetchMore(result);
  const [connectionKey] = Object.keys(data || {});
  const list = _.get(data, `[${connectionKey}].edges`, []).map(({ node }) => node);
  const hasNextPage = _.get(data, `[${connectionKey}].pageInfo.hasNextPage`, []);

  async function handleSearch(value) {
    if (value) {
      setVariables({ filter: { [fieldName]: { includesInsensitive: value } } });
    } else {
      setVariables({});
    }
  }

  return (
    <Select
      showSearch
      loading={loading}
      onSearch={_.debounce(handleSearch, 100)}
      filterOption={false}
      onPopupScroll={e => {
        e.persist();
        const { scrollTop, offsetHeight, scrollHeight } = e.target;
        if (scrollTop + offsetHeight === scrollHeight) {
          if (hasNextPage) {
            fetchMore();
          }
        }
      }}
      {...passThroughProps}
    >
      {list.map(node => (
        <Select.Option value={node.id} key={node.id}>
          {renderNode(node)}
        </Select.Option>
      ))}
    </Select>
  );
}

export function ClientSelector(props) {
  const { variables: propVariables, ...passThroughProps } = props;
  const variables = { includeArchived: 'YES', ...propVariables };
  return (
    <SearchableSelector
      query={GET_CLIENTS}
      fieldName="name"
      variables={variables}
      renderNode={node => node.name}
      {...passThroughProps}
    />
  );
}

export const FormikClientSelector = withFormikAnt()(ClientSelector);

export function HardwareTypeSelector(props) {
  return (
    <SearchableSelector query={GET_HARDWARE_TYPES} fieldName="typeName" renderNode={node => node.typeName} {...props} />
  );
}

export const FormikHardwareTypeSelector = withFormikAnt()(HardwareTypeSelector);
export function HardwareSelector(props) {
  return (
    <SearchableSelector
      query={GET_HARDWARES}
      fieldName="physicalId"
      renderNode={node => `${node.hardwareType.typeName} ${node.physicalId || '(No Physical ID)'} (#${node.id})`}
      {...props}
    />
  );
}

export const FormikHardwareSelector = withFormikAnt()(HardwareSelector);

export function HardwareSegmentedSelector(props) {
  const { variables: staticVariables = {}, hardwareTypeId, setHardwareTypeId, ...passThroughProps } = props;
  const sharedProps = _.pick(props, ['disabled']);
  const variables = hardwareTypeId
    ? { filter: { ...staticVariables.filter, hardwareTypeId: { equalTo: hardwareTypeId } } }
    : null;
  return (
    <>
      <HardwareTypeSelector
        allowClear
        {...sharedProps}
        value={hardwareTypeId}
        onChange={v => setHardwareTypeId(v)}
        placeholder="Choose a hardware type"
      />
      <HardwareSelector {...passThroughProps} variables={{ ...staticVariables, ...variables }} />
    </>
  );
}

export const FormikHardwareSegmentedSelector = withFormikAnt()(HardwareSegmentedSelector);

export function OrganizationSelector(props) {
  const { clientId, variables, ...passThroughProps } = props;
  if (!variables.includeArchived) {
    variables.includeArchived = 'YES';
  }
  if (clientId) {
    variables.filter = {
      ...variables.filter,
      clientId: { equalTo: clientId }
    };
  }
  return (
    <SearchableSelector
      query={GET_CLIENT_ORGANIZATIONS}
      fieldName="organizationId"
      variables={variables}
      renderNode={node => `${node.organizationId} - ${node.description}`}
      {...passThroughProps}
    />
  );
}

export const FormikOrganizationSelector = withFormikAnt()(OrganizationSelector);
