import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import fecha from 'fecha';

const DEFAULT_FORMAT = 'h:mmA MMM D, YYYY';

function Timestamp(props) {
  const { datetime, className, format } = props;
  const parsedDatetime = _.isString(datetime) ? new Date(datetime) : datetime;

  return <span className={`timestamp ${className}`}>{fecha.format(parsedDatetime, format)}</span>;
}

Timestamp.propTypes = {
  className: PropTypes.string,
  format: PropTypes.string
};

Timestamp.defaultProps = {
  className: '',
  format: DEFAULT_FORMAT
};

export default Timestamp;
