import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { notification, Card, Popconfirm } from 'antd';

import getFileIconFromMimeType from '../utils/get-file-icon-name';
import { DELETE_FILE } from '../queries/file-queries';
import RequirePermission from './require-permission';
import { DeleteOutlined } from '@ant-design/icons';

function FileCard(props) {
  const {
    node: { id, filename, mimetype, signedUrl },
    refetchQueries,
    ...passThroughProps
  } = props;
  const iconType = getFileIconFromMimeType(mimetype);
  const [loading, setLoading] = useState(false);
  const [deleteFile] = useMutation(DELETE_FILE);

  async function handleDeleteClick() {
    try {
      await deleteFile({
        variables: { id },
        refetchQueries
      });
      notification.success({
        message: `${filename} deleted successfully.`
      });
    } catch (err) {
      notification.error({
        message: `${filename} could not be deleted due to an error: ${err.message}`
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Card
        loading={loading}
        actions={[
          <RequirePermission permission="MANAGE_ACCOUNTS">
            <Popconfirm title={`Are you sure you want to delete ${filename}?`} onConfirm={handleDeleteClick}>
              <DeleteOutlined /> Delete
            </Popconfirm>
          </RequirePermission>
        ]}
        {...passThroughProps}
      >
        <Card.Meta
          avatar={iconType}
          title={
            <a href={signedUrl} target="_blank" rel="noopener noreferrer">
              {filename}
            </a>
          }
        />
      </Card>
    </>
  );
}

FileCard.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired
  }),
  refetchQueries: PropTypes.array.isRequired
};

export default FileCard;
