import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/client';
import { notification, Avatar, Card, Modal, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import ClientSalespersonForm from '../forms/client-salesperson';
import { DELETE_CLIENT_SALESPERSON } from '../../queries/client/salesperson';
import RequirePermission from '../require-permission';

function Salesperson(props) {
  const {
    clientSalesperson,
    clientSalesperson: { id, account, percent },
    clientId
  } = props;
  const [deleteClientSalesperson] = useMutation(DELETE_CLIENT_SALESPERSON);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const handleEditClick = () => {
    setIsEditModalVisible(!isEditModalVisible);
  };

  const handleDeleteClick = () => {
    deleteClientSalesperson({
      variables: { id },
      refetchQueries: ['GetClientSalespersons']
    })
      .then(() => {
        notification.success({
          message: 'Account Exec deleted successfully.'
        });
      })
      .catch(error => {
        notification.error({
          message: `Account Exec deletion failed with error: ${error.message}`
        });
      });
  };

  const handleEditSuccess = () => {
    setIsEditModalVisible(false);
  };

  return (
    <Fragment>
      <Card
        actions={[
          <span onClick={handleEditClick}>
            <EditOutlined /> Edit
          </span>,
          <RequirePermission permission="MANAGE_ACCOUNTS">
            <Popconfirm title={`Are you sure you want to delete ${account.name}?`} onConfirm={handleDeleteClick}>
              <DeleteOutlined /> Delete
            </Popconfirm>
          </RequirePermission>
        ]}
      >
        <Card.Meta
          avatar={<Avatar src={`https://api.adorable.io/avatars/285/${account.id}@adorable.io.png`} />}
          title={<span>{account.name}</span>}
          description={<div>{percent} %</div>}
        />
      </Card>
      <Modal footer={null} centered visible={isEditModalVisible} onCancel={() => setIsEditModalVisible(false)}>
        <ClientSalespersonForm
          clientId={clientId}
          clientSalesperson={clientSalesperson}
          onSuccess={handleEditSuccess}
        />
      </Modal>
    </Fragment>
  );
}

export default Salesperson;
