import React from 'react';
import moment from 'moment';

import { DatePicker as AntDatePicker } from 'antd';

function DatePicker(props) {
  const { field, form, ...passthroughProps } = props;

  return (
    <AntDatePicker
      name={field.name}
      value={field.value && moment(field.value)}
      onChange={date => {
        if (date) {
          form.setFieldValue(field.name, moment(date).format('YYYY-MM-DD'));
        } else {
          form.setFieldValue(field.name, null);
        }
      }}
      onBlur={() => {
        form.setFieldTouched(field.name);
      }}
      {...passthroughProps}
    />
  );
}

export default DatePicker;
