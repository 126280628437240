import { gql } from '@apollo/client';

export const CLIENT_CONTACT_FRAGMENT = gql`
  fragment ClientContactFields on ClientContact {
    id
    clientId
    createdAt
    deletedAt
    email
    firstName
    lastName
    phone
    title
    isPrimary
    isBilling
  }
`;
export const GET_CLIENT_CONTACTS = gql`
  query GetClientContacts($first: Int = 20, $after: Cursor, $clientId: Int!) {
    clientContacts(
      first: $first
      after: $after
      filter: { clientId: { equalTo: $clientId }, deletedAt: { isNull: true } }
      orderBy: [ID_DESC]
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...ClientContactFields
        }
      }
    }
  }
  ${CLIENT_CONTACT_FRAGMENT}
`;

export const CREATE_CLIENT_CONTACT = gql`
  mutation CreateClientContact(
    $clientId: Int!
    $firstName: String!
    $lastName: String!
    $phone: String
    $email: String
    $title: String
    $isPrimary: Boolean
    $isBilling: Boolean
  ) {
    createClientContact(
      input: {
        clientContact: {
          clientId: $clientId
          firstName: $firstName
          lastName: $lastName
          phone: $phone
          email: $email
          title: $title
          isPrimary: $isPrimary
          isBilling: $isBilling
        }
      }
    ) {
      clientContact {
        ...ClientContactFields
      }
    }
  }
  ${CLIENT_CONTACT_FRAGMENT}
`;

export const UPDATE_CLIENT_CONTACT = gql`
  mutation UpdateClientContact(
    $id: Int!
    $firstName: String!
    $lastName: String!
    $phone: String
    $email: String
    $title: String
    $isPrimary: Boolean
    $isBilling: Boolean
  ) {
    updateClientContact(
      input: {
        patch: {
          firstName: $firstName
          lastName: $lastName
          phone: $phone
          email: $email
          title: $title
          isPrimary: $isPrimary
          isBilling: $isBilling
        }
        id: $id
      }
    ) {
      clientContact {
        ...ClientContactFields
      }
    }
  }
  ${CLIENT_CONTACT_FRAGMENT}
`;

export const DELETE_CLIENT_CONTACT = gql`
  mutation DeleteClientContact($id: Int!) {
    deleteClientContact(input: { id: $id }) {
      clientContact {
        ...ClientContactFields
      }
    }
  }
  ${CLIENT_CONTACT_FRAGMENT}
`;
