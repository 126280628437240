import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Checkbox, Button, Table, Typography, Skeleton, notification } from 'antd';
import { useMutation } from '@apollo/client';

import getLoadingState from '../utils/get-loading-state';
import PaginatedQuery from '../components/paginated-query';
import SectionHeader from '../components/section-header';
import {
  GET_HARDWARE_LEASE_LINE_ITEMS,
  UPDATE_HARDWARE_LEASE_LINE_ITEM
} from '../queries/hardware/hardware-lease-queries';
import { renderMoney } from '../utils/money';
import PageError from '../components/page-error';
import { HardwareTypeSelector } from '../components/searchable-selectors';
import SelectFilter, { isFiltered } from '../components/tables/select-filter';
import SearchFilter from '../components/tables/search-filter';
import { SearchOutlined } from '@ant-design/icons';

function ReturnHardwareCheckBox(props) {
  const [loading, setLoading] = useState(false);
  const [updateHardwareLeaseLineItem] = useMutation(UPDATE_HARDWARE_LEASE_LINE_ITEM);
  async function handleChange(e) {
    setLoading(true);
    try {
      await updateHardwareLeaseLineItem({
        variables: { id: props.node.id, returned: e.target.checked },
        refetchQueries: [{ query: GET_HARDWARE_LEASE_LINE_ITEMS }]
      });
      notification.success({
        message: 'Successfully marked hardware as returned'
      });
    } catch (err) {
      notification.success({
        message: `Marking hardware as returned failed with error: ${err.message}`
      });
    } finally {
      setLoading(false);
    }
  }
  return <Checkbox onChange={handleChange} checked={props.node.returned} loading={loading} />;
}
ReturnHardwareCheckBox.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    returned: PropTypes.bool.isRequired
  })
};

function HardwarePendingReturnListPage(props) {
  const [variables, setVariables] = useState({});

  return (
    <PaginatedQuery query={GET_HARDWARE_LEASE_LINE_ITEMS} variables={variables} notifyOnNetworkStatusChange={true}>
      {getHardwaresResponse => {
        let content;

        const { data, error, loading, fetchMore } = getHardwaresResponse;
        const loadingState = getLoadingState(getHardwaresResponse);

        if (error) {
          return <PageError error={error} />;
        }

        if (loadingState === 'FIRST_LOADING') {
          content = <Skeleton />;
        } else {
          const hardwares = _.get(data, 'hardwareLeaseLineItems.edges', []).map(({ node }) => ({
            ...node,
            key: node.id
          }));

          content = (
            <div>
              <Table dataSource={hardwares} pagination={false} size="middle" loading={loading} bordered>
                <Table.Column
                  title="Lease"
                  dataIndex="hardwareLeaseId"
                  key="hardwareLeaseId"
                  render={id => <Link to={`/hardware-lease/${id}`}>#{id}</Link>}
                  filterDropdown={
                    <SearchFilter fieldName="hardwareLeaseId" variables={variables} setVariables={setVariables} />
                  }
                  filterIcon={<SearchOutlined />}
                />
                <Table.Column
                  title="Type"
                  dataIndex="hardware"
                  key="hardwareType"
                  render={node => node.hardwareType.typeName}
                  filtered={isFiltered({ fieldName: 'hardwareTypeId', variables })}
                  filterDropdown={
                    <SelectFilter
                      fieldName="hardwareTypeId"
                      Selector={HardwareTypeSelector}
                      variables={variables}
                      setVariables={setVariables}
                    />
                  }
                />
                <Table.Column
                  title="Physical ID"
                  dataIndex="hardware"
                  key="physicalId"
                  render={node => node.physicalId}
                />
                <Table.Column title="Cost" dataIndex="hardware" key="cost" render={node => renderMoney(node.cost)} />
                <Table.Column title="Returned" key="returned" render={node => <ReturnHardwareCheckBox node={node} />} />
              </Table>
              <Button
                block
                loading={loading}
                size="large"
                disabled={!_.get(data, 'hardwares.pageInfo.hasNextPage')}
                onClick={() => fetchMore()}
              >
                Load More
              </Button>
              <Typography.Paragraph>
                If you accidentaly return an item you can unreturn it by going to the{' '}
                <Link to="/hardware-leases">lease</Link>
              </Typography.Paragraph>
            </div>
          );
        }

        return (
          <div>
            <SectionHeader
              left={<Typography.Title style={{ marginBottom: 0 }}>Hardware Pending Return</Typography.Title>}
            />
            {content}
          </div>
        );
      }}
    </PaginatedQuery>
  );
}

export default HardwarePendingReturnListPage;
