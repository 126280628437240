import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import produce from 'immer';
import _ from 'lodash';
import { useState } from 'react';
import PropTypes from 'prop-types';

export default function SelectFilter({
  Selector,
  variables,
  setVariables,
  fieldName,
  multiple = true,
  dateRange = false,
  array = false
}) {
  function getInitialValue() {
    return getFiltersFromVariables({ variables, fieldName, multiple, dateRange, array });
  }

  const [value, setValue] = useState(getInitialValue);

  function handleSelectChange(option) {
    setValue(option);
  }

  function updateStateWithFilter(state, filterValue) {
    if (filterValue) {
      if (dateRange) {
        state.filter = {
          ...state.filter,
          and: [
            { [fieldName]: { greaterThanOrEqualTo: filterValue[0] } },
            { [fieldName]: { lessThanOrEqualTo: filterValue[1] } }
          ]
        };
      } else if (array) {
        state.filter = {
          ...state.filter,
          [fieldName]: { contains: filterValue }
        };
      } else if (multiple) {
        state.filter = {
          ...state.filter,
          [fieldName]: { in: filterValue }
        };
      } else {
        state.filter = {
          ...state.filter,
          [fieldName]: { equalTo: filterValue }
        };
      }
    } else {
      if (state.filter) {
        if (dateRange) {
          // note could cause bugs if caller sets and filters
          delete state.filter.and;
        } else {
          delete state.filter[fieldName];
        }
        if (_.isEmpty(state.filter)) {
          delete state.filter;
        }
      }
    }
  }

  function updateVariables(searchValue) {
    setVariables(produce(state => updateStateWithFilter(state, searchValue)));
  }

  function handleReset() {
    updateVariables(null);
  }

  function handleSubmit() {
    updateVariables(value);
  }

  return (
    <div style={{ padding: 8, display: 'flex' }}>
      <div style={{ display: 'flex', flexDirection: 'column', flex: '0 0 auto' }}>
        <Selector
          autoFocus
          style={{ minWidth: 200 }}
          mode={dateRange ? [] : multiple ? 'multiple' : 'default'}
          value={value}
          onChange={handleSelectChange}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: '0 0 auto', marginLeft: 16 }}>
        <Button
          type="primary"
          onClick={handleSubmit}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8, marginBottom: 8, flex: '0 0 auto' }}
        >
          Search
        </Button>
        <Button onClick={handleReset} size="small" style={{ width: 90, flex: '0 0 auto' }}>
          Reset
        </Button>
      </div>
    </div>
  );
}

function getFiltersFromVariables({ variables, fieldName, dateRange = false, array = false, multiple = true }) {
  if (!variables?.filter) {
    return undefined;
  }
  const dateFilter0 = variables.filter.and?.[0]?.[fieldName]?.greaterThanOrEqualTo;
  const dateFilter1 = variables.filter.and?.[1]?.[fieldName]?.lessThanOrEqualTo;
  if (dateRange && dateFilter0 && dateFilter1) {
    return [dateFilter0, dateFilter1];
  } else if (array) {
    return variables.filter[fieldName]?.contains;
  } else if (multiple) {
    return variables.filter[fieldName]?.in;
  } else {
    return variables.filter[fieldName]?.equalTo;
  }
}

export function isFiltered({ variables, fieldName, dateRange, array, multiple }) {
  const filters = getFiltersFromVariables({ variables, fieldName, dateRange, array, multiple });
  const isFiltered = typeof filters !== 'undefined';
  return isFiltered;
}

SelectFilter.propTypes = {
  variables: PropTypes.any,
  setVariables: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired
};
