import React from 'react';
import { Select } from 'antd';
import { PERMISSIONS } from '../constants';
import { withFormikAnt } from '../hocs';

export default function AccountPermissionSelector(props) {
  return (
    <Select {...props}>
      {PERMISSIONS.map(state => (
        <Select.Option key={state.value} value={state.value}>
          {state.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export const FormikAccountPermissionSelector = withFormikAnt()(AccountPermissionSelector);
