import { gql } from '@apollo/client';

export const GET_CLIENT_SALESPERSONS = gql`
  query GetClientSalespersons($first: Int = 20, $after: Cursor, $clientId: Int!) {
    clientSellers(first: $first, after: $after, filter: { clientId: { equalTo: $clientId } }, orderBy: [ID_DESC]) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          account {
            name
            email
            id
          }
          accountId
          createdAt
          deletedAt
          id
          percent
          updatedAt
        }
      }
    }
  }
`;

export const CREATE_CLIENT_SALESPERSON = gql`
  mutation CreateClientSalesperson($clientId: Int!, $percent: BigFloat!, $accountId: Int!) {
    createClientSeller(input: { clientSeller: { clientId: $clientId, percent: $percent, accountId: $accountId } }) {
      clientSeller {
        account {
          name
          email
          id
        }
        accountId
        createdAt
        deletedAt
        id
        percent
        updatedAt
      }
    }
  }
`;

export const UPDATE_CLIENT_SALESPERSON = gql`
  mutation UpdateClientSalesperson($id: Int!, $percent: BigFloat, $accountId: Int) {
    updateClientSeller(input: { patch: { percent: $percent, accountId: $accountId }, id: $id }) {
      clientSeller {
        account {
          name
          email
          id
        }
        accountId
        createdAt
        deletedAt
        id
        percent
        updatedAt
      }
    }
  }
`;

export const DELETE_CLIENT_SALESPERSON = gql`
  mutation DeleteClientSalesperson($id: Int!) {
    deleteClientSeller(input: { id: $id }) {
      clientSeller {
        account {
          name
          email
          id
        }
        accountId
        createdAt
        deletedAt
        id
        percent
        updatedAt
      }
    }
  }
`;
