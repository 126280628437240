import { FileImageOutlined, FilePdfOutlined, FileZipOutlined, FileTextOutlined, FileOutlined } from '@ant-design/icons';

export default function getFileIconFromMimeType(fileType) {
  if (typeof fileType === 'string') {
    if (/^image\//.test(fileType)) {
      return <FileImageOutlined />;
    }
    if (/pdf/.test(fileType)) {
      return <FilePdfOutlined />;
    }
    if (/compressed|zip/.test(fileType)) {
      return <FileZipOutlined />;
    }
    if (/txt|text/.test(fileType)) {
      return <FileTextOutlined />;
    }
  }

  return <FileOutlined />;
}
