import React from 'react';

import { Link, NavLink, withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { TeamOutlined, DollarOutlined, ShopOutlined, TableOutlined } from '@ant-design/icons';

import UserMenu from '../user-menu';

function AuthenticatedLayout(props) {
  const { children, location } = props;
  const pathParts = location.pathname.split('/').filter(v => !!v);
  const path = pathParts.length > 0 ? pathParts[0] : '';
  const selectedKey = `/${path}`;
  // relies on /submenu-menutem type links
  // ie /hardware-stock
  const openKeys = path.match('-') ? [path.split('-')[0]] : [];

  return (
    <Layout className="app" hasSider={true}>
      <Layout.Sider breakpoint="md" collapsedWidth="0" width={250}>
        <Link to="/">
          <img className="app__logo" src="/meadow-logo-white.svg" alt="Meadow Internal Tools" />
        </Link>
        <UserMenu />
        <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]} defaultOpenKeys={openKeys}>
          <Menu.SubMenu key="billing" icon={<DollarOutlined />} title="Billing">
            <Menu.Item key="/billing-monthly-rates">
              <NavLink to="/billing-monthly-rates">Monthly Rates</NavLink>
            </Menu.Item>
            <Menu.Item key="/billing-monthly-line-items">
              <NavLink to="/billing-monthly-line-items">Monthly Misc</NavLink>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key="clients" icon={<ShopOutlined />} title="Clients">
            <Menu.Item key="/clients">
              <NavLink to="/clients">All Clients</NavLink>
            </Menu.Item>
            <Menu.Item key="/organizations">
              <NavLink to="/organizations">Organizations</NavLink>
            </Menu.Item>
            <Menu.Item key="/contracts">
              <NavLink to="/contracts">Contracts</NavLink>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key="hardware" icon={<TableOutlined />} title="Hardware">
            <Menu.Item key="/hardware-types">
              <NavLink to="/hardware-types">Types</NavLink>
            </Menu.Item>
            <Menu.Item key="/hardware-stock">
              <NavLink to="/hardware-stock">Stock</NavLink>
            </Menu.Item>
            <Menu.Item key="/hardware-sales">
              <NavLink to="/hardware-sales">Sales</NavLink>
            </Menu.Item>
            <Menu.Item key="/hardware-leases">
              <NavLink to="/hardware-leases">Leases</NavLink>
            </Menu.Item>
            <Menu.Item key="/hardware-pending-return">
              <NavLink to="/hardware-pending-return">Pending Returns</NavLink>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="/team-meadow" icon={<TeamOutlined />}>
            <NavLink to="/team-meadow">Team Meadow</NavLink>
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      <Layout>
        <Layout.Content className="app__content">{children}</Layout.Content>
      </Layout>
    </Layout>
  );
}

export default withRouter(AuthenticatedLayout);
