import _ from 'lodash';
import React, { useState } from 'react';

import { Button, Modal, Typography, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import PaginatedQuery from '../paginated-query';
import SectionHeader from '../section-header';
import ClientSalespersonForm from '../forms/client-salesperson';
import ClientSalesperson from './salesperson';

import { GET_CLIENT_SALESPERSONS } from '../../queries/client/salesperson';

function ClientSalespersons(props) {
  const { id } = props;
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const handleClientSalespersonCreateSuccess = () => {
    setIsCreateModalVisible(false);
  };

  return (
    <div className="client-salespersons">
      <SectionHeader
        left={
          <Typography.Title level={2} style={{ marginBottom: 0 }}>
            Account Execs
          </Typography.Title>
        }
        right={
          <Button type="secondary" size="small" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Add Exec
          </Button>
        }
      />
      <PaginatedQuery
        query={GET_CLIENT_SALESPERSONS}
        variables={{ clientId: id, first: 9 }}
        notifyOnNetworkStatusChange={true}
      >
        {getClientSalespersonsResponse => {
          let content;
          const { data, networkStatus } = getClientSalespersonsResponse;

          // loading state
          if (networkStatus === 1) {
            content = <Skeleton />;
          }
          // empty state
          else if (
            networkStatus === 7 &&
            _.isEmpty(_.get(data, 'clientSellers.edges')) &&
            !_.get(data, 'clientSellers.pageInfo.hasNextPage')
          ) {
            content = <p>No account execs found</p>;
          }
          // results state
          else {
            const clientSalespersons = _.map(data?.clientSellers?.edges, ({ node }) => ({ ...node, key: node.id }));

            content = _.map(clientSalespersons, clientSalesperson => {
              return (
                <ClientSalesperson clientSalesperson={clientSalesperson} clientId={id} key={clientSalesperson.id} />
              );
            });
          }

          return <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '15px' }}>{content}</div>;
        }}
      </PaginatedQuery>
      <Modal footer={null} centered visible={isCreateModalVisible} onCancel={() => setIsCreateModalVisible(false)}>
        <ClientSalespersonForm clientId={id} onSuccess={handleClientSalespersonCreateSuccess} />
      </Modal>
    </div>
  );
}

export default ClientSalespersons;
