import React from 'react';
import * as antd from 'antd';

import { withFormikAnt } from '../../hocs';
import WrappedDatePicker from './date-picker';

export const Input = withFormikAnt()(antd.Input);
Input.TextArea = withFormikAnt()(antd.Input.TextArea);

function MeadowInputNumber(props) {
  return <antd.InputNumber controls={false} {...props} />;
}
export const InputNumber = withFormikAnt()(MeadowInputNumber);
export const Select = withFormikAnt()(antd.Select);

export const DatePicker = WrappedDatePicker;
export const DatetimePicker = props => <DatePicker {...props} showTime />;

Select.Option = antd.Select.Option;
Select.OptGroup = antd.Select.OptGroup;

export const Checkbox = props => {
  const { field, form } = props;
  return (
    <antd.Checkbox
      name={field.name}
      checked={field.value}
      onChange={argument => {
        if (argument && argument.target && typeof argument.target.checked === 'boolean') {
          form.setFieldValue(field.name, argument.target.checked);
        }
      }}
      onBlur={() => {
        form.setFieldTouched(field.name);
      }}
      {...props}
    />
  );
};

export const InputMoney = props => (
  <InputNumber
    {...props}
    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value.replace(/\$\s?|(,*)/g, '')}
  />
);

export const InputPercent = props => (
  <InputNumber {...props} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
);
