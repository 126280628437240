import _ from 'lodash';
import React from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { Button, Typography, notification } from 'antd';

import { createHardwareSale as CreateHardwareSaleSchema } from '../../yup-schemas';

import FormikInputGroup from './formik-input-group';
import { Input, DatePicker } from './formik-ant';
import { FormikClientSelector } from '../searchable-selectors';
import {
  CREATE_HARDWARE_SALE,
  GET_HARDWARE_SALES,
  UPDATE_HARDWARE_SALE
} from '../../queries/hardware/hardware-sale-queries';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';

function HardwareSaleForm(props) {
  const { hardwareSale = {}, onSuccess } = props;
  const [createHardwareSale] = useMutation(CREATE_HARDWARE_SALE);
  const [updateHardwareSale] = useMutation(UPDATE_HARDWARE_SALE);
  const isEditing = !_.isEmpty(hardwareSale);

  async function handleFormSubmit(values, formikProps) {
    const verb = isEditing ? 'update' : 'create';

    try {
      formikProps.setSubmitting(true);
      const mutation = isEditing ? updateHardwareSale : createHardwareSale;

      const mutationResult = await mutation({
        variables: values,
        refetchQueries: [GET_HARDWARE_SALES]
      });

      notification.success({ message: `Hardware sale ${verb}d successfully!` });
      if (onSuccess) {
        const hardware = _.get(mutationResult, `data.${verb}HardwareSale.hardwareSale`);
        onSuccess(hardware);
      }
    } catch (error) {
      notification.error({ message: `Hardware ${verb} failed with error: ${error}.` });
      setAnyFormErrorsFromGraphQlError(error, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        ...hardwareSale,
        invoiceDate: hardwareSale.invoiceDate && moment(hardwareSale.invoiceDate)
      }}
      validationSchema={CreateHardwareSaleSchema}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        return (
          <Form>
            <Typography.Title level={2}>
              {isEditing && `Edit Hardware Sale`}
              {!isEditing && 'Create Hardware Sale'}
            </Typography.Title>

            <FormikInputGroup
              disabled={isEditing}
              name="clientId"
              label="Client"
              component={FormikClientSelector}
              formikProps={formikProps}
            />

            {isEditing && (
              <>
                <FormikInputGroup
                  name="invoiceDate"
                  label="Invoice Date"
                  component={DatePicker}
                  placeholder="Select Date"
                  formikProps={formikProps}
                />

                <FormikInputGroup
                  name="invoiceLink"
                  label="Invoice Link"
                  component={Input}
                  placeholder="ex: https://timz-custom-invoices.net/invoices/420"
                  formikProps={formikProps}
                />
              </>
            )}

            <FormikInputGroup
              name="description"
              label="Description"
              component={Input}
              placeholder="What is the occasion for this sale, a launch or an upgrade?"
              formikProps={formikProps}
            />

            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                {isEditing && 'Edit Hardware Sale'}
                {!isEditing && 'Create Hardware Sale'}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default HardwareSaleForm;
