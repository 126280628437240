import React from 'react';
import PropTypes from 'prop-types';
import { Card, Popconfirm, Descriptions, notification } from 'antd';
import { DeleteOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';

import { renderDatetimeAsDate } from '../../utils/datetime';
import { renderMoney } from '../../utils/money';
import {
  DELETE_CLIENT_CONTRACT,
  GET_CLIENT_CONTRACTS_BY_CLIENT_ID
} from '../../queries/client/client-contract-queries';

function ClientContractCard(props) {
  const { contract, clientId } = props;
  const { id, amount, startDate, endDate } = contract;
  const [deleteContract] = useMutation(DELETE_CLIENT_CONTRACT);

  async function handleDeleteClick() {
    try {
      await deleteContract({
        variables: { id },
        refetchQueries: [{ query: GET_CLIENT_CONTRACTS_BY_CLIENT_ID, variables: { clientId } }]
      });
      notification.success({
        message: 'Contract deleted successfully'
      });
    } catch (err) {
      notification.error({
        message: `Could not delete contract ${err.message}`
      });
    }
  }
  function handleClickDetails() {
    props.history.push(`/contracts/${id}`);
  }

  return (
    <div>
      <Card
        title={`#${id} - ${renderMoney(amount)}`}
        style={{ marginBottom: '2em' }}
        actions={[
          <span onClick={handleClickDetails}>
            <FullscreenOutlined /> Details
          </span>,
          <Popconfirm title={`Are you sure you want to remove this contract?`} onConfirm={handleDeleteClick}>
            <DeleteOutlined /> Delete
          </Popconfirm>
        ]}
      >
        <Descriptions layout="vertical" colon={false}>
          <Descriptions.Item label="Start">{renderDatetimeAsDate(startDate)}</Descriptions.Item>
          <Descriptions.Item label="End">{renderDatetimeAsDate(endDate)}</Descriptions.Item>
        </Descriptions>
      </Card>
    </div>
  );
}

ClientContractCard.propTypes = {
  clientId: PropTypes.number.isRequired
};

export default withRouter(ClientContractCard);
