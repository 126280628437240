import '../../styles/components/forms/hardware-type-selector.css';

import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'antd';

import HardwareTypeForm from './hardware-type-form';
import { Select } from './formik-ant';

function HardwareTypeSelector(props) {
  const { className, hardwareTypes, field, form } = props;
  const [isCreateHardwareTypeVisible, setIsCreateHardwareTypeVisible] = useState(false);

  function handleCreateHardwareTypeSuccess(hardwareTypeData) {
    setIsCreateHardwareTypeVisible(false);
    form.setFieldValue(field.name, hardwareTypeData.id);
  }

  return (
    <div className={`hardware-type-selector ${className}`}>
      <Select className="hardware-type-selector__select" field={field} form={form}>
        {_.map(hardwareTypes, hardwareType => {
          return (
            <Select.Option value={hardwareType.id} key={hardwareType.id}>
              {hardwareType.typeName}
            </Select.Option>
          );
        })}
      </Select>
      <Popover
        overlayClassName="hardware-type-selector__new-popover"
        placement="bottom"
        title="New Hardware Type"
        content={<HardwareTypeForm onSuccess={handleCreateHardwareTypeSuccess} />}
        trigger="click"
        visible={isCreateHardwareTypeVisible}
        onVisibleChange={setIsCreateHardwareTypeVisible}
      >
        <Button className="hardware-type-selector__add">Add Hardware Type</Button>
      </Popover>
    </div>
  );
}

HardwareTypeSelector.propTypes = {
  className: PropTypes.string,
  hardwareTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ).isRequired
};

HardwareTypeSelector.defaultProps = {
  className: ''
};

export default HardwareTypeSelector;
