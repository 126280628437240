import React, { useContext } from 'react';

import { Typography } from 'antd';

import { CurrentUserContext } from '../global-contexts';
import AccountForm from '../components/forms/my-account-form';

function MyAccountPage(props) {
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);

  function handleAccountUpdateSuccess(accountData) {
    setCurrentUser(accountData);
  }

  return (
    <div>
      <Typography.Title style={{ marginBottom: 0 }}>My Account</Typography.Title>
      <AccountForm account={currentUser} onSuccess={handleAccountUpdateSuccess} />
    </div>
  );
}

export default MyAccountPage;
