import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { Button, notification, Modal, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { DELETE_CLIENT_ORGANIZATION, GET_CLIENT_ORGANIZATIONS } from '../../queries/client/client-organization-queries';

import ClientOrganizationForm from '../forms/client-organization-form';
import RequirePermission from '../require-permission';
import { GET_CLIENT } from '../../queries/client/client-queries';

export function ClientOrganizationEditButton({ clientOrganization, clientId }) {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const handleEditClick = () => {
    setIsEditModalVisible(!isEditModalVisible);
  };
  const handleEditSuccess = () => {
    setIsEditModalVisible(false);
  };

  return (
    <>
      <Button icon={<EditOutlined />} onClick={handleEditClick} />
      <Modal
        destroyOnClose
        footer={null}
        centered
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
      >
        <ClientOrganizationForm
          clientOrganization={clientOrganization}
          clientId={clientId}
          onSuccess={handleEditSuccess}
        />
      </Modal>
    </>
  );
}

ClientOrganizationEditButton.propTypes = {
  clientOrganization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  clientId: PropTypes.number.isRequired
};

export function ClientOrganizationDeleteButton({ clientOrganization: { id, description, clientId }, onSuccess }) {
  const [deleting, setDeleting] = useState(false);
  const [deleteClientOrganization] = useMutation(DELETE_CLIENT_ORGANIZATION);

  async function handleDeleteClick() {
    setDeleting(true);
    try {
      await deleteClientOrganization({
        variables: { id },
        refetchQueries: [
          {
            query: GET_CLIENT_ORGANIZATIONS,
            variables: { filter: { clientId: { equalTo: clientId } }, first: 9 }
          },
          {
            query: GET_CLIENT,
            variables: { id: clientId }
          }
        ]
      });
      if (onSuccess) {
        onSuccess();
      }
      notification.success({
        message: 'Client organization deleted successfully.'
      });
    } catch (error) {
      notification.error({
        message: `Client organization deletion failed with error: ${error.message}`
      });
    } finally {
      setDeleting(false);
    }
  }
  return (
    <RequirePermission permission="MANAGE_ACCOUNTS">
      <Popconfirm title={`Are you sure you want to delete ${description}?`} onConfirm={handleDeleteClick}>
        <Button icon={<DeleteOutlined />} loading={deleting} />
      </Popconfirm>
    </RequirePermission>
  );
}

ClientOrganizationDeleteButton.propTypes = {
  clientOrganization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired
};
