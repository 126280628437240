import { AutoComplete } from 'antd';
import { useQuery } from '@apollo/client';

import { GET_BILLING_MONTHLY_LINE_ITEM_NAMES } from '../queries/billing-queries';
import { withFormikAnt } from '../hocs';

export default function BillingMonthlyLineItemNameAutoComplete(props) {
  const result = useQuery(GET_BILLING_MONTHLY_LINE_ITEM_NAMES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  const { data, loading } = result;
  return (
    <AutoComplete
      allowClear
      options={data?.billingMonthlyLineItemNames.nodes.map(node => ({
        label: node.name,
        value: node.name
      }))}
      loading={loading}
      {...props}
    />
  );
}

export const FormikBillingMonthlyLineItemNameAutoComplete = withFormikAnt()(BillingMonthlyLineItemNameAutoComplete);
