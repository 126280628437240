import React from 'react';
import { Result, Button } from 'antd';
import { withRouter } from 'react-router-dom';

function processError(error) {
  if (typeof error === 'string') {
    return error;
  } else if (error.graphQLErrors) {
    return (
      <ul>
        {error.graphQLErrors.map(error => (
          <li key={error.location}>{error.message}</li>
        ))}
      </ul>
    );
  }
  console.error(JSON.stringify(error)); // eslint-disable-line no-console
  return 'An unknown error occurred';
}

function PageError({ error, history, ...passThroughProps }) {
  return (
    <Result
      status="500"
      title="Error"
      subTitle={processError(error)}
      extra={<Button onClick={() => history.go(0)}>Reload</Button>}
      {...passThroughProps}
    />
  );
}

export default withRouter(PageError);
