import _ from 'lodash';
import React from 'react';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';

import { Formik, Form } from 'formik';
import { Button } from 'antd';

import { updateMyAccount as UpdateMyAccountSchema } from '../../yup-schemas';

import FormikInputGroup from './formik-input-group';
import { Input } from './formik-ant';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import { UPDATE_MY_ACCOUNT } from '../../queries/account';

function AccountForm(props) {
  const { account, onSuccess } = props;
  const [updateAccount] = useMutation(UPDATE_MY_ACCOUNT);

  async function handleFormSubmit(values, formikProps) {
    const { id } = account;
    const { setSubmitting } = formikProps;

    setSubmitting(true);

    try {
      const updateAccountResponse = await updateAccount({
        variables: { id, ...values },
        refetchQueries: ['GetAccounts', 'GetMe']
      });

      notification.success({ message: 'Account updated successfully' });
      if (onSuccess) {
        onSuccess(updateAccountResponse.data?.updateAccount.account);
      }
    } catch (updateAccountError) {
      notification.error({ message: `Account update failed with error: ${updateAccountError.message}` });
      setAnyFormErrorsFromGraphQlError(updateAccountError, formikProps);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        ..._.pick(account, ['name', 'email'])
      }}
      validationSchema={UpdateMyAccountSchema}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        return (
          <Form>
            <FormikInputGroup
              name="name"
              label="Name"
              component={Input}
              placeholder="ex: Timothy Kempf"
              formikProps={formikProps}
            />

            <FormikInputGroup
              name="email"
              label="Email"
              component={Input}
              placeholder="ex: tim@getmeadow.com"
              formikProps={formikProps}
            />

            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                Edit Account
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default AccountForm;
