import _ from 'lodash';
import React from 'react';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';

import { Formik, Form } from 'formik';
import { Button } from 'antd';

import { inviteNewAccount as InviteSchema } from '../../yup-schemas';
import { INVITE_USER } from '../../queries/user';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import FormikInputGroup from './formik-input-group';
import { Input } from './formik-ant';
import { FormikAccountPermissionSelector } from '../account-permission-selector';

function InviteAccountForm(props) {
  const { onSuccess } = props;
  const [inviteUser] = useMutation(INVITE_USER);

  async function handleFormSubmit(values, formikProps) {
    const { setSubmitting } = formikProps;

    setSubmitting(true);

    try {
      const inviteUserResponse = await inviteUser({
        variables: values,
        refetchQueries: ['GetAccounts']
      });

      notification.success({ message: 'Invitation sent successfully' });
      if (onSuccess) {
        const accountData = _.get(inviteUserResponse, 'data.inviteNewAccount.account');
        onSuccess(accountData);
      }
    } catch (inviteUserError) {
      notification.error({ message: `User invite failed with error: ${inviteUserError.message}` });
      setAnyFormErrorsFromGraphQlError(inviteUserError, formikProps);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{}}
      validationSchema={InviteSchema}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        return (
          <Form>
            <FormikInputGroup
              label="Name"
              name="name"
              component={Input}
              placeholder="ex: Timothy Kempf"
              formikProps={formikProps}
            />

            <FormikInputGroup
              label="Email"
              name="email"
              component={Input}
              placeholder="ex: tim@getmeadow.com"
              formikProps={formikProps}
            />

            <FormikInputGroup
              label="Permissions"
              name="permissions"
              formikProps={formikProps}
              mode="multiple"
              component={FormikAccountPermissionSelector}
            />
            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                Send Invite
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default InviteAccountForm;
