import { gql } from '@apollo/client';

export const GET_CLIENT_COMMENTS = gql`
  query GetClientComments($first: Int = 20, $after: Cursor, $clientId: Int!) {
    clientComments(
      first: $first
      after: $after
      filter: { clientId: { equalTo: $clientId }, parentCommentId: { isNull: true } }
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          account {
            name
            email
            id
          }
          clientCommentsByParentCommentId {
            nodes {
              account {
                name
                email
                id
              }
              accountId
              body
              createdAt
              id
              deletedAt
              clientId
              updatedAt
            }
          }
          accountId
          body
          createdAt
          id
          deletedAt
          clientId
          updatedAt
        }
      }
    }
  }
`;

export const CREATE_CLIENT_COMMENT = gql`
  mutation CreateClientComment($clientId: Int!, $parentCommentId: Int, $body: String!) {
    createClientComment(
      input: { clientComment: { clientId: $clientId, parentCommentId: $parentCommentId, body: $body } }
    ) {
      clientComment {
        accountId
        body
        createdAt
        id
        deletedAt
        clientId
        updatedAt
      }
    }
  }
`;

export const UPDATE_CLIENT_COMMENT = gql`
  mutation UpdateClientComment($id: Int!, $body: String!) {
    updateClientComment(input: { patch: { body: $body }, id: $id }) {
      clientComment {
        account {
          name
          email
          id
        }
        accountId
        body
        createdAt
        id
        deletedAt
        clientId
        updatedAt
      }
    }
  }
`;

export const DELETE_CLIENT_COMMENT = gql`
  mutation DeleteClientComment($id: Int!) {
    deleteClientComment(input: { id: $id }) {
      clientComment {
        account {
          name
          email
          id
        }
        accountId
        body
        createdAt
        id
        deletedAt
        clientId
        updatedAt
      }
    }
  }
`;
