import { gql } from '@apollo/client';

export const HARDWARE_TYPE_FIELDS_FRAGMENT = gql`
  fragment HardwareTypeFields on HardwareType {
    typeName
    physicalIdType
    id
    updatedAt
    createdAt
    deletedAt
  }
`;

export const GET_HARDWARE_TYPES = gql`
  query GetHardwareTypes(
    $first: Int = 20
    $after: Cursor
    $withSummary: Boolean! = false
    $orderBy: [HardwareTypesOrderBy!] = [ID_DESC]
  ) {
    hardwareTypes(first: $first, after: $after, orderBy: $orderBy) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...HardwareTypeFields
          atMeadowCount @include(if: $withSummary)
          averageCost @include(if: $withSummary)
        }
      }
    }
  }
  ${HARDWARE_TYPE_FIELDS_FRAGMENT}
`;

export const CREATE_HARDWARE_TYPE = gql`
  mutation CreateHardwareType($typeName: String!, $physicalIdType: String) {
    createHardwareType(input: { hardwareType: { typeName: $typeName, physicalIdType: $physicalIdType } }) {
      hardwareType {
        ...HardwareTypeFields
      }
    }
  }
  ${HARDWARE_TYPE_FIELDS_FRAGMENT}
`;

export const UPDATE_HARDWARE_TYPE = gql`
  mutation UpdateHardwareType($id: Int!, $typeName: String, $physicalIdType: String) {
    updateHardwareType(input: { patch: { typeName: $typeName, physicalIdType: $physicalIdType }, id: $id }) {
      hardwareType {
        ...HardwareTypeFields
      }
    }
  }
  ${HARDWARE_TYPE_FIELDS_FRAGMENT}
`;

export const DELETE_HARDWARE_TYPE = gql`
  mutation DeleteHardwareType($id: Int!) {
    deleteHardwareType(input: { id: $id }) {
      hardwareType {
        ...HardwareTypeFields
      }
    }
  }
  ${HARDWARE_TYPE_FIELDS_FRAGMENT}
`;
