import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Typography, Skeleton, Descriptions } from 'antd';
import { Link } from 'react-router-dom';

import SectionHeader from '../components/section-header';
import getLoadingState from '../utils/get-loading-state';
import { renderDatetimeAsDate } from '../utils/datetime';
import PageError from '../components/page-error';
import PageNotFound from '../components/page-not-found';
import ClientStatusTag from '../components/client/client-status-tag';
import { GET_CLIENT_ORGANIZATION } from '../queries/client/client-organization-queries';
import {
  ClientOrganizationDeleteButton,
  ClientOrganizationEditButton
} from '../components/client/client-organization-buttons';
import ClientOrganizationStatusTable from '../components/client/client-organization-status-table';
import ClientOrganizationTypeEditableTags from '../components/client/client-organization-type-editable-tags';

export default function OrganizationDetailPage({
  history,
  match: {
    params: { id }
  }
}) {
  const response = useQuery(GET_CLIENT_ORGANIZATION, {
    variables: { id: Number(id) },
    notifyOnNetworkStatusChange: true
  });
  const { data, error } = response;
  const loadingState = getLoadingState(response);

  if (loadingState === 'FIRST_LOADING') {
    return <Skeleton />;
  }
  if (error) {
    return <PageError error={error} />;
  }
  if (!_.get(data, 'clientOrganization.id') || _.get(data, 'clientOrganization.deletedAt')) {
    return <PageNotFound entity="organization" />;
  }
  const { clientOrganization } = data;
  const { organizationId, description, status, createdAt, updatedAt, isPrimary, client } = clientOrganization;

  return (
    <>
      <SectionHeader
        left={
          <Typography.Title style={{ marginBottom: 0 }}>
            Org #{organizationId} - {description}
          </Typography.Title>
        }
        right={
          <>
            <ClientOrganizationEditButton clientOrganization={clientOrganization} clientId={client.id} />
            <ClientOrganizationDeleteButton
              clientOrganization={clientOrganization}
              onSuccess={() => history.push('/organizations')}
            />
          </>
        }
      />
      <Descriptions>
        <Descriptions.Item label="Client">
          <Link to={`/clients/${client.id}`}>{client.name}</Link>
        </Descriptions.Item>
        <Descriptions.Item label="Primary Org">{isPrimary ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item label="Status">
          <ClientStatusTag status={status} />
        </Descriptions.Item>
        <Descriptions.Item label="Created">{renderDatetimeAsDate(createdAt)}</Descriptions.Item>
        <Descriptions.Item label="Updated">{renderDatetimeAsDate(updatedAt)}</Descriptions.Item>
      </Descriptions>
      <br />
      <ClientOrganizationTypeEditableTags clientOrganization={clientOrganization} />
      <br />
      <ClientOrganizationStatusTable clientOrganization={clientOrganization} />
    </>
  );
}

OrganizationDetailPage.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired }).isRequired
};
