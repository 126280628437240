import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { notification, Avatar, Card, Popconfirm, Modal, Typography, Tag } from 'antd';
import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';

import AccountForm from '../forms/account-permission-form';
import { DELETE_ACCOUNT } from '../../queries/account';
import { PERMISSIONS } from '../../constants';
import RequirePermission from '../require-permission';

function Account(props) {
  const { account } = props;
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [deleteAccount] = useMutation(DELETE_ACCOUNT);

  function handleEditClick() {
    setIsEditModalVisible(true);
  }

  function handleDeleteClick() {
    deleteAccount({
      variables: { id: account.id },
      refetchQueries: ['GetAccounts']
    })
      .then(() => {
        notification.success({
          message: 'Account deleted successfully.'
        });
      })
      .catch(error => {
        notification.error({
          message: `Account deletion failed with error: ${error.message}`
        });
      });
  }

  function handleAccountFormSuccess() {
    setIsEditModalVisible(false);
  }

  return (
    <>
      <Card
        actions={[
          <RequirePermission permission="MANAGE_ACCOUNTS">
            <span onClick={handleEditClick}>
              <EditOutlined /> Edit
            </span>
          </RequirePermission>,
          <RequirePermission permission="MANAGE_ACCOUNTS">
            <Popconfirm title={`Are you sure you want to remove ${account.name}?`} onConfirm={handleDeleteClick}>
              <DeleteOutlined /> Remove
            </Popconfirm>
          </RequirePermission>
        ]}
      >
        <Card.Meta
          avatar={<Avatar icon={<UserOutlined />} />}
          title={
            <>
              <span>{account.name}</span>
              &nbsp;
              {!account.hasSetPassword && <Tag>Pending</Tag>}
            </>
          }
          description={<a href={`mailto:${account.email}`}>{account.email}</a>}
        />
        {/* Without a set height, cards without permissions will have actions not sticking to bottom */}
        <div style={{ paddingTop: '2em', paddingLeft: '1em', paddingRight: '1em', height: '3em' }}>
          {account.accountPermissions.nodes.map(({ permission, id }) => (
            <Tag color="volcano" key={id}>
              {PERMISSIONS.find(p => p.value === permission).label}
            </Tag>
          ))}
        </div>
      </Card>
      <Modal footer={null} centered visible={isEditModalVisible} onCancel={() => setIsEditModalVisible(false)}>
        <Typography.Title level={2}>Edit Account</Typography.Title>
        <AccountForm account={account} onSuccess={handleAccountFormSuccess} />
      </Modal>
    </>
  );
}

export default Account;
