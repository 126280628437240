import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import LoginPage from './pages/login-page';
import LogoutPage from './pages/logout-page';
import BillingMonthlyRateListPage from './pages/billing-monthly-rate-list-page';
import BillingMonthlyRateDetailPage from './pages/billing-monthly-rate-detail-page';
import BillingMonthlyLineItemListPage from './pages/billing-monthly-line-item-list-page';
import BillingMonthlyLineItemDetailPage from './pages/billing-monthly-line-item-detail-page';
import ClientDetailPage from './pages/client-detail-page';
import ClientsListPage from './pages/clients-list-page';
import ResetPasswordPage from './pages/reset-password-page';
import ChangePasswordPage from './pages/change-password-page';
import HardwareStockPage from './pages/hardware-stock-page';
import HardwarePendingReturnListPage from './pages/hardware-pending-return-list-page';
import HardwareTypesPage from './pages/hardware-types-page';
import HardwareSalesPage from './pages/hardware-sales-page';
import HardwareSalePage from './pages/hardware-sale-page';
import HardwareLeasesPage from './pages/hardware-leases-page';
import HardwareLease from './pages/hardware-lease-page';
import WelcomePage from './pages/welcome-page';
import AccountsPage from './pages/team-meadow-page';
import MyAccountPage from './pages/my-account-page';
import Authenticated from './components/authenticated';
import PageNotFound from './components/page-not-found';
import OrganizationDetailPage from './pages/organization-detail-page';
import OrganizationsListPage from './pages/organizations-list-page';
import ContractsListPage from './pages/contracts-list-page';
import ContractDetailPage from './pages/contract-detail-page';

import AuthFlowRoute from './components/routes/auth-flow-route';

export default function Routes() {
  return (
    <Switch>
      <Redirect exact path="/" to="/clients" />
      <AuthFlowRoute exact path="/login" component={LoginPage} />
      <AuthFlowRoute exact path="/logout" component={LogoutPage} />
      <AuthFlowRoute exact path="/reset-password" component={ResetPasswordPage} />
      <AuthFlowRoute exact path="/change-password" component={ChangePasswordPage} />
      <AuthFlowRoute exact path="/welcome" component={WelcomePage} />
      <Authenticated>
        <Switch>
          <Route exact path="/hardware-stock" component={HardwareStockPage} />
          <Route exact path="/hardware-pending-return" component={HardwarePendingReturnListPage} />
          <Route exact path="/hardware-types" component={HardwareTypesPage} />
          <Route exact path="/hardware-sales" component={HardwareSalesPage} />
          <Route exact path="/hardware-sale/:id" component={HardwareSalePage} />
          <Route exact path="/hardware-leases" component={HardwareLeasesPage} />
          <Route exact path="/hardware-lease/:id" component={HardwareLease} />
          <Route exact path="/clients" component={ClientsListPage} />
          <Route exact path="/clients/:id" component={ClientDetailPage} />
          <Route exact path="/organizations" component={OrganizationsListPage} />
          <Route exact path="/organizations/:id" component={OrganizationDetailPage} />
          <Route exact path="/contracts" component={ContractsListPage} />
          <Route exact path="/contracts/:id" component={ContractDetailPage} />
          <Route exact path="/team-meadow" component={AccountsPage} />
          <Route exact path="/me" component={MyAccountPage} />
          <Route exact path="/billing-monthly-rates" component={BillingMonthlyRateListPage} />
          <Route exact path="/billing-monthly-rates/:id" component={BillingMonthlyRateDetailPage} />
          <Route exact path="/billing-monthly-line-items" component={BillingMonthlyLineItemListPage} />
          <Route exact path="/billing-monthly-line-items/:id" component={BillingMonthlyLineItemDetailPage} />
          <Route component={PageNotFound} />
        </Switch>
      </Authenticated>
    </Switch>
  );
}
