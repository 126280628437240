import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Modal, Skeleton, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';

import { GET_BILLING_MONTHLY_CURRENT_RATES } from '../../queries/billing-queries';
import SectionHeader from '../section-header';
import ClientBillingCard from './client-billing-card';
import BillingGeneralForm from '../forms/billing-general-form';

export default function ClientBillingList(props) {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const { id } = props;
  const result = useQuery(GET_BILLING_MONTHLY_CURRENT_RATES, { variables: { clientId: id } });
  const { loading, data } = result;

  function handleCreateSuccess() {
    setIsCreateModalVisible(false);
  }

  return (
    <>
      <SectionHeader
        left={
          <Tooltip title="Billing rates here does not show past billing rates, please see billing tables for full history.">
            <Typography.Title level={2} style={{ marginBottom: 0 }}>
              Billing Rates
            </Typography.Title>
          </Tooltip>
        }
        right={
          <Button type="secondary" size="small" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Add Billing
          </Button>
        }
      />
      {loading && <Skeleton />}
      {data?.length === 0 && <Typography.Text key="none">No billing rates found.</Typography.Text>}
      {data?.billingMonthlyCurrentRates && (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '15px' }}>
          {data?.billingMonthlyCurrentRates?.nodes.map(node => (
            <ClientBillingCard key={node.id} billingMonthlyCurrentRate={node} />
          ))}
        </div>
      )}
      <Modal
        footer={null}
        centered
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
        destroyOnClose={true}
      >
        <BillingGeneralForm clientId={id} onSuccess={handleCreateSuccess} />
      </Modal>
    </>
  );
}

ClientBillingList.propTypes = {
  id: PropTypes.number.isRequired
};
