import _ from 'lodash';
import React, { useState } from 'react';

import getLoadingState from '../utils/get-loading-state';

import { Button, Modal, Table, Typography, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import PaginatedQuery from '../components/paginated-query';
import SectionHeader from '../components/section-header';
import HardwareTypeForm from '../components/forms/hardware-type-form';
import { renderMoney } from '../utils/money';
import { GET_HARDWARE_TYPES } from '../queries/hardware/hardware-type-queries';
import PageError from '../components/page-error';
import { getAntSortOrderFromGraphqlVariables, setGraphqlOrderFromAntSorter } from '../utils/graphql';

function HardwarePage(props) {
  const [modalState, setModalState] = useState({ visible: false, id: null });
  const [variables, setVariables] = useState({});
  function handleTableChange(pagination, filters, sorter) {
    setGraphqlOrderFromAntSorter({
      setVariables,
      sorter
    });
  }
  return (
    <PaginatedQuery
      query={GET_HARDWARE_TYPES}
      variables={{ withSummary: true, ...variables }}
      notifyOnNetworkStatusChange={true}
    >
      {getHardwaresResponse => {
        let content;

        const { data, loading, error, fetchMore } = getHardwaresResponse;
        const loadingState = getLoadingState(getHardwaresResponse);
        const hardwareTypeForModal = data?.hardwareTypes?.edges
          .map(({ node }) => node)
          .find(node => node.id === modalState.id);

        if (error) {
          return <PageError error={error} />;
        }
        if (loadingState === 'FIRST_LOADING') {
          content = <Skeleton />;
        } else {
          const hardwares = data?.hardwareTypes?.edges.map(({ node }) => ({ ...node, key: node.id }));

          content = (
            <div>
              <Table
                dataSource={hardwares}
                pagination={false}
                loading={loading}
                size="middle"
                bordered
                onChange={handleTableChange}
                onRow={record => {
                  return {
                    style: { cursor: 'pointer' },
                    onClick: () => setModalState({ visible: true, id: record.id })
                  };
                }}
              >
                <Table.Column
                  sorter={{ multiple: 1 }}
                  sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'typeName' })}
                  title="Type"
                  dataIndex="typeName"
                  key="typeName"
                />
                <Table.Column
                  sorter={{ multiple: 1 }}
                  sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'physicalIdType' })}
                  title="ID Type"
                  dataIndex="physicalIdType"
                  key="physicalIdType"
                />
                <Table.Column title="Average Cost" dataIndex="averageCost" key="averageCost" render={renderMoney} />
                <Table.Column title="Stock" dataIndex="atMeadowCount" key="atMeadowCount" />
              </Table>
              <Button
                block
                loading={loading}
                size="large"
                disabled={!_.get(data, 'hardwares.pageInfo.hasNextPage')}
                onClick={() => fetchMore()}
              >
                Load More
              </Button>
              <Typography.Paragraph>
                Only hardware that has not been sold or leased is included in the stock count.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Average cost is calculated from hardware stock costs for that hardware type.
              </Typography.Paragraph>
            </div>
          );
        }

        return (
          <div>
            <SectionHeader
              left={<Typography.Title style={{ marginBottom: 0 }}>Hardware Types</Typography.Title>}
              right={
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setModalState({ visible: true, id: null })}
                >
                  Create Hardware Type
                </Button>
              }
            />
            {content}
            <Modal
              destroyOnClose
              footer={null}
              centered
              visible={modalState.visible}
              onCancel={() => setModalState(state => ({ ...state, visible: false }))}
            >
              <Typography.Title level={2}>
                {hardwareTypeForModal && `Edit Hardware Type`}
                {!hardwareTypeForModal && 'Create Hardware Type'}
              </Typography.Title>
              <HardwareTypeForm
                hardwareType={hardwareTypeForModal}
                onSuccess={() => setModalState(state => ({ ...state, visible: false }))}
              />
            </Modal>
          </div>
        );
      }}
    </PaginatedQuery>
  );
}

export default HardwarePage;
