import './styles/index.css';

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloClient, ApolloProvider, ApolloLink, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';

import App from './app';
import { GlobalStateContextProvider } from './global-contexts';
import { notification } from 'antd';

notification.config({
  placement: 'bottomRight'
});

// in production we have a proxy while in development we use cors
const { REACT_APP_SERVER_ORIGIN } = process.env;
const apiOrigin = REACT_APP_SERVER_ORIGIN || '';

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors }) => {
      if (graphQLErrors && graphQLErrors.find(e => e.message === 'Not Authorised!')) {
        if (window.location.pathname !== '/login') {
          window.location.replace('/login');
        }
      }
    }),
    createUploadLink({
      uri: apiOrigin + '/graphql',
      credentials: 'include'
    })
  ]),
  cache: new InMemoryCache()
});

function WrappedApp() {
  const [AppFromState, setApp] = useState(<App />);
  // Hot module reloading for the app.
  // NOTE: this does not always work
  // but its nice when it does as it preserves
  // state from the providers
  useEffect(() => {
    if (module.hot) {
      module.hot.accept('./app', () => {
        const NextApp = require('./app').default;
        setApp(<NextApp />);
      });
    }
  }, []);
  return (
    <Router>
      <ApolloProvider client={client}>
        <GlobalStateContextProvider>{AppFromState}</GlobalStateContextProvider>
      </ApolloProvider>
    </Router>
  );
}

ReactDOM.render(<WrappedApp />, document.getElementById('root'));
