import _ from 'lodash';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Button, DatePicker, Modal, Table, Typography, Skeleton } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';

import getLoadingState from '../utils/get-loading-state';
import { usePaginatedQuery } from '../components/paginated-query';
import SectionHeader from '../components/section-header';
import HardwareSaleForm from '../components/forms/hardware-sale-form';
import { renderDatetimeAsDate } from '../utils/datetime';
import {
  getAntSortOrderFromGraphqlVariables,
  setGraphqlNullFilterVariableFromAntBooleanFilter
} from '../utils/graphql';
import SelectFilter, { isFiltered } from '../components/tables/select-filter';
import { renderMoney } from '../utils/money';
import PageError from '../components/page-error';
import { setGraphqlOrderFromAntSorter } from '../utils/graphql';
import { GET_HARDWARE_SALES } from '../queries/hardware/hardware-sale-queries';
import SearchFilter from '../components/tables/search-filter';
import HeaderFilters from '../components/tables/header-filters';

function HardwareSalesPage(props) {
  const { history } = props;
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [variables, setVariables] = useState({});

  const res = usePaginatedQuery(GET_HARDWARE_SALES, {
    variables,
    notifyOnNetworkStatusChange: true
  });

  function handleTableChange(pagination, filters, sorter) {
    setGraphqlNullFilterVariableFromAntBooleanFilter({
      filters,
      variables,
      setVariables,
      fieldName: 'invoiceDate'
    });
    setGraphqlOrderFromAntSorter({
      setVariables,
      sorter
    });
  }

  const { data, loading, fetchMore, error } = res;
  const loadingState = getLoadingState(res);

  const hardwares = data?.hardwareSales?.edges.map(({ node }) => ({ ...node, key: node.id }));
  return (
    <>
      <SectionHeader
        left={<Typography.Title style={{ marginBottom: 0 }}>Hardware Sales</Typography.Title>}
        right={
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Create Hardware Sale
          </Button>
        }
      />
      {error && <PageError error={error} />}
      {!error && <HeaderFilters variables={variables} setVariables={setVariables} searchClient="id" />}
      {!error && loadingState === 'FIRST_LOADING' && <Skeleton />}
      {!error && loadingState !== 'FIRST_LOADING' && (
        <>
          <Table
            dataSource={hardwares}
            pagination={false}
            size="middle"
            bordered
            loading={loading}
            onChange={handleTableChange}
            onRow={record => {
              return {
                style: { cursor: 'pointer' },
                onClick: e => {
                  props.history.push(`/hardware-sale/${record.id}`);
                }
              };
            }}
          >
            <Table.Column title="ID" dataIndex="id" key="id" />

            <Table.Column
              title="Client"
              dataIndex="client"
              key="client"
              render={client => <strong>{client.name}</strong>}
            />

            <Table.Column
              title="Description"
              dataIndex="description"
              key="description"
              filterDropdown={
                <SearchFilter fieldName="description" variables={variables} setVariables={setVariables} />
              }
              filterIcon={<SearchOutlined />}
            />

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'createdAt' })}
              title="Created"
              dataIndex="createdAt"
              key="createdAt"
              render={renderDatetimeAsDate}
              filtered={isFiltered({ variables, dateRange: true, fieldName: 'createdAt' })}
              filterDropdown={
                <SelectFilter
                  dateRange
                  fieldName="createdAt"
                  Selector={DatePicker.RangePicker}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
            />

            <Table.Column title="Items Sold" dataIndex="itemCount" key="itemCount" />

            <Table.Column title="Total" dataIndex="totalPrice" key="totalPrice" render={renderMoney} />
            <Table.Column
              title="Invoiced"
              dataIndex="invoiceDate"
              key="invoiceDate"
              render={v => (v ? 'Yes' : 'No')}
              filters={[
                { text: 'Yes', value: true },
                { text: 'No', value: false }
              ]}
            />
          </Table>
          <Button
            block
            loading={loading}
            size="large"
            disabled={!_.get(data, 'hardwares.pageInfo.hasNextPage')}
            onClick={() => fetchMore()}
          >
            Load More
          </Button>
        </>
      )}
      <Modal
        destroyOnClose
        footer={null}
        centered
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
      >
        <HardwareSaleForm
          onSuccess={hardwareData => {
            history.push(`/hardware-sale/${hardwareData.id}`);
          }}
        />
      </Modal>
    </>
  );
}

export default withRouter(HardwareSalesPage);
