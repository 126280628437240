import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { Formik, Form } from 'formik';
import { Button, Typography, Popconfirm } from 'antd';

import { createHardwareSaleLineItem as CreateHardwareSaleLineItemSchema } from '../../yup-schemas';
import FormikInputGroup from './formik-input-group';
import { InputMoney } from './formik-ant';
import { FormikHardwareSegmentedSelector } from '../searchable-selectors';
import { GET_HARDWARES } from '../../queries/hardware/hardware-stock-queries';
import {
  CREATE_HARDWARE_SALE_LINE_ITEM,
  UPDATE_HARDWARE_SALE_LINE_ITEM,
  DELETE_HARDWARE_SALE_LINE_ITEM,
  GET_HARDWARE_SALE
} from '../../queries/hardware/hardware-sale-queries';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';

function HardwareSaleLineItemForm(props) {
  const { hardwareSaleLineItem, hardwareSaleId, onSuccess } = props;
  const [createHardwareSaleLineItem] = useMutation(CREATE_HARDWARE_SALE_LINE_ITEM);
  const [updateHardwareSaleLineItem] = useMutation(UPDATE_HARDWARE_SALE_LINE_ITEM);
  const [hardwareTypeId, setHardwareTypeId] = useState(_.get(hardwareSaleLineItem, 'hardware.hardwareType.id'));
  const [deleteLineItem] = useMutation(DELETE_HARDWARE_SALE_LINE_ITEM);
  const isEditing = !_.isEmpty(hardwareSaleLineItem);
  const [deleting, setDeleting] = useState(false);
  async function handleDeleteLineItem() {
    setDeleting(true);
    try {
      await deleteLineItem({
        variables: { id: hardwareSaleLineItem.id },
        refetchQueries: [
          {
            query: GET_HARDWARES,
            variables: {
              filter: { hardwareTypeId: { equalTo: _.get(hardwareSaleLineItem, 'hardware.hardwareType.id') } }
            }
          },
          { query: GET_HARDWARE_SALE, variables: { id: hardwareSaleId } }
        ]
      });
      notification.success({ message: `Successfully deleted sale line item` });
      onSuccess();
    } catch (err) {
      notification.error({ message: `Unable to delete sale line item: ${err.message}` });
    } finally {
      setDeleting(deleting);
    }
  }
  async function handleFormSubmit(values, formikProps) {
    const verb = isEditing ? 'update' : 'create';

    try {
      formikProps.setSubmitting(true);
      const mutation = isEditing ? updateHardwareSaleLineItem : createHardwareSaleLineItem;

      const mutationResult = await mutation({
        variables: {
          ...values,
          hardwareSaleId
        },
        refetchQueries: [{ query: GET_HARDWARE_SALE, variables: { id: hardwareSaleId } }]
      });

      notification.success({ message: `Hardware sale line item ${verb}d successfully!` });
      if (onSuccess) {
        const hardware = _.get(mutationResult, `data.${verb}Hardware.hardware`);
        onSuccess(hardware);
      }
    } catch (error) {
      notification.error({ message: `Hardware sale line item ${verb} failed with error: ${error}.` });
      setAnyFormErrorsFromGraphQlError(error, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={hardwareSaleLineItem || {}}
      validationSchema={CreateHardwareSaleLineItemSchema}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        return (
          <Form>
            <Typography.Title level={2}>
              {isEditing && `Edit Line Item`}
              {!isEditing && 'Create Line Item'}
            </Typography.Title>

            <FormikInputGroup
              name="hardwareId"
              label="Hardware"
              component={FormikHardwareSegmentedSelector}
              variables={{
                filter: { status: { equalTo: 'AT_MEADOW' } }
              }}
              hardwareTypeId={hardwareTypeId}
              setHardwareTypeId={setHardwareTypeId}
              disabled={isEditing}
              placeholder={
                isEditing
                  ? `${hardwareSaleLineItem.hardware.hardwareType.typeName} ${hardwareSaleLineItem.hardware.physicalId} (#${hardwareSaleLineItem.hardware.id})`
                  : 'Choose hardware for sale'
              }
              formikProps={formikProps}
            />
            <FormikInputGroup
              name="price"
              label="Price"
              component={InputMoney}
              placeholder="Price to client"
              formikProps={formikProps}
            />

            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                {isEditing && 'Edit Line Item'}
                {!isEditing && 'Create Line Item'}
              </Button>
              {isEditing && (
                <Popconfirm
                  title={'Are you sure you want to remove this line item?'}
                  onConfirm={() => handleDeleteLineItem()}
                >
                  <Button type="danger" icon={<DeleteOutlined />} loading={deleting}>
                    Remove Line item
                  </Button>
                </Popconfirm>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
}

HardwareSaleLineItemForm.propTypes = {
  hardwareSaleLineItem: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  hardwareSaleId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default HardwareSaleLineItemForm;
