import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import { Avatar, Button, Menu, Dropdown } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

import { CurrentUserContext } from '../global-contexts';

function UserMenu() {
  const [currentUser] = useContext(CurrentUserContext);

  if (!currentUser) {
    return <span>Loading</span>;
  }

  const menu = (
    <Menu>
      <Menu.Item key="edit-account">
        <Link to="/me">My Account</Link>
      </Menu.Item>
      <Menu.Item key="log-out">
        <Link to="/logout">Log Out</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="user-menu">
      <Dropdown className="user-menu__dropdown" overlay={menu} trigger={['click']}>
        <Button type="ghost" block>
          <Avatar className="user-menu__avatar" size="small" icon={<UserOutlined />} />
          <span className="user-menu__name">{currentUser.name}</span>
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
}

export default UserMenu;
