/**
 * Logout page showing logging out message while browser calls the logout mutation
 * This is necessary for removing cookies
 */

import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

import { CurrentUserContext, DEFAULT_CURRENT_USER_STATE } from '../global-contexts';
import Centered from '../components/centered';
import Spinner from '../components/spinner';

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export default function LogoutPage({ history }) {
  const [logout] = useMutation(LOGOUT);
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);
  useEffect(() => {
    async function logoutAndClearCurrentUser() {
      await logout();
      setCurrentUser(DEFAULT_CURRENT_USER_STATE);
    }
    logoutAndClearCurrentUser();
  }, [logout, setCurrentUser]);
  if (!currentUser) {
    history.push('/login');
  }
  return (
    <Centered>
      <Spinner active />
    </Centered>
  );
}
