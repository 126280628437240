import _ from 'lodash';

function hasData(data) {
  let hasData = true;
  if (!data) {
    return false;
  }
  const opKey = Object.keys(data || {})[0];
  if (!opKey) {
    return false;
  }
  const opResult = data[opKey];

  if (!opResult || _.get(opResult, 'edges.length') === 0 || _.get(opResult, 'nodes.length') === 0) {
    hasData = false;
  }

  return hasData;
}

export default function getLoadingState(response) {
  const { data, error, loading } = response;
  const resultsHaveData = hasData(data);

  if (loading && !resultsHaveData) {
    return 'FIRST_LOADING';
  }
  if (loading && resultsHaveData) {
    return 'LOADING';
  }
  if (!loading && !resultsHaveData) {
    return 'EMPTY';
  }
  if (!loading && resultsHaveData) {
    return 'LOADED';
  }
  if (error) {
    return 'ERROR';
  }

  return 'UNKNOWN';
}
