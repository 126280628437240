import _ from 'lodash';
import React, { useState } from 'react';
import { Button, Modal, Typography, Popconfirm, Descriptions, notification, Skeleton } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import SectionHeader from '../components/section-header';
import ContractForm from '../components/forms/contract-form';
import { renderDatetimeAsDate } from '../utils/datetime';
import { renderMoney } from '../utils/money';
import getLoadingState from '../utils/get-loading-state';
import { DELETE_CLIENT_CONTRACT, GET_CLIENT_CONTRACT } from '../queries/client/client-contract-queries';
import { GET_CONTRACT_FILES, CREATE_CONTRACT_FILE } from '../queries/file-queries';
import FileList from '../components/file-list';
import PageError from '../components/page-error';
import PageNotFound from '../components/page-not-found';
import RequirePermission from '../components/require-permission';

export default function ContractDetailPage(props) {
  const {
    history,
    match: {
      params: { id }
    }
  } = props;
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [deleteContract] = useMutation(DELETE_CLIENT_CONTRACT);
  const [deleting, setDeleting] = useState(false);
  const response = useQuery(GET_CLIENT_CONTRACT, {
    variables: { id: Number(id) },
    notifyOnNetworkStatusChange: true
  });
  const { data, error } = response;
  const loadingState = getLoadingState(response);

  if (loadingState === 'FIRST_LOADING') {
    return <Skeleton />;
  }
  if (error) {
    return <PageError error={error} />;
  }
  if (!_.get(data, 'contract.id') || _.get(data, 'contract.deletedAt')) {
    return <PageNotFound entity="contract" />;
  }

  const { updatedAt, createdAt, amount, client } = data?.contract ?? {};

  async function handleDeleteClick() {
    setDeleting(true);
    try {
      await deleteContract({
        variables: { id: data?.contract.id }
      });
      history.push('/contracts');
      notification.success({
        message: 'Contract deleted successfully'
      });
    } catch (err) {
      notification.error({
        message: `Could not delete contract ${err.message}`
      });
    } finally {
      setDeleting(false);
    }
  }

  return (
    <>
      <SectionHeader
        left={<Typography.Title style={{ marginBottom: 0 }}>Contract #{id}</Typography.Title>}
        right={
          <>
            <Button icon={<EditOutlined />} onClick={() => setIsEditModalVisible(true)} />
            <RequirePermission permission="MANAGE_ACCOUNTS">
              <Popconfirm title={`Are you sure you want to remove this contract?`} onConfirm={handleDeleteClick}>
                <Button icon={<DeleteOutlined />} loading={deleting} />
              </Popconfirm>
            </RequirePermission>
          </>
        }
      />

      <Descriptions>
        <Descriptions.Item label="Client">
          <Link to={`/clients/${client.id}`}>{client.name}</Link>
        </Descriptions.Item>
        <Descriptions.Item label="ACV">{renderMoney(amount)}</Descriptions.Item>
        <Descriptions.Item label="Created">{renderDatetimeAsDate(createdAt)}</Descriptions.Item>
        <Descriptions.Item label="Updated">{renderDatetimeAsDate(updatedAt)}</Descriptions.Item>
      </Descriptions>
      <br />
      <FileList
        variables={{ contractId: Number(id) }}
        createMutation={CREATE_CONTRACT_FILE}
        getQuery={GET_CONTRACT_FILES}
      />
      <Modal footer={null} centered visible={isEditModalVisible} onCancel={() => setIsEditModalVisible(false)}>
        <ContractForm
          clientId={data?.contract.clientId}
          contract={data?.contract}
          onSuccess={() => setIsEditModalVisible(false)}
        />
      </Modal>
    </>
  );
}
