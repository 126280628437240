import { ShopOutlined, CarOutlined, CalendarOutlined } from '@ant-design/icons';

export const PERMISSIONS = [{ value: 'MANAGE_ACCOUNTS', label: 'Team Manager' }];

export const COMPANY_TYPES = [
  {
    value: 'RETAIL',
    label: 'Retail',
    icon: <ShopOutlined />
  },
  {
    value: 'DELIVERY',
    label: 'Delivery',
    icon: <CarOutlined />
  },
  {
    value: 'EVENT',
    label: 'Event',
    icon: <CalendarOutlined />
  }
];

export const CLIENT_STATUS = [
  {
    value: 'PRE_LAUNCH',
    label: 'Pre-launch'
  },
  {
    value: 'ACTIVE',
    label: 'Active'
  },
  {
    value: 'CHURNED',
    label: 'Churned'
  },
  {
    value: 'NEVER_LAUNCHED',
    label: 'Never-launched'
  }
];

export const COMPANY_SIZES = [
  {
    value: 'L',
    label: 'Large'
  },
  {
    value: 'M',
    label: 'Medium'
  },
  {
    value: 'S',
    label: 'Small'
  }
];

export const CHURN_REASONS = [
  'Closed',
  'No License (closed)',
  'Switched to Competitor',
  'Price',
  'Delinquent on Payment'
];

export const BILLING_MONTHLY_RATE_TYPES = [
  {
    value: 'SAAS',
    dbValue: 'saas',
    label: 'SaaS'
  },
  {
    value: 'ANALYTICS',
    dbValue: 'analytics',
    label: 'Analytics'
  },
  {
    value: 'DYNAMIC_DELIVERY',
    dbValue: 'dynamic-delivery',
    label: 'Dynamic Delivery'
  },
  {
    value: 'SMS_MARKETING_SUBSCRIPTION',
    dbValue: 'sms-marketing-subscription',
    label: 'SMS Marketing Subscription'
  }
];

export const BILLING_MONTHLY_RATE_DYNAMIC_DELIVERY_TYPES = [
  {
    value: 'FLAT_RATE',
    label: 'Flat Rate'
  }
];

export const BILLING_MONTHLY_RATE_SUB_TYPES = [...BILLING_MONTHLY_RATE_DYNAMIC_DELIVERY_TYPES];
