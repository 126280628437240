import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Descriptions, notification, Popconfirm, Skeleton, Typography } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PageError from '../components/page-error';
import PageNotFound from '../components/page-not-found';
import RequirePermission from '../components/require-permission';
import SectionHeader from '../components/section-header';
import { BILLING_MONTHLY_RATE_SUB_TYPES, BILLING_MONTHLY_RATE_TYPES } from '../constants';
import {
  DELETE_BILLING_MONTHLY_RATE,
  GET_BILLING_MONTHLY_CURRENT_RATES,
  GET_BILLING_MONTHLY_RATE
} from '../queries/billing-queries';
import { renderDate, renderDatetimeAsDate } from '../utils/datetime';
import getLoadingState from '../utils/get-loading-state';
import { renderMoney } from '../utils/money';

export default function BillingMonthlyRateDetailPage(props) {
  const {
    match: {
      params: { id }
    },
    history
  } = props;
  const [deleting, setDeleting] = useState(false);

  const [deleteNode] = useMutation(DELETE_BILLING_MONTHLY_RATE);
  const response = useQuery(GET_BILLING_MONTHLY_RATE, { variables: { id: Number(id) } });
  const { data, error } = response;

  async function handleDelete() {
    try {
      await deleteNode({
        variables: { id: Number(id) },
        refetchQueries: [
          { query: GET_BILLING_MONTHLY_CURRENT_RATES, variables: { clientId: data?.billingMonthlyLineItem?.id } }
        ]
      });
      notification.success({ message: 'Successfully deleted billing rate' });
      history.push('/billing-monthly-rates');
    } catch (err) {
      notification.error({ message: `Unable to delete billing rate: ${err.message}` });
    } finally {
      setDeleting(false);
    }
  }

  const loadingState = getLoadingState(response);

  if (error) {
    return <PageError error={error} />;
  }
  if (loadingState === 'FIRST_LOADING') {
    return <Skeleton />;
  }
  if (!data?.billingMonthlyRate?.id) {
    return <PageNotFound entity="Monthly billing rate" />;
  }

  return (
    <>
      <SectionHeader
        left={<Typography.Title style={{ marginBottom: 0 }}>Monthly Billing Rate</Typography.Title>}
        right={
          <RequirePermission permission="MANAGE_ACCOUNTS">
            <Popconfirm title={'Are you sure you want to delete this billing rate?'} onConfirm={() => handleDelete()}>
              <Button icon={<DeleteOutlined />} loading={deleting} />
            </Popconfirm>
          </RequirePermission>
        }
      />

      <Descriptions title={'Details'}>
        <Descriptions.Item label="Client">
          <Link to={`/clients/${data?.billingMonthlyRate.client.id}`}>{data?.billingMonthlyRate.client.name}</Link>
        </Descriptions.Item>
        <Descriptions.Item label="Amount">{renderMoney(data?.billingMonthlyRate.amount)}</Descriptions.Item>
        <Descriptions.Item label="Start Date">{renderDate(data?.billingMonthlyRate.startDate)}</Descriptions.Item>
        <Descriptions.Item label="Type">
          {BILLING_MONTHLY_RATE_TYPES.find(o => o.value === data?.billingMonthlyRate.type)?.label ?? 'N/A'}
        </Descriptions.Item>
        {data?.billingMonthlyRate.subType && (
          <Descriptions.Item label="Sub Type">
            {BILLING_MONTHLY_RATE_SUB_TYPES.find(o => o.value === data?.billingMonthlyRate.subType)?.label ?? 'N/A'}
          </Descriptions.Item>
        )}
        {data?.billingMonthlyRate.legacy2016 && <Descriptions.Item label="Legacy">True</Descriptions.Item>}
        {typeof data?.billingMonthlyRate.maxLocations === 'number' && (
          <Descriptions.Item label="Max Locations">{data?.billingMonthlyRate.maxLocations}</Descriptions.Item>
        )}
        {typeof data?.billingMonthlyRate.freeLocations === 'number' && (
          <Descriptions.Item label="Free Locations">{data?.billingMonthlyRate.freeLocations}</Descriptions.Item>
        )}
        {typeof data?.billingMonthlyRate.smsCreditAmount === 'number' && (
          <Descriptions.Item label="SMS Credit Amount">{data?.billingMonthlyRate.smsCreditAmount}</Descriptions.Item>
        )}
        {data?.billingMonthlyRate.clientOrganization && (
          <Descriptions.Item label="Organization">
            {data?.billingMonthlyRate.clientOrganization.organizationId}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Created">
          {renderDatetimeAsDate(data?.billingMonthlyRate.createdAt)}
        </Descriptions.Item>
        {data?.billingMonthlyRate.deletedAt && (
          <Descriptions.Item label="Deleted">
            {renderDatetimeAsDate(data?.billingMonthlyRate.deletedAt)}
          </Descriptions.Item>
        )}
      </Descriptions>
    </>
  );
}
