import moment from 'moment';

export function renderDatetimeAsDate(value) {
  if (value === null) {
    return '-';
  }
  return moment(value).format('YYYY-MM-DD');
}

export function renderDate(value) {
  if (value === null) {
    return '-';
  }
  return moment.utc(value).format('YYYY-MM-DD');
}

export function renderDateTime(value) {
  if (value === null) {
    return '-';
  }
  return moment(value).format('YYYY-MM-DD, hh:mm A');
}
