import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';

import { Formik, Form } from 'formik';
import { Button, Typography } from 'antd';

import FormikInputGroup from './formik-input-group';
import { Input, Checkbox } from './formik-ant';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import {
  CREATE_CLIENT_CONTACT,
  GET_CLIENT_CONTACTS,
  UPDATE_CLIENT_CONTACT
} from '../../queries/client/client-contact-queries.js';
import { createClientContact as createClientContactSchema } from '../../yup-schemas';
import { GET_CLIENT } from '../../queries/client/client-queries';

function ClientContactForm(props) {
  const { clientContact, clientId, onSuccess } = props;
  const [createClientContact] = useMutation(CREATE_CLIENT_CONTACT);
  const [updateClientContact] = useMutation(UPDATE_CLIENT_CONTACT);
  const isEditing = !_.isEmpty(clientContact);

  async function handleFormSubmit(values, formikProps) {
    formikProps.setSubmitting(true);
    const mutation = isEditing ? updateClientContact : createClientContact;
    const verb = isEditing ? 'update' : 'create';

    try {
      const mutationResult = await mutation({
        variables: { id: _.get(clientContact, 'id'), clientId, ...values },
        refetchQueries: [
          {
            query: GET_CLIENT_CONTACTS,
            variables: { clientId, first: 9 }
          },
          {
            query: GET_CLIENT,
            variables: { id: clientId }
          }
        ]
      });

      notification.success({ message: `Client contact ${verb}d successfully.` });
      if (onSuccess) {
        const clientContactData = _.get(mutationResult, `data.${verb}ClientContact.clientContact`);
        onSuccess(clientContactData);
      }
    } catch (error) {
      notification.error({ message: `Client contact ${verb} failed with error: ${error.message}` });
      setAnyFormErrorsFromGraphQlError(error, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        ..._.pick(clientContact, ['firstName', 'lastName', 'phone', 'email', 'title', 'isPrimary', 'isBilling'])
      }}
      onSubmit={handleFormSubmit}
      validationSchema={createClientContactSchema}
      render={formikProps => {
        return (
          <Form>
            <Typography.Title level={2}>
              {isEditing && `Edit ${clientContact.firstName} ${clientContact.lastName}`}
              {!isEditing && 'Create Client Contact'}
            </Typography.Title>

            <FormikInputGroup
              name="firstName"
              label="First Name"
              component={Input}
              placeholder="ex: Gabi"
              formikProps={formikProps}
            />

            <FormikInputGroup
              name="lastName"
              label="Last Name"
              component={Input}
              placeholder="ex: Chan"
              formikProps={formikProps}
            />

            <FormikInputGroup
              name="phone"
              label="Phone"
              component={Input}
              placeholder="ex: 865 555 9333"
              formikProps={formikProps}
            />

            <FormikInputGroup
              name="email"
              label="Email"
              component={Input}
              placeholder="ex: gabi@chan.com"
              formikProps={formikProps}
            />

            <FormikInputGroup
              name="title"
              label="Title"
              component={Input}
              placeholder="ex: Head of Distribution"
              formikProps={formikProps}
            />

            <FormikInputGroup name="isPrimary" label="Primary Contact" component={Checkbox} formikProps={formikProps} />

            <FormikInputGroup name="isBilling" label="Billing Contact" component={Checkbox} formikProps={formikProps} />

            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                {isEditing && 'Edit Client Contact'}
                {!isEditing && 'Create Client Contact'}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
}

ClientContactForm.propTypes = {
  clientId: PropTypes.number.isRequired
};

export default ClientContactForm;
