import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Button, notification, Popconfirm } from 'antd';
import { withRouter } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';

import { createHardwareType as CreateHardwareTypeSchema } from '../../yup-schemas';
import FormikInputGroup from './formik-input-group';
import { Input } from './formik-ant';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';

import {
  GET_HARDWARE_TYPES,
  CREATE_HARDWARE_TYPE,
  UPDATE_HARDWARE_TYPE,
  DELETE_HARDWARE_TYPE
} from '../../queries/hardware/hardware-type-queries';
import RequirePermission from '../require-permission';

const defaultValues = {
  typeName: '',
  physicalIdType: ''
};

function HardwareTypeForm(props) {
  const { hardwareType, onSuccess } = props;
  const [createHardwareType] = useMutation(CREATE_HARDWARE_TYPE);
  const [updateHardwareType] = useMutation(UPDATE_HARDWARE_TYPE);
  const [deleteHardwareType] = useMutation(DELETE_HARDWARE_TYPE);
  const id = _.get(hardwareType, 'id');
  const isEditing = !_.isEmpty(hardwareType);
  const [deleting, setDeleting] = useState(false);

  async function handleFormSubmit(values, formikProps) {
    const verb = isEditing ? 'update' : 'create';

    try {
      formikProps.setSubmitting(true);
      const { typeName, physicalIdType } = values;
      const mutation = isEditing ? updateHardwareType : createHardwareType;

      const mutationResult = await mutation({
        variables: {
          id,
          typeName,
          physicalIdType
        },
        refetchQueries: [{ query: GET_HARDWARE_TYPES, variables: { withSummary: true } }]
      });

      notification.success({ message: `Hardware type ${verb}d successfully!` });
      const hardwareType = _.get(mutationResult, `data.${verb}HardwareType.hardwareType`);
      onSuccess(hardwareType);
    } catch (error) {
      notification.error({ message: `Hardware type ${verb} failed with error: ${error}.` });
      setAnyFormErrorsFromGraphQlError(error, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  async function handleDelete() {
    setDeleting(true);
    try {
      await deleteHardwareType({
        variables: { id },
        refetchQueries: [{ query: GET_HARDWARE_TYPES, variables: { withSummary: true } }]
      });
      notification.success({ message: `Hardware type deleted successfully` });
      onSuccess(hardwareType);
    } catch (err) {
      notification.error({ message: `Deleting hardware type failed with error: ${err.message}.` });
    } finally {
      setDeleting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        ...defaultValues,
        ..._.pick(hardwareType, ['typeName', 'physicalIdType'])
      }}
      validationSchema={CreateHardwareTypeSchema}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        return (
          <form
            onSubmit={event => {
              // Workaround so this formik can be nested inside another
              event.stopPropagation();
              event.preventDefault();
              formikProps.handleSubmit(arguments);
            }}
          >
            <FormikInputGroup
              name="typeName"
              label="Name"
              component={Input}
              placeholder="ex: iPad, Socket Scanner, etc"
              formikProps={formikProps}
            />

            <FormikInputGroup
              name="physicalIdType"
              label="ID Type"
              component={Input}
              placeholder="ex: Serial Number, MAC Address, etc"
              formikProps={formikProps}
            />

            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                {isEditing && 'Edit Hardware Type'}
                {!isEditing && 'Create Hardware Type'}
              </Button>
              {isEditing && (
                <RequirePermission permission="MANAGE_ACCOUNTS">
                  <Popconfirm
                    title="Are you sure you want to delete this hardware type? This will also remove all hardware of this type"
                    onConfirm={handleDelete}
                  >
                    <Button icon={<DeleteOutlined />} type="danger" loading={deleting}>
                      Delete Hardware Type
                    </Button>
                  </Popconfirm>
                </RequirePermission>
              )}
            </div>
          </form>
        );
      }}
    />
  );
}

HardwareTypeForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  hardwareType: PropTypes.shape({ id: PropTypes.number })
};

export default withRouter(HardwareTypeForm);
