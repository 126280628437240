import React from 'react';

function Centered(props) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      {props.children}
    </div>
  );
}

export default Centered;
