import { gql } from '@apollo/client';

export const HARDWARE_FIELDS_FRAGMENT = gql`
  fragment HardwareFields on Hardware {
    createdAt
    updatedAt
    deletedAt
    cost
    status
    hardwareTypeId
    hardwareType {
      id
      typeName
      physicalIdType
    }
    physicalId
    id
    updatedAt
    client {
      id
      name
    }
  }
`;

export const GET_HARDWARE = gql`
  query GetHardware($id: Int!) {
    hardware(id: $id) {
      ...HardwareFields
    }
  }
  ${HARDWARE_FIELDS_FRAGMENT}
`;

export const GET_HARDWARES = gql`
  query GetHardwares(
    $first: Int = 20
    $after: Cursor
    $filter: HardwareFilter
    $orderBy: [HardwaresOrderBy!] = [ID_DESC]
  ) {
    hardwares(first: $first, after: $after, condition: { status: AT_MEADOW }, filter: $filter, orderBy: $orderBy) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...HardwareFields
        }
      }
    }
  }
  ${HARDWARE_FIELDS_FRAGMENT}
`;

export const CREATE_HARDWARE = gql`
  mutation CreateHardware($cost: BigFloat, $hardwareTypeId: Int!, $physicalId: String) {
    createHardware(input: { hardware: { cost: $cost, hardwareTypeId: $hardwareTypeId, physicalId: $physicalId } }) {
      hardware {
        ...HardwareFields
      }
    }
  }
  ${HARDWARE_FIELDS_FRAGMENT}
`;

export const UPDATE_HARDWARE = gql`
  mutation UpdateHardware($id: Int!, $cost: BigFloat, $hardwareTypeId: Int!, $physicalId: String) {
    updateHardware(
      input: { patch: { cost: $cost, hardwareTypeId: $hardwareTypeId, physicalId: $physicalId }, id: $id }
    ) {
      hardware {
        ...HardwareFields
      }
    }
  }
  ${HARDWARE_FIELDS_FRAGMENT}
`;

export const DELETE_HARDWARE = gql`
  mutation DeleteHardware($id: Int!) {
    deleteHardware(input: { id: $id }) {
      hardware {
        ...HardwareFields
      }
    }
  }
  ${HARDWARE_FIELDS_FRAGMENT}
`;
