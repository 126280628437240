import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Table, Modal, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import ClientOrganizationStatusForm from '../forms/client-organization-status-form';
import SectionHeader from '../section-header';
import { renderDateTime } from '../../utils/datetime';

export default function ClientOrganizationStatusTable(props) {
  const {
    clientOrganization: { id, clientId }
  } = props;
  const organizationStatuses = _.get(props, 'clientOrganization.clientOrganizationStatuses.nodes', []);
  const [addStatusModalState, setAddStatusModalState] = useState({ visible: false, id: null });

  return (
    <>
      <SectionHeader
        left={<Typography.Title level={2}>Status Updates</Typography.Title>}
        right={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setAddStatusModalState({ visible: true, id: null })}
          >
            Add Status Update
          </Button>
        }
      />
      <br />
      <Table
        dataSource={organizationStatuses}
        pagination={false}
        size="middle"
        bordered
        rowKey="id"
        onRow={record => {
          return {
            style: { cursor: 'pointer' },
            onClick: e => {
              setAddStatusModalState({ visible: true, id: record.id });
            }
          };
        }}
      >
        <Table.Column title="Date" dataIndex="statusDatetime" render={renderDateTime} />
        <Table.Column
          title="Status"
          key="status"
          render={node => `${node.status}${node.status === 'CHURNED' ? ` - ${node.churnReason}` : ''}`}
        />
        <Table.Column title="Description" dataIndex="description" key="description" />
      </Table>
      <Modal
        destroyOnClose
        footer={null}
        centered
        visible={addStatusModalState.visible}
        onCancel={() => setAddStatusModalState(state => ({ ...state, visible: false }))}
      >
        <ClientOrganizationStatusForm
          clientOrganizationStatus={organizationStatuses.find(node => node.id === addStatusModalState.id)}
          clientOrganizationId={id}
          clientId={clientId}
          onSuccess={() => setAddStatusModalState(state => ({ ...state, visible: false }))}
        />
      </Modal>
    </>
  );
}

ClientOrganizationStatusTable.propTypes = {
  clientOrganization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired
  })
};
