import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import { Popconfirm, Button, Typography, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

import FormikInputGroup from './formik-input-group';
import { Input, DatetimePicker } from './formik-ant';
import { FormikClientStatusSelector } from './client-status-selector';
import { FormikChurnReasonSelector } from './client-churn-reason-selector';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import RequirePermission from '../require-permission';

import {
  GET_CLIENT_ORGANIZATION,
  CREATE_CLIENT_ORGANIZATION_STATUS,
  UPDATE_CLIENT_ORGANIZATION_STATUS,
  DELETE_CLIENT_ORGANIZATION_STATUS
} from '../../queries/client/client-organization-queries';
import { GET_CLIENT } from '../../queries/client/client-queries';

function validate(values) {
  const errors = {};
  if (values.status === 'CHURNED' && !values.churnReason) {
    errors.churnReason = 'is required';
  }

  return errors;
}

function ClientOrganizationStatusForm(props) {
  const { clientOrganizationStatus, clientOrganizationId, clientId, onSuccess } = props;
  const [createClientOrganizationStatus] = useMutation(CREATE_CLIENT_ORGANIZATION_STATUS);
  const [updateClientOrganizationStatus] = useMutation(UPDATE_CLIENT_ORGANIZATION_STATUS);
  const [deleteClientOrganizationStatus] = useMutation(DELETE_CLIENT_ORGANIZATION_STATUS);
  const isEditing = !_.isEmpty(clientOrganizationStatus);
  const id = _.get(clientOrganizationStatus, 'id');
  const [deleting, setDeleting] = useState(false);

  async function handleFormSubmit(values, formikProps) {
    const { setSubmitting } = formikProps;
    const verb = isEditing ? 'update' : 'create';
    const mutation = isEditing ? updateClientOrganizationStatus : createClientOrganizationStatus;

    setSubmitting(true);

    try {
      const mutationResponse = await mutation({
        variables: { id, clientOrganizationId, ...values },
        refetchQueries: [
          { query: GET_CLIENT_ORGANIZATION, variables: { id: clientOrganizationId } },
          {
            query: GET_CLIENT,
            variables: { id: clientId }
          }
        ]
      });
      notification.success({ message: `Client organization status ${verb}d successfully` });
      if (onSuccess) {
        const clientData = _.get(mutationResponse, `data.${verb}ClientOrganizationStatus.clientOrganizationStatus`);
        onSuccess(clientData);
      }
    } catch (mutationError) {
      notification.error({
        message: `Client organization status ${verb} failed with error: ${mutationError.message}`
      });
      setAnyFormErrorsFromGraphQlError(mutationError, formikProps);
    } finally {
      setSubmitting(false);
    }
  }

  async function handleDelete() {
    setDeleting(true);
    try {
      const response = await deleteClientOrganizationStatus({
        variables: { id },
        refetchQueries: [{ query: GET_CLIENT_ORGANIZATION, variables: { id: clientOrganizationId } }]
      });
      notification.success({ message: 'Organization status deleted successfully' });
      if (onSuccess) {
        const clientData = _.get(response, `data?.deleteClientOrganizationStatus.clientOrganizationStatus`);
        onSuccess(clientData);
      }
    } catch (mutationError) {
      notification.error({
        message: `Failed to delete organization status update with error: ${mutationError.message}`
      });
    } finally {
      setDeleting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        ...clientOrganizationStatus,
        statusDatetime:
          clientOrganizationStatus && clientOrganizationStatus.statusDatetime
            ? moment(clientOrganizationStatus.statusDatetime)
            : moment()
      }}
      validate={validate}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        return (
          <Form>
            <Typography.Title level={2}>{isEditing ? 'Edit' : 'Create'} Status Update</Typography.Title>

            <FormikInputGroup name="statusDatetime" label="Date" component={DatetimePicker} formikProps={formikProps} />

            <FormikInputGroup
              name="status"
              label="Status"
              component={FormikClientStatusSelector}
              formikProps={formikProps}
            />

            {formikProps.values.status === 'CHURNED' && (
              <FormikInputGroup
                name="churnReason"
                label="Churn Reason"
                component={FormikChurnReasonSelector}
                formikProps={formikProps}
              />
            )}

            <FormikInputGroup
              name="description"
              label="Description"
              component={Input}
              placeholder="write additional notes for this status change"
              formikProps={formikProps}
            />

            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                {isEditing ? 'Edit' : 'Create'} Status Update
              </Button>
              {isEditing && (
                <RequirePermission permission="MANAGE_ACCOUNTS">
                  <Popconfirm title="Are you sure you want to delete this status update?" onConfirm={handleDelete}>
                    <Button icon={<DeleteOutlined />} type="danger" loading={deleting}>
                      Delete Status Update
                    </Button>
                  </Popconfirm>
                </RequirePermission>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
}

ClientOrganizationStatusForm.propTypes = {
  clientOrganizationId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  clientOrganizationStatus: PropTypes.shape({
    id: PropTypes.number
  }),
  onSuccess: PropTypes.func
};

export default ClientOrganizationStatusForm;
