import React from 'react';
import PropTypes from 'prop-types';
import { Card, Tag, Descriptions, notification, Popconfirm } from 'antd';
import { DeleteOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';

import ClientStatusTag from './client-status-tag';
import { DELETE_CLIENT_ORGANIZATION, GET_CLIENT_ORGANIZATIONS } from '../../queries/client/client-organization-queries';
import { GET_CLIENT } from '../../queries/client/client-queries';

function ClientOrganizationCard(props) {
  const { clientOrganization } = props;
  const { organizationId, status, isPrimary, description, clientId } = clientOrganization;
  const [deleteClientOrganization] = useMutation(DELETE_CLIENT_ORGANIZATION);

  async function handleDeleteClick() {
    try {
      await deleteClientOrganization({
        variables: { id: clientOrganization.id },
        refetchQueries: [
          {
            query: GET_CLIENT_ORGANIZATIONS,
            variables: { filter: { clientId: { equalTo: clientId } }, first: 9 }
          },
          {
            query: GET_CLIENT,
            variables: { id: clientId }
          }
        ]
      });
      notification.success({
        message: 'Client organization deleted successfully.'
      });
    } catch (error) {
      notification.error({
        message: `Client organization deletion failed with error: ${error.message}`
      });
    }
  }
  function handleClickDetails() {
    props.history.push(`/organizations/${clientOrganization.id}`);
  }

  return (
    <div>
      <Card
        title={
          <>
            {description}
            {isPrimary && (
              <>
                &nbsp;<Tag color="purple">Primary</Tag>
              </>
            )}
          </>
        }
        actions={[
          <span onClick={handleClickDetails}>
            <FullscreenOutlined /> Details
          </span>,
          <Popconfirm title={`Are you sure you want to remove this organization?`} onConfirm={handleDeleteClick}>
            <DeleteOutlined /> Delete
          </Popconfirm>
        ]}
      >
        <Descriptions layout="vertical" colon={false}>
          <Descriptions.Item label="Org ID">{organizationId}</Descriptions.Item>
          <Descriptions.Item label="Status">
            <ClientStatusTag status={status} />
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </div>
  );
}

ClientOrganizationCard.propTypes = {
  clientOrganization: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};
export default withRouter(ClientOrganizationCard);
