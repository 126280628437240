import React from 'react';
import { gql } from '@apollo/client';
import { Button, Input, Select, notification } from 'antd';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';

import { createClient as createClientSchema, updateClient as updateClientSchema } from '../../yup-schemas';
import FormikInputGroup from './formik-input-group';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';

const CREATE_CLIENT = gql`
  mutation CreateClient($name: String!, $size: CompanySize!) {
    createClient(input: { client: { name: $name, size: $size } }) {
      client {
        id
        name
      }
    }
  }
`;

const UPDATE_CLIENT = gql`
  mutation UpdateClient($id: Int!, $name: String!, $size: CompanySize!) {
    updateClient(input: { patch: { name: $name, size: $size }, id: $id }) {
      client {
        id
        name
        size
      }
    }
  }
`;

export default function ClientForm(props) {
  const { id, onSuccess = () => {}, name, size } = props;
  const isEditing = props.id;
  const [createClient] = useMutation(CREATE_CLIENT);
  const [updateClient] = useMutation(UPDATE_CLIENT);

  async function handleFormSubmit(values, formik) {
    formik.setSubmitting(true);
    try {
      const fn = isEditing ? updateClient : createClient;
      const res = await fn({
        variables: { ...values, id }
      });
      onSuccess(res.data[isEditing ? 'updateClient' : 'createClient'].client);
      notification.success({
        message: `Client ${isEditing ? 'updated' : 'created'} successfully`
      });
    } catch (err) {
      notification.error({
        message: `${isEditing ? 'Updating' : 'Creating'} client failed with error: ${err.message}`
      });
      setAnyFormErrorsFromGraphQlError(err, formik);
    } finally {
      formik.setSubmitting(false);
    }
  }
  return (
    <Formik
      enableReinitialize={true}
      onSubmit={handleFormSubmit}
      validationSchema={isEditing ? updateClientSchema : createClientSchema}
      initialValues={{
        name,
        size
      }}
      render={formik => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <FormikInputGroup
              label="Name"
              name="name"
              placeholder="ex: Green Door"
              formikProps={formik}
              render={({ form }) => (
                <Input
                  value={form.values.name}
                  onChange={event => {
                    form.setFieldValue('name', event.target.value);
                  }}
                />
              )}
            />
            <FormikInputGroup
              label="Size"
              name="size"
              formikProps={formik}
              render={({ form }) => (
                <Select value={form.values.size} onChange={value => form.setFieldValue('size', value)}>
                  <Select.Option value="S">Small</Select.Option>
                  <Select.Option value="M">Medium</Select.Option>
                  <Select.Option value="L">Large</Select.Option>
                </Select>
              )}
            />
            <div className="client-comment-form__actions">
              {(!isEditing || formik.dirty) && (
                <Button type="primary" htmlType="submit" loading={formik.isSubmitting}>
                  {isEditing ? 'Update' : 'Create'}
                </Button>
              )}
              {isEditing && formik.dirty && (
                <Button loading={formik.isSubmitting} onClick={formik.handleReset}>
                  Cancel
                </Button>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
}
