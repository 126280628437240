import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { notification, Card, Popconfirm, Descriptions } from 'antd';
import { DeleteOutlined, FullscreenOutlined } from '@ant-design/icons';

import { DELETE_BILLING_MONTHLY_LINE_ITEM, DELETE_BILLING_MONTHLY_RATE } from '../../queries/billing-queries';
import RequirePermission from '../require-permission';
import { GET_BILLING_MONTHLY_CURRENT_RATES } from '../../queries/billing-queries';
import { BILLING_MONTHLY_RATE_TYPES } from '../../constants';
import { renderMoney } from '../../utils/money';
import { renderDate } from '../../utils/datetime';
import { Link } from 'react-router-dom';

export default function ClientBillingCard(props) {
  const { billingMonthlyCurrentRate } = props;
  const {
    id: nodeId,
    smsCreditAmount,
    clientId,
    name,
    type,
    amount,
    maxLocations,
    freeLocations,
    startDate,
    clientOrganization
  } = billingMonthlyCurrentRate;
  const id = Number(nodeId.split('-').pop());

  const [deleteBillingMonthlyRate] = useMutation(DELETE_BILLING_MONTHLY_RATE);
  const [deleteBillingMonthlyLineItem] = useMutation(DELETE_BILLING_MONTHLY_LINE_ITEM);

  async function handleDeleteClick() {
    const fn = type === 'line-item' ? deleteBillingMonthlyLineItem : deleteBillingMonthlyRate;
    try {
      await fn({
        variables: { id },
        refetchQueries: [{ query: GET_BILLING_MONTHLY_CURRENT_RATES, variables: { clientId } }]
      });
      notification.success({
        message: 'Billing deleted successfully.'
      });
    } catch (error) {
      notification.error({
        message: `Billing deletion failed with error: ${error.message}`
      });
    }
  }

  const typeHumanized = BILLING_MONTHLY_RATE_TYPES.find(x => x.dbValue === type)?.label;
  let link, title;
  switch (type) {
    case 'line-item':
      link = `/billing-monthly-line-items/${id}`;
      title = name;
      break;
    default:
      link = `/billing-monthly-rates/${id}`;
      title = typeHumanized;
  }

  return (
    <div>
      <Card
        title={title}
        actions={[
          <Link to={link}>
            <FullscreenOutlined /> Details
          </Link>,
          <RequirePermission permission="MANAGE_ACCOUNTS">
            <Popconfirm title={`Are you sure you want to delete this billing rate?`} onConfirm={handleDeleteClick}>
              <DeleteOutlined /> Delete
            </Popconfirm>
          </RequirePermission>
        ]}
      >
        <Descriptions layout="vertical" colon={false}>
          <Descriptions.Item key="amount" label="Amount">
            {renderMoney(amount)}
          </Descriptions.Item>
          {!!startDate && (
            <Descriptions.Item key="startDate" label="Start Date" span={2}>
              {renderDate(startDate)}
            </Descriptions.Item>
          )}
          {typeof maxLocations === 'number' && (
            <Descriptions.Item key="maxLocations" label="Max Locations">
              {maxLocations}
            </Descriptions.Item>
          )}
          {typeof freeLocations === 'number' && (
            <Descriptions.Item key="freeLocations" label="Free Locations">
              {freeLocations}
            </Descriptions.Item>
          )}
          {typeof smsCreditAmount === 'number' && (
            <Descriptions.Item key="smsCreditAmount" label="SMS Credits">
              {smsCreditAmount}
            </Descriptions.Item>
          )}
          {clientOrganization && (
            <Descriptions.Item key="org" label="Org ID">
              {clientOrganization.organizationId}
            </Descriptions.Item>
          )}
        </Descriptions>
      </Card>
    </div>
  );
}

ClientBillingCard.propTypes = {
  billingMonthlyCurrentRate: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};
