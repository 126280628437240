import { gql } from '@apollo/client';

export const ACCOUNT_FIELDS_FRAGMENT = gql`
  fragment AccountFields on Account {
    createdAt
    deletedAt
    updatedAt
    id
    email
    name
    hasSetPassword
    accountPermissions {
      nodes {
        id
        permission
      }
    }
  }
`;

export const GET_ACCOUNTS = gql`
  query GetAccounts {
    accounts(filter: { deletedAt: { isNull: true } }, orderBy: NAME_ASC) {
      nodes {
        ...AccountFields
      }
    }
  }
  ${ACCOUNT_FIELDS_FRAGMENT}
`;

export const UPDATE_ACCOUNT_PERMISSIONS = gql`
  mutation UpdateAccountPermissions($id: Int!, $permissions: [Permission!]) {
    updateAccountPermissions(input: { permissions: $permissions, id: $id }) {
      account {
        ...AccountFields
      }
    }
  }
  ${ACCOUNT_FIELDS_FRAGMENT}
`;

export const UPDATE_MY_ACCOUNT = gql`
  mutation UpdateAccount($id: Int!, $name: String, $email: String) {
    updateAccount(input: { patch: { name: $name, email: $email }, id: $id }) {
      account {
        ...AccountFields
      }
    }
  }
  ${ACCOUNT_FIELDS_FRAGMENT}
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($id: Int!) {
    deleteAccount(input: { id: $id }) {
      account {
        ...AccountFields
      }
    }
  }
  ${ACCOUNT_FIELDS_FRAGMENT}
`;

export const ME_QUERY = gql`
  query GetMe {
    me {
      ...AccountFields
    }
  }
  ${ACCOUNT_FIELDS_FRAGMENT}
`;
