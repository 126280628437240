/**
 * This file contains global contexts which can be individually consumed
 * @example
 * import { CurrentUserContext } from './global-contexts'
 * function MyComponent () {
 *  const [currentUser, setCurrentUser] = useContext(CurrentUserContext)
 * }
 * As can be seen they are simply a value from useState but provided globally through a React Context
 */

import React, { useState } from 'react';

const noop = () => {};

// if not null contains the account
export const DEFAULT_CURRENT_USER_STATE = null;

export const CurrentUserContext = React.createContext([DEFAULT_CURRENT_USER_STATE, noop]);

/**
 * If adding a context, also add it to the GlobalStateContextProvider
 */

export function GlobalStateContextProvider({ children }) {
  return (
    <CurrentUserContext.Provider value={useState(DEFAULT_CURRENT_USER_STATE)}>{children}</CurrentUserContext.Provider>
  );
}
