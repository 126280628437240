import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';

import { clientComment as ClientCommentSchema } from '../../yup-schemas';

import { Formik, Form } from 'formik';
import { Button } from 'antd';

import FormikInputGroup from './formik-input-group';
import { Input } from './formik-ant';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import { CREATE_CLIENT_COMMENT, UPDATE_CLIENT_COMMENT } from '../../queries/client/comment.js';

const defaultValues = {
  body: ''
};

function ClientCommentForm(props) {
  const { clientComment, clientId, parentCommentId, onCancel, onSuccess } = props;
  const [createClientComment] = useMutation(CREATE_CLIENT_COMMENT);
  const [updateClientComment] = useMutation(UPDATE_CLIENT_COMMENT);
  const isEditing = !_.isEmpty(clientComment);
  const isReply = _.isNumber(parentCommentId);

  function handleFormSubmit(values, formikProps) {
    const { body } = values;

    formikProps.setSubmitting(true);

    if (isEditing) {
      const { id } = clientComment;

      updateClientComment({
        variables: { id, body },
        refetchQueries: ['GetClientComments']
      })
        .then(updateClientCommentResult => {
          notification.success({
            message: 'Client comment updated successfully'
          });

          if (onSuccess) {
            const clientData = _.get(updateClientCommentResult, 'data.createClientComment.client');

            onSuccess(clientData);
          }
        })
        .catch(updateClientCommentError => {
          notification.error({
            message: `Client comment update failed with error: ${updateClientCommentError.message}`
          });
          setAnyFormErrorsFromGraphQlError(updateClientCommentError, formikProps);
        })
        .finally(() => {
          formikProps.setSubmitting(false);
        });
    } else {
      createClientComment({
        variables: { clientId, parentCommentId, body },
        refetchQueries: ['GetClientComments']
      })
        .then(createClientCommentResult => {
          formikProps.resetForm();

          notification.success({
            message: 'Client comment created successfully'
          });

          if (onSuccess) {
            const clientData = _.get(createClientCommentResult, 'data.createClientComment.client');

            onSuccess(clientData);
          }
        })
        .catch(createClientCommentError => {
          notification.error({
            message: `Client comment creation failed with error: ${createClientCommentError.message}`
          });
          setAnyFormErrorsFromGraphQlError(createClientCommentError, formikProps);
        })
        .finally(() => {
          formikProps.setSubmitting(false);
        });
    }
  }

  function handleCancel() {
    if (onCancel) {
      onCancel();
    }
  }

  return (
    <Formik
      initialValues={{
        ...defaultValues,
        ..._.pick(clientComment, ['body'])
      }}
      validationSchema={ClientCommentSchema}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        function handleKeyPress(event) {
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            formikProps.submitForm();
          }
        }

        return (
          <Form className="client-comment-form" onSubmit={formikProps.handleSubmit}>
            <FormikInputGroup
              className="client-comment-form__body"
              component={Input.TextArea}
              name="body"
              placeholder="Enter a comment..."
              formikProps={formikProps}
              onKeyPress={handleKeyPress}
            />

            <div className="client-comment-form__actions">
              <Button
                className="client-comment-form__submit client-comment-form__action"
                type={isEditing ? 'primary' : 'secondary'}
                htmlType="submit"
                loading={formikProps.isSubmitting}
              >
                {isEditing && isReply && 'Edit Reply'}
                {isEditing && !isReply && 'Edit Comment'}
                {!isEditing && isReply && 'Create Reply'}
                {!isEditing && !isReply && 'Create Comment'}
              </Button>
              {isEditing && (
                <Button
                  className="parnter-comment-form__cancel client-comment-form__action"
                  type="secondary"
                  loading={formikProps.isSubmitting}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
}

ClientCommentForm.propTypes = {
  clientComment: PropTypes.shape({
    id: PropTypes.number,
    body: PropTypes.string
  }),
  clientId: PropTypes.number.isRequired,
  parentCommentId: PropTypes.number,
  onSuccess: PropTypes.func
};

export default ClientCommentForm;
