import React from 'react';

import ResetPasswordForm from '../components/forms/reset-password-form';

function ResetPasswordPage() {
  return (
    <div className="reset-password">
      <p>Enter your account's email address below, and we'll send you a link to reset your password.</p>
      <ResetPasswordForm />
    </div>
  );
}

export default ResetPasswordPage;
