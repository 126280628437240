import { gql } from '@apollo/client';

export const CONTRACT_FIELDS_FRAGMENT = gql`
  fragment ContractFields on Contract {
    id
    updatedAt
    createdAt
    deletedAt
    amount
    startDate
    endDate
    client {
      id
      name
    }
  }
`;

export const GET_CLIENT_CONTRACT = gql`
  query GetClientContract($id: Int!) {
    contract(id: $id) {
      ...ContractFields
    }
  }
  ${CONTRACT_FIELDS_FRAGMENT}
`;

export const GET_CLIENT_CONTRACTS = gql`
  query GetClientContracts(
    $first: Int = 20
    $after: Cursor
    $filter: ContractFilter
    $orderBy: [ContractsOrderBy!] = [ID_DESC]
  ) {
    contracts(first: $first, after: $after, filter: $filter, orderBy: $orderBy) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...ContractFields
        }
      }
    }
  }
  ${CONTRACT_FIELDS_FRAGMENT}
`;

export const GET_CLIENT_CONTRACTS_BY_CLIENT_ID = gql`
  query GetClientContractsByClientId($first: Int = 20, $after: Cursor, $clientId: Int!) {
    contracts(first: $first, after: $after, condition: { clientId: $clientId }, orderBy: [ID_DESC]) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...ContractFields
        }
      }
    }
  }
  ${CONTRACT_FIELDS_FRAGMENT}
`;

export const CREATE_CLIENT_CONTRACT = gql`
  mutation CreateClientContract($startDate: Date!, $endDate: Date!, $amount: BigFloat!, $clientId: Int!) {
    createContract(
      input: { contract: { startDate: $startDate, endDate: $endDate, amount: $amount, clientId: $clientId } }
    ) {
      contract {
        ...ContractFields
      }
    }
  }
  ${CONTRACT_FIELDS_FRAGMENT}
`;

export const UPDATE_CLIENT_CONTRACT = gql`
  mutation UpdateClientContract($id: Int!, $startDate: Date!, $endDate: Date!, $amount: BigFloat!) {
    updateContract(input: { id: $id, patch: { startDate: $startDate, endDate: $endDate, amount: $amount } }) {
      contract {
        ...ContractFields
      }
    }
  }
  ${CONTRACT_FIELDS_FRAGMENT}
`;

export const DELETE_CLIENT_CONTRACT = gql`
  mutation DeleteClientContract($id: Int!) {
    deleteContract(input: { id: $id }) {
      contract {
        ...ContractFields
      }
    }
  }
  ${CONTRACT_FIELDS_FRAGMENT}
`;
