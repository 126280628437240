import React from 'react';

import { Typography } from 'antd';

import SetPasswordForm from '../components/forms/set-password-form';

function ChangePassword(props) {
  const { history } = props;
  const params = new URLSearchParams(props.location.search);
  const token = params.get('token');

  return (
    <div>
      <Typography.Title level={2}>So, it's come to this</Typography.Title>
      <p>
        It sucks to forget a password, but try to focus on the joy of creating something{' '}
        <strong className="rainbow-text">new</strong>. Remember to use{' '}
        <a href="https://1password.com/" target="_blank" rel="noopener noreferrer">
          {' '}
          1Password
        </a>{' '}
        to generate a secure password.
      </p>
      <SetPasswordForm
        token={token}
        onSuccess={() => {
          history.push('/');
        }}
      />
    </div>
  );
}

export default ChangePassword;
