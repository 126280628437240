import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Typography, Tag, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';

import { COMPANY_TYPES } from '../../constants';
import FormikInputGroup from '../forms/formik-input-group';
import SectionHeader from '../section-header';
import { FormikCompanyTypeSelector, CompanyTypeIcon } from '../company-type';
import {
  DELETE_CLIENT_ORGANIZATION_TYPE,
  GET_CLIENT_ORGANIZATION,
  CREATE_CLIENT_ORGANIZATION_TYPE
} from '../../queries/client/client-organization-queries';
import { GET_CLIENT } from '../../queries/client/client-queries';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';

function ClientOrganizationTypeEditableTag({ organizationType, clientOrganizationId, clientId }) {
  const [deleteType] = useMutation(DELETE_CLIENT_ORGANIZATION_TYPE);
  const [visible, setVisible] = useState(true);
  async function handleClose() {
    try {
      await deleteType({
        variables: { id: organizationType.id },
        refetchQueries: [
          { query: GET_CLIENT_ORGANIZATION, variables: { id: clientOrganizationId } },
          { query: GET_CLIENT, variables: { id: clientId } }
        ]
      });
      notification.success({
        message: `Company type removed successfully`
      });
      setVisible(false);
    } catch (err) {
      notification.error({
        message: `Company type deletion failed with error: ${err.message}`
      });
    }
  }
  return (
    <Tag visible={visible} closable onClose={handleClose} style={{ padding: '12px' }}>
      <CompanyTypeIcon type={organizationType.companyType} style={{ fontSize: '1.5em' }} />
    </Tag>
  );
}

ClientOrganizationTypeEditableTag.propTypes = {
  organizationType: PropTypes.shape({
    id: PropTypes.number.isRequired,
    companyType: PropTypes.oneOf(COMPANY_TYPES.map(t => t.value))
  }),
  clientId: PropTypes.number.isRequired,
  clientOrganizationId: PropTypes.number.isRequired
};

function CreateCompanyTypeForm({ clientOrganizationId, onSuccess }) {
  const [createType] = useMutation(CREATE_CLIENT_ORGANIZATION_TYPE);
  async function handleSubmit(values, formikProps) {
    formikProps.setSubmitting(true);
    try {
      await createType({
        variables: { clientOrganizationId, ...values },
        refetchQueries: [{ query: GET_CLIENT_ORGANIZATION, variables: { id: clientOrganizationId } }]
      });
      notification.success({
        message: `Company type added successfully`
      });
      onSuccess();
    } catch (err) {
      notification.error({
        message: `Company type creation failed with error: ${err.message}`
      });
      setAnyFormErrorsFromGraphQlError(err, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }
  return (
    <>
      <Typography.Title level={3}>Add Company Type</Typography.Title>
      <Formik
        initialValues={{}}
        onSubmit={handleSubmit}
        render={formik => {
          return (
            <Form onSubmit={formik.handleSubmit}>
              <FormikInputGroup
                label="Company Type"
                name="companyType"
                formikProps={formik}
                render={FormikCompanyTypeSelector}
              />
              <Button type="primary" htmlType="submit" loading={formik.isSubmitting}>
                Add
              </Button>
            </Form>
          );
        }}
      />
    </>
  );
}

CreateCompanyTypeForm.propTypes = {
  clientOrganizationId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default function ClientOrganizationTypeEditableTags(props) {
  const {
    clientOrganization: { id, clientId }
  } = props;
  const organizationTypes = _.get(props, 'clientOrganization.clientOrganizationTypes.nodes', []);
  const [modalState, setModalState] = useState(false);
  return (
    <>
      <SectionHeader
        left={<Typography.Title level={2}>Company Types</Typography.Title>}
        right={
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setModalState(true)}>
            Add company type
          </Button>
        }
      />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {organizationTypes.map(organizationType => (
          <ClientOrganizationTypeEditableTag
            clientOrganizationId={id}
            organizationType={organizationType}
            clientId={clientId}
            key={organizationType.id}
          />
        ))}
      </div>
      <Modal destroyOnClose footer={null} centered visible={modalState} onCancel={() => setModalState(false)}>
        <CreateCompanyTypeForm clientOrganizationId={id} onSuccess={() => setModalState(false)} />
      </Modal>
    </>
  );
}
