import { gql } from '@apollo/client';

export const HARDWARE_LEASE_LINE_ITEM_FIELDS_FRAGMENT = gql`
  fragment HardwareLeaseLineItemFields on HardwareLeaseLineItem {
    id
    description
    updatedAt
    createdAt
    deletedAt
    returned
    hardwareLeaseId
    hardwareId
    hardware {
      id
      cost
      physicalId
      hardwareType {
        id
        typeName
      }
    }
  }
`;

export const HARDWARE_LEASE_FIELDS_FRAGMENT = gql`
  fragment HardwareLeaseFields on HardwareLease {
    id
    updatedAt
    createdAt
    deletedAt
    description
    invoiceDate
    price
    itemCount
    invoiceLink
    clientId
    client {
      id
      name
    }
    hardwareLeaseLineItems {
      nodes {
        ...HardwareLeaseLineItemFields
      }
    }
  }
  ${HARDWARE_LEASE_LINE_ITEM_FIELDS_FRAGMENT}
`;

export const GET_HARDWARE_LEASES = gql`
  query GetHardwareLeases($first: Int = 20, $after: Cursor, $filter: HardwareLeaseFilter) {
    hardwareLeases(first: $first, after: $after, filter: $filter, orderBy: [ID_DESC]) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...HardwareLeaseFields
        }
      }
    }
  }
  ${HARDWARE_LEASE_FIELDS_FRAGMENT}
`;

export const CREATE_HARDWARE_LEASE = gql`
  mutation CreateHardwareLease(
    $description: String
    $invoiceDate: Datetime
    $price: BigFloat!
    $invoiceLink: String
    $clientId: Int!
  ) {
    createHardwareLease(
      input: {
        hardwareLease: {
          description: $description
          invoiceDate: $invoiceDate
          price: $price
          invoiceLink: $invoiceLink
          clientId: $clientId
        }
      }
    ) {
      hardwareLease {
        ...HardwareLeaseFields
      }
    }
  }
  ${HARDWARE_LEASE_FIELDS_FRAGMENT}
`;

export const UPDATE_HARDWARE_LEASE = gql`
  mutation UpdateHardwareLease(
    $id: Int!
    $description: String
    $invoiceDate: Datetime
    $price: BigFloat!
    $invoiceLink: String
  ) {
    updateHardwareLease(
      input: {
        id: $id
        patch: { description: $description, invoiceDate: $invoiceDate, price: $price, invoiceLink: $invoiceLink }
      }
    ) {
      hardwareLease {
        ...HardwareLeaseFields
      }
    }
  }
  ${HARDWARE_LEASE_FIELDS_FRAGMENT}
`;

export const GET_HARDWARE_LEASE = gql`
  query GetHardwareLease($id: Int!) {
    hardwareLease(id: $id) {
      ...HardwareLeaseFields
    }
  }
  ${HARDWARE_LEASE_FIELDS_FRAGMENT}
`;

export const DELETE_HARDWARE_LEASE = gql`
  mutation DeleteHardwareLease($id: Int!) {
    deleteHardwareLease(input: { id: $id }) {
      hardwareLease {
        ...HardwareLeaseFields
      }
    }
  }
  ${HARDWARE_LEASE_FIELDS_FRAGMENT}
`;

export const CREATE_HARDWARE_LEASE_LINE_ITEM = gql`
  mutation CreateHardwareLeaseLineItem($hardwareId: Int!, $hardwareLeaseId: Int!) {
    createHardwareLeaseLineItem(
      input: { hardwareLeaseLineItem: { hardwareId: $hardwareId, hardwareLeaseId: $hardwareLeaseId } }
    ) {
      hardwareLeaseLineItem {
        ...HardwareLeaseLineItemFields
      }
    }
  }
  ${HARDWARE_LEASE_LINE_ITEM_FIELDS_FRAGMENT}
`;

export const UPDATE_HARDWARE_LEASE_LINE_ITEM = gql`
  mutation UpdateHardwareLeaseLineItem($returned: Boolean!, $id: Int!) {
    updateHardwareLeaseLineItem(input: { id: $id, patch: { returned: $returned } }) {
      hardwareLeaseLineItem {
        ...HardwareLeaseLineItemFields
      }
    }
  }
  ${HARDWARE_LEASE_LINE_ITEM_FIELDS_FRAGMENT}
`;

export const DELETE_HARDWARE_LEASE_LINE_ITEM = gql`
  mutation DeleteHardwareLeaseLineItem($id: Int!) {
    deleteHardwareLeaseLineItem(input: { id: $id }) {
      hardwareLeaseLineItem {
        ...HardwareLeaseLineItemFields
      }
    }
  }
  ${HARDWARE_LEASE_LINE_ITEM_FIELDS_FRAGMENT}
`;

export const GET_HARDWARE_LEASE_LINE_ITEMS = gql`
  query GetHardwareLeaseLineItems($first: Int = 20, $after: Cursor, $filter: HardwareLeaseLineItemFilter) {
    hardwareLeaseLineItems(
      first: $first
      after: $after
      condition: { returned: false }
      filter: $filter
      orderBy: [ID_DESC]
    ) {
      edges {
        cursor
        node {
          ...HardwareLeaseLineItemFields
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
  ${HARDWARE_LEASE_LINE_ITEM_FIELDS_FRAGMENT}
`;
