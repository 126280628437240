import React from 'react';
import { Select } from 'antd';
import { CLIENT_STATUS } from '../../constants';
import { withFormikAnt } from '../../hocs';

export default function ClientStatusSelector(props) {
  return (
    <Select {...props}>
      {CLIENT_STATUS.map(state => (
        <Select.Option key={state.value} value={state.value}>
          {state.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export const FormikClientStatusSelector = withFormikAnt()(ClientStatusSelector);
