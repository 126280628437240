import { gql } from '@apollo/client';

export const FILE_FIELDS_FRAGMENT = gql`
  fragment FileFields on File {
    createdAt
    deletedAt
    filename
    height
    id
    mimetype
    signedUrl
    thumbnail
    updatedAt
    width
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($id: Int!) {
    deleteFile(input: { id: $id }) {
      file {
        ...FileFields
      }
    }
  }
  ${FILE_FIELDS_FRAGMENT}
`;

export const CLIENT_FILE_FIELDS_FRAGMENT = gql`
  fragment ClientFileFields on ClientFile {
    id
    file {
      ...FileFields
    }
  }
  ${FILE_FIELDS_FRAGMENT}
`;

export const GET_CLIENT_FILES = gql`
  query GetClientFiles($first: Int = 20, $after: Cursor, $clientId: Int!) {
    clientFiles(first: $first, after: $after, filter: { clientId: { equalTo: $clientId } }, orderBy: [ID_DESC]) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...ClientFileFields
        }
      }
    }
  }
  ${CLIENT_FILE_FIELDS_FRAGMENT}
`;

export const CREATE_CLIENT_FILE = gql`
  mutation CreateClientFile($clientId: Int!, $file: Upload!) {
    createClientFile(input: { clientFile: { clientId: $clientId, file: $file } }) {
      clientFile {
        ...ClientFileFields
      }
    }
  }
  ${CLIENT_FILE_FIELDS_FRAGMENT}
`;

export const CONTRACT_FILE_FIELDS_FRAGMENT = gql`
  fragment ContractFileFields on ContractFile {
    id
    file {
      ...FileFields
    }
  }
  ${FILE_FIELDS_FRAGMENT}
`;

export const GET_CONTRACT_FILES = gql`
  query GetContractFiles($first: Int = 20, $after: Cursor, $contractId: Int!) {
    contractFiles(first: $first, after: $after, filter: { contractId: { equalTo: $contractId } }, orderBy: [ID_DESC]) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...ContractFileFields
        }
      }
    }
  }
  ${CONTRACT_FILE_FIELDS_FRAGMENT}
`;

export const CREATE_CONTRACT_FILE = gql`
  mutation CreateContractFile($contractId: Int!, $file: Upload!) {
    createContractFile(input: { contractFile: { contractId: $contractId, file: $file } }) {
      contractFile {
        ...ContractFileFields
      }
    }
  }
  ${CONTRACT_FILE_FIELDS_FRAGMENT}
`;
