import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Button, Table, Typography, Skeleton, Modal, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import getLoadingState from '../utils/get-loading-state';
import { usePaginatedQuery } from '../components/paginated-query';
import SectionHeader from '../components/section-header';
import { renderMoney } from '../utils/money';
import PageError from '../components/page-error';
import { getAntSortOrderFromGraphqlVariables, setGraphqlOrderFromAntSorter } from '../utils/graphql';
import { GET_BILLING_MONTHLY_LINE_ITEMS } from '../queries/billing-queries';
import BillingMonthlyLineItemForm from '../components/forms/billing-monthly-line-item-form';
import SelectFilter, { isFiltered } from '../components/tables/select-filter';
import { renderDatetimeAsDate } from '../utils/datetime';
import HeaderFilters from '../components/tables/header-filters';

function BillingMonthlyLineItemsPage(props) {
  const { history } = props;
  const [variables, setVariables] = useState({});
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const res = usePaginatedQuery(GET_BILLING_MONTHLY_LINE_ITEMS, {
    variables,
    notifyOnNetworkStatusChange: true
  });

  function handleTableChange(pagination, filters, sorter) {
    setGraphqlOrderFromAntSorter({
      setVariables,
      sorter
    });
    return true;
  }

  const { data, loading, fetchMore, error } = res;
  const loadingState = getLoadingState(res);
  return (
    <>
      <SectionHeader
        left={<Typography.Title style={{ marginBottom: 0 }}>Monthly Billing Line Items (Misc)</Typography.Title>}
        right={
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Create Billing
          </Button>
        }
      />
      {error && <PageError error={error} />}
      {!error && <HeaderFilters variables={variables} setVariables={setVariables} searchClient="id" />}
      {!error && loadingState === 'FIRST_LOADING' && <Skeleton />}
      {!error && loadingState !== 'FIRST_LOADING' && (
        <>
          <Table
            rowKey={'id'}
            dataSource={data?.billingMonthlyLineItems.edges.map(({ node }) => node)}
            loading={loading}
            pagination={false}
            size="middle"
            onChange={handleTableChange}
            bordered
            onRow={record => {
              return {
                style: { cursor: 'pointer' },
                onClick: e => {
                  history.push(`/billing-monthly-line-items/${record.id}`);
                }
              };
            }}
          >
            <Table.Column
              title="Client"
              dataIndex="client"
              key="client"
              render={client => <strong>{client.name}</strong>}
            />

            <Table.Column sorter title="Name" dataIndex="name" key="name" />

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'createdAt' })}
              title="Created"
              dataIndex="createdAt"
              key="createdAt"
              render={renderDatetimeAsDate}
              filtered={isFiltered({ variables, dateRange: true, fieldName: 'createdAt' })}
              filterDropdown={
                <SelectFilter
                  dateRange
                  fieldName="createdAt"
                  Selector={DatePicker.RangePicker}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
            />

            {variables?.includeArchived === 'YES' && (
              <Table.Column
                sorter={{ multiple: 1 }}
                sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'deletedAt' })}
                title="Deleted"
                dataIndex="deletedAt"
                key="deletedAt"
                render={renderDatetimeAsDate}
                filtered={isFiltered({ variables, dateRange: true, fieldName: 'deletedAt' })}
                filterDropdown={
                  <SelectFilter
                    dateRange
                    fieldName="deletedAt"
                    Selector={DatePicker.RangePicker}
                    variables={variables}
                    setVariables={setVariables}
                  />
                }
              />
            )}

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'amount' })}
              title="Rate"
              dataIndex="amount"
              key="amount"
              render={renderMoney}
            />
          </Table>
          <Button
            block
            loading={loading}
            size="large"
            disabled={!data?.billingMonthlyLineItems.pageInfo.hasNextPage}
            onClick={() => fetchMore()}
          >
            Load More
          </Button>
        </>
      )}
      <Modal footer={null} centered visible={isCreateModalVisible} onCancel={() => setIsCreateModalVisible(false)}>
        <BillingMonthlyLineItemForm onSuccess={() => setIsCreateModalVisible(false)} />
      </Modal>
    </>
  );
}

export default withRouter(BillingMonthlyLineItemsPage);
