import React from 'react';
import { Select } from 'antd';
import { CHURN_REASONS } from '../../constants';
import { withFormikAnt } from '../../hocs';

export default function ClientChurnReasonSelector(props) {
  return (
    <Select {...props}>
      {CHURN_REASONS.map(reason => (
        <Select.Option key={reason} value={reason}>
          {reason}
        </Select.Option>
      ))}
    </Select>
  );
}

export const FormikChurnReasonSelector = withFormikAnt()(ClientChurnReasonSelector);
