import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Typography, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import PaginatedQuery from '../paginated-query';
import SectionHeader from '../section-header';
import ClientContactForm from '../forms/client-contact-form';
import ClientContactCard from './client-contact-card';

import { GET_CLIENT_CONTACTS } from '../../queries/client/client-contact-queries';

function ClientContactsList(props) {
  const { id } = props;
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const handleClientContactCreateSuccess = () => {
    setIsCreateModalVisible(false);
  };

  return (
    <div className="client-contacts">
      <SectionHeader
        left={
          <Typography.Title level={2} style={{ marginBottom: 0 }}>
            Contacts
          </Typography.Title>
        }
        right={
          <Button type="secondary" size="small" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Add Contact
          </Button>
        }
      />
      <PaginatedQuery
        query={GET_CLIENT_CONTACTS}
        variables={{ clientId: id, first: 9 }}
        notifyOnNetworkStatusChange={true}
      >
        {getClientContactsResponse => {
          let content;

          const { data, networkStatus } = getClientContactsResponse;

          // loading state
          if (networkStatus === 1) {
            content = <Skeleton />;
          }
          // empty state
          else if (
            networkStatus === 7 &&
            _.isEmpty(_.get(data, 'clientContacts.edges')) &&
            !_.get(data, 'clientContacts.pageInfo.hasNextPage')
          ) {
            content = <p>No client contacts found</p>;
          }
          // results state
          else {
            const clientContacts = _.map(data?.clientContacts?.edges, ({ node }) => ({ ...node, key: node.id }));

            content = _.map(clientContacts, clientContact => {
              return <ClientContactCard clientId={id} clientContact={clientContact} key={clientContact.id} />;
            });
          }

          return <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '15px' }}>{content}</div>;
        }}
      </PaginatedQuery>
      <Modal
        footer={null}
        centered
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
        destroyOnClose={true}
      >
        <ClientContactForm clientId={id} onSuccess={handleClientContactCreateSuccess} />
      </Modal>
    </div>
  );
}

ClientContactsList.propTypes = {
  id: PropTypes.number.isRequired // client ID
};

export default ClientContactsList;
