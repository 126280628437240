import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { Formik, Form } from 'formik';
import { Button } from 'antd';

import FormikInputGroup from './formik-input-group';
import { InputMoney } from './formik-ant';
import { createBillingMonthlyLineItemSchema } from '../../yup-schemas';
import { CREATE_BILLING_MONTHLY_LINE_ITEM, GET_BILLING_MONTHLY_CURRENT_RATES } from '../../queries/billing-queries';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import { FormikClientSelector } from '../searchable-selectors';
import { FormikBillingMonthlyLineItemNameAutoComplete } from '../billing-monthly-line-item-name-autocomplete';

export default function BillingMonthlyLineItemForm(props) {
  const { title, clientId } = props;
  const [createBillingRate] = useMutation(CREATE_BILLING_MONTHLY_LINE_ITEM);

  async function handleFormSubmit(values, formikProps) {
    formikProps.setSubmitting(true);
    try {
      await createBillingRate({
        variables: values,
        refetchQueries: [{ query: GET_BILLING_MONTHLY_CURRENT_RATES, variables: { clientId: values.clientId } }]
      });
      props.onSuccess();
      notification.success({
        message: `Monthly billing line item created successfully`
      });
    } catch (err) {
      notification.error({
        message: `Error on creating billing item: ${err}`
      });
      setAnyFormErrorsFromGraphQlError(err, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  return (
    <>
      {title && <Typography.Title level={3}>Create Monthly Billing Line Item (Misc)</Typography.Title>}
      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        validationSchema={createBillingMonthlyLineItemSchema}
        initialValues={{
          clientId
        }}
        render={formikProps => {
          return (
            <Form onSubmit={formikProps.handleSubmit}>
              {!clientId && (
                <FormikInputGroup
                  name="clientId"
                  label="Client"
                  component={FormikClientSelector}
                  formikProps={formikProps}
                />
              )}

              <FormikInputGroup
                label="Name"
                component={FormikBillingMonthlyLineItemNameAutoComplete}
                name="name"
                formikProps={formikProps}
              />

              <FormikInputGroup
                label="Monthly Rate (USD)"
                component={InputMoney}
                name="amount"
                formikProps={formikProps}
              />
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                Create
              </Button>
            </Form>
          );
        }}
      />
    </>
  );
}

BillingMonthlyLineItemForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  clientId: PropTypes.number,
  title: PropTypes.bool
};

BillingMonthlyLineItemForm.defaultProps = {
  title: true,
  clientId: null
};
