import React from 'react';

function SectionHeader(props) {
  const { right, children, left } = props;

  return (
    <div className="section-header">
      <span className="section-header__left">{left}</span>
      <span className="section-header__center">{children}</span>
      <span className="section-header__right">{right}</span>
    </div>
  );
}

export default SectionHeader;
