import React from 'react';
import { Route } from 'react-router';

import AuthFlowLayout from '../layouts/auth-flow-layout';

function AuthFlowRoute({ component: Component, ...routeProps }) {
  return (
    <Route
      {...routeProps}
      render={matchProps => (
        <AuthFlowLayout>
          <Component {...matchProps} />
        </AuthFlowLayout>
      )}
    />
  );
}

export default AuthFlowRoute;
