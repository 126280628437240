import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { Formik, Form } from 'formik';
import { Button, Typography, Popconfirm } from 'antd';

import { createHardware as CreateHardwareSchema } from '../../yup-schemas';

import FormikInputGroup from './formik-input-group';
import HardwareTypeSelector from './hardware-type-selector';
import { Input, InputMoney } from './formik-ant';

import { CREATE_HARDWARE, UPDATE_HARDWARE, DELETE_HARDWARE } from '../../queries/hardware/hardware-stock-queries';
import { GET_HARDWARE_TYPES } from '../../queries/hardware/hardware-type-queries';
import { GET_HARDWARES } from '../../queries/hardware/hardware-stock-queries';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import RequirePermission from '../require-permission';

const defaultValues = {
  hardwareId: '',
  hardwareTypeId: '',
  cost: 0
};

function Hardware(props) {
  const { hardware, onSuccess } = props;
  const [createHardware] = useMutation(CREATE_HARDWARE);
  const [updateHardware] = useMutation(UPDATE_HARDWARE);
  const [deleteHardware] = useMutation(DELETE_HARDWARE);
  const getHardwareTypesResponse = useQuery(GET_HARDWARE_TYPES);
  const id = _.get(hardware, 'id');
  const isEditing = !_.isEmpty(hardware);
  const [deleting, setDeleting] = useState(false);

  async function handleDelete() {
    setDeleting(true);
    try {
      await deleteHardware({
        variables: { id },
        refetchQueries: [
          { query: GET_HARDWARES },
          {
            query: GET_HARDWARES,
            variables: { filter: { hardwareTypeId: { equalTo: _.get(hardware, 'hardwareType.id') } } }
          }
        ]
      });
      notification.success({ message: `Hardware stock deleted successfully` });
      onSuccess(hardware);
    } catch (error) {
      notification.error({ message: `Failed to delete hardware stock: ${error}.` });
    } finally {
      setDeleting(false);
    }
  }

  if (getHardwareTypesResponse.loading) {
    return <div>Loading...</div>;
  }

  const hardwareTypes = _.get(getHardwareTypesResponse, 'data.hardwareTypes.edges').map(({ node }) => node);

  async function handleFormSubmit(values, formikProps) {
    const verb = isEditing ? 'update' : 'create';

    try {
      formikProps.setSubmitting(true);
      const mutation = isEditing ? updateHardware : createHardware;

      const mutationResult = await mutation({
        variables: {
          ...values,
          id
        },
        refetchQueries: [
          { query: GET_HARDWARES },
          { query: GET_HARDWARES, variables: { filter: { hardwareTypeId: { equalTo: values.hardwareTypeId } } } }
        ]
      });

      notification.success({ message: `Hardware stock ${verb}d successfully!` });
      const hardwareData = _.get(mutationResult, `data.${verb}Hardware.hardware`);
      onSuccess(hardwareData);
    } catch (error) {
      notification.error({ message: `Hardware stock ${verb} failed with error: ${error}.` });
      setAnyFormErrorsFromGraphQlError(error, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        ...defaultValues,
        ...hardware
      }}
      validationSchema={CreateHardwareSchema}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        const { values } = formikProps;
        const selectedHardwareType = _.find(hardwareTypes, { id: _.parseInt(values.hardwareTypeId) });
        const physicalIdType = _.get(selectedHardwareType, 'physicalIdType');

        return (
          <Form>
            <Typography.Title level={2}>
              {isEditing && `Edit Stock`}
              {!isEditing && 'Create Stock'}
            </Typography.Title>

            <FormikInputGroup
              name="hardwareTypeId"
              label="Hardware Type"
              component={HardwareTypeSelector}
              placeholder="ex: test"
              hardwareTypes={hardwareTypes}
              formikProps={formikProps}
            />

            <FormikInputGroup
              name="physicalId"
              label="Hardware ID"
              subLabel={physicalIdType && `(${physicalIdType})`}
              component={Input}
              placeholder="ex: W88401231AX"
              formikProps={formikProps}
            />

            <FormikInputGroup
              name="cost"
              label="Cost"
              component={InputMoney}
              placeholder="ex: 2255"
              formikProps={formikProps}
            />

            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                {isEditing && 'Edit Stock'}
                {!isEditing && 'Create Stock'}
              </Button>
              {isEditing && (
                <RequirePermission permission="MANAGE_ACCOUNTS">
                  <Popconfirm title="Are you sure you want to delete this hardware stock?" onConfirm={handleDelete}>
                    <Button type="danger" icon={<DeleteOutlined />} loading={deleting}>
                      Delete Stock
                    </Button>
                  </Popconfirm>
                </RequirePermission>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
}

Hardware.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default Hardware;
