import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { Formik, Form } from 'formik';
import { Button, Typography, Popconfirm } from 'antd';

import { Checkbox } from '../forms/formik-ant';
import { createHardwareLeaseLineItem as CreateHardwareLeaseLineItemSchema } from '../../yup-schemas';
import FormikInputGroup from './formik-input-group';
import { FormikHardwareSegmentedSelector } from '../searchable-selectors';
import { GET_HARDWARES } from '../../queries/hardware/hardware-stock-queries';
import {
  CREATE_HARDWARE_LEASE_LINE_ITEM,
  UPDATE_HARDWARE_LEASE_LINE_ITEM,
  DELETE_HARDWARE_LEASE_LINE_ITEM,
  GET_HARDWARE_LEASE
} from '../../queries/hardware/hardware-lease-queries';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';

const staticHardwareVariables = {
  filter: { status: { equalTo: 'AT_MEADOW' } }
};
function HardwareLeaseLineItemForm(props) {
  const { hardwareLeaseLineItem, hardwareLeaseId, onSuccess } = props;
  const [createHardwareLeaseLineItem] = useMutation(CREATE_HARDWARE_LEASE_LINE_ITEM);
  const [updateHardwareLeaseLineItem] = useMutation(UPDATE_HARDWARE_LEASE_LINE_ITEM);
  const [deleteLineItem] = useMutation(DELETE_HARDWARE_LEASE_LINE_ITEM);
  const [hardwareTypeId, setHardwareTypeId] = useState(_.get(hardwareLeaseLineItem, 'hardware.hardwareType.id'));
  const isEditing = !_.isEmpty(hardwareLeaseLineItem);
  const [deleting, setDeleting] = useState(false);

  async function handleDeleteLineItem() {
    try {
      setDeleting(true);
      await deleteLineItem({
        variables: { id: hardwareLeaseLineItem.id },
        refetchQueries: [
          {
            query: GET_HARDWARES,
            variables: {
              filter: { hardwareTypeId: { equalTo: _.get(hardwareLeaseLineItem, 'hardware.hardwareType.id') } }
            }
          },
          { query: GET_HARDWARE_LEASE, variables: { id: hardwareLeaseId } }
        ]
      });
      notification.success({ message: `Successfully deleted lease line item` });
      onSuccess();
    } catch (err) {
      notification.error({ message: `Unable to delete lease line item: ${err.message}` });
    } finally {
      setDeleting(false);
    }
  }
  async function handleFormSubmit(values, formikProps) {
    const verb = isEditing ? 'update' : 'create';

    try {
      formikProps.setSubmitting(true);
      const mutation = isEditing ? updateHardwareLeaseLineItem : createHardwareLeaseLineItem;

      const mutationResult = await mutation({
        variables: {
          ...values,
          id: hardwareLeaseLineItem && hardwareLeaseLineItem.id,
          hardwareLeaseId
        },
        refetchQueries: [
          {
            query: GET_HARDWARES,
            variables: {
              filter: {
                status: { equalTo: 'AT_MEADOW' },
                ...(_.get(hardwareLeaseLineItem, 'hardware.hardwareType.id') && {
                  hardwareTypeId: {
                    equalTo: _.get(hardwareLeaseLineItem, 'hardware.hardwareType.id') || hardwareTypeId
                  }
                })
              }
            }
          },
          { query: GET_HARDWARE_LEASE, variables: { id: hardwareLeaseId } }
        ]
      });

      notification.success({ message: `Hardware lease line item ${verb}d successfully!` });
      if (onSuccess) {
        const hardware = _.get(mutationResult, `data.${verb}Hardware.hardware`);
        onSuccess(hardware);
      }
    } catch (error) {
      notification.error({ message: `Hardware lease line item ${verb} failed with error: ${error}.` });
      setAnyFormErrorsFromGraphQlError(error, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={hardwareLeaseLineItem || {}}
      validationSchema={CreateHardwareLeaseLineItemSchema}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        return (
          <Form>
            <Typography.Title level={2}>
              {isEditing && `Edit Line Item`}
              {!isEditing && 'Create Line Item'}
            </Typography.Title>

            <FormikInputGroup
              name="hardwareId"
              label="Hardware"
              disabled={isEditing}
              component={FormikHardwareSegmentedSelector}
              hardwareTypeId={hardwareTypeId}
              setHardwareTypeId={setHardwareTypeId}
              variables={staticHardwareVariables}
              placeholder={
                isEditing
                  ? `${hardwareLeaseLineItem.hardware.hardwareType.typeName} ${hardwareLeaseLineItem.hardware.physicalId} (#${hardwareLeaseLineItem.hardware.id})`
                  : 'Choose hardware for lease'
              }
              formikProps={formikProps}
            />

            {isEditing && (
              <FormikInputGroup name="returned" label="Returned" component={Checkbox} formikProps={formikProps} />
            )}

            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                {isEditing && 'Edit Line Item'}
                {!isEditing && 'Create Line Item'}
              </Button>
              {isEditing && (
                <Popconfirm
                  title={'Are you sure you want to remove this line item?'}
                  onConfirm={() => handleDeleteLineItem()}
                >
                  <Button type="danger" icon={<DeleteOutlined />} loading={deleting}>
                    Remove Line item
                  </Button>
                </Popconfirm>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
}

HardwareLeaseLineItemForm.propTypes = {
  hardwareLeaseId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default HardwareLeaseLineItemForm;
