import React from 'react';
import cx from 'classnames';

import '../styles/spinner.css';

function Spinner(props) {
  const spinnerClassName = cx(props.className, {
    spinner: true,
    'spinner--active': props.active
  });

  return (
    <div className={spinnerClassName}>
      <div className="spinner__parts">
        <span className="spinner__part" />
        <span className="spinner__part" />
        <span className="spinner__part" />
        <span className="spinner__part" />
      </div>
    </div>
  );
}

export default Spinner;
