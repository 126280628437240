import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { notification, Skeleton, Descriptions, Tag, Typography, Button, Modal, Popconfirm, Divider, Alert } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import ClientForm from '../components/forms/client-form';
import SectionHeader from '../components/section-header';
import ClientSalespersons from '../components/client/salespersons';
import ClientContacts from '../components/client/client-contacts-list';
import ClientComments from '../components/client/comments';
import ClientOrganizationsList from '../components/client/client-organizations-list';
import ClientBillingList from '../components/client/client-billing-list';
import FileList from '../components/file-list';
import ClientContracts from '../components/client/client-contracts-list';
import ClientStatusTag from '../components/client/client-status-tag';
import { CompanyTypeTags } from '../components/company-type';
import PageNotFound from '../components/page-not-found';
import PageError from '../components/page-error';
import { renderDatetimeAsDate } from '../utils/datetime';
import { GET_CLIENT, DELETE_CLIENT } from '../queries/client/client-queries';
import { GET_CLIENT_FILES, CREATE_CLIENT_FILE } from '../queries/file-queries';
import RequirePermission from '../components/require-permission';

function ClientPage(props) {
  const {
    history,
    match: { params }
  } = props;
  const id = parseInt(params.id);
  const { loading, error, data } = useQuery(GET_CLIENT, { variables: { id }, notifyOnNetworkStatusChange: true });
  const [deleteClient] = useMutation(DELETE_CLIENT);
  const [isEditingClient, setIsEditingClient] = useState(false);
  const [clientState, setClientState] = useState({});
  const client = { ...data?.client, ...clientState };
  const [deleting, setDeleting] = useState(false);
  if (loading) {
    return <Skeleton />;
  }

  if (!client.id) {
    return <PageNotFound entity="client" />;
  }

  if (error) {
    return <PageError error={error} />;
  }

  async function handleDelete() {
    setDeleting(true);
    try {
      await deleteClient({ variables: { id }, refetchQueries: ['GetClients'] });
      notification.success({
        message: `Successfully deleted client ${client.name}`
      });
      history.push('/clients');
    } catch (err) {
      notification.error({
        message: `Failed to delete client: ${err.message}`
      });
    } finally {
      setDeleting(false);
    }
  }

  const { createdAt, updatedAt, size, status, billingContact } = client;
  return (
    <>
      <SectionHeader
        left={<Typography.Title style={{ marginBottom: 0 }}>{client.name}</Typography.Title>}
        right={
          <>
            <Button icon={<EditOutlined />} onClick={() => setIsEditingClient(true)} />
            <RequirePermission permission="MANAGE_ACCOUNTS">
              <Popconfirm
                title="Are you sure you want to delete this client? Deleting this client will also delete related records like their billing rates."
                onConfirm={handleDelete}
              >
                <Button icon={<DeleteOutlined />} loading={deleting} />
              </Popconfirm>
            </RequirePermission>
          </>
        }
      />
      {(status === 'ACTIVE' || status === 'PRE_LAUNCH') && !billingContact && (
        <>
          <Alert
            type="warning"
            message="There is no billing contact for this client. All billable clients should have a billing contact."
          />
          <br />
        </>
      )}
      <Descriptions>
        <Descriptions.Item label="Status">
          <ClientStatusTag status={client.status} />
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          <CompanyTypeTags types={client.companyTypes} />
        </Descriptions.Item>
        <Descriptions.Item label="Size">
          <Tag>{size}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Created">{renderDatetimeAsDate(createdAt)}</Descriptions.Item>
        <Descriptions.Item label="Updated">{renderDatetimeAsDate(updatedAt)}</Descriptions.Item>
        {client.deletedAt && (
          <Descriptions.Item label="Deleted">{renderDatetimeAsDate(client.deletedAt)}</Descriptions.Item>
        )}
      </Descriptions>
      <Divider />
      <ClientBillingList id={id} />
      <Divider />
      <ClientContacts id={id} />
      <Divider />
      <ClientOrganizationsList id={id} />
      <Divider />
      <ClientContracts id={id} />
      <Divider />
      <ClientSalespersons id={id} />
      <Divider />
      <FileList variables={{ clientId: id }} getQuery={GET_CLIENT_FILES} createMutation={CREATE_CLIENT_FILE} />
      <Divider />
      <ClientComments clientId={id} />
      <Modal footer={null} centered visible={isEditingClient} onCancel={() => setIsEditingClient(false)}>
        <Typography.Title level={2}>Update {client.name}</Typography.Title>
        <ClientForm
          {...client}
          onSuccess={client => {
            setClientState(client);
            setIsEditingClient(false);
          }}
        />
      </Modal>
    </>
  );
}

export default ClientPage;
