import _ from 'lodash';
import React from 'react';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import { Formik, Form } from 'formik';
import { Button, Typography } from 'antd';
import moment from 'moment';

import { createHardwareLease as CreateHardwareLeaseSchema } from '../../yup-schemas';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import FormikInputGroup from './formik-input-group';
import { Input, InputMoney, DatePicker } from './formik-ant';
import { FormikClientSelector } from '../searchable-selectors';
import { CREATE_HARDWARE_LEASE, UPDATE_HARDWARE_LEASE } from '../../queries/hardware/hardware-lease-queries';
import { GET_HARDWARE_SALES } from '../../queries/hardware/hardware-sale-queries';

export default function HardwareLeaseForm(props) {
  const { hardwareLease = {}, onSuccess } = props;
  const [createHardwareLease] = useMutation(CREATE_HARDWARE_LEASE);
  const [updateHardwareLease] = useMutation(UPDATE_HARDWARE_LEASE);
  const isEditing = !_.isEmpty(hardwareLease);

  async function handleFormSubmit(values, formikProps) {
    const verb = isEditing ? 'update' : 'create';

    try {
      formikProps.setSubmitting(true);
      const mutation = isEditing ? updateHardwareLease : createHardwareLease;

      const mutationResult = await mutation({
        variables: values,
        refetchQueries: [GET_HARDWARE_SALES]
      });

      notification.success({ message: `Hardware lease ${verb}d successfully!` });
      if (onSuccess) {
        const hardware = _.get(mutationResult, `data.${verb}HardwareLease.hardwareLease`);
        onSuccess(hardware);
      }
    } catch (error) {
      notification.error({ message: `Hardware lease ${verb} failed with error: ${error}.` });
      setAnyFormErrorsFromGraphQlError(error, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        ...hardwareLease,
        invoiceDate: hardwareLease.invoiceDate && moment(hardwareLease.invoiceDate)
      }}
      validationSchema={CreateHardwareLeaseSchema}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        return (
          <Form>
            <Typography.Title level={2}>
              {isEditing && `Edit Hardware Lease`}
              {!isEditing && 'Create Hardware Lease'}
            </Typography.Title>

            <FormikInputGroup
              name="clientId"
              label="Client"
              component={FormikClientSelector}
              disabled={isEditing}
              formikProps={formikProps}
            />

            <FormikInputGroup
              name="price"
              label="Price (USD)"
              component={InputMoney}
              placeholder="ex: 12500"
              formikProps={formikProps}
            />

            {isEditing && (
              <>
                <FormikInputGroup
                  name="invoiceDate"
                  label="Invoice Date"
                  component={DatePicker}
                  placeholder="Select Date"
                  formikProps={formikProps}
                />

                <FormikInputGroup
                  name="invoiceLink"
                  label="Invoice Link"
                  component={Input}
                  placeholder="ex: https://timz-custom-invoices.net/invoices/420"
                  formikProps={formikProps}
                />
              </>
            )}

            <FormikInputGroup
              name="description"
              label="Description"
              component={Input}
              placeholder="What is the occasion for this lease, an event?"
              formikProps={formikProps}
            />

            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                {isEditing && 'Edit Lease'}
                {!isEditing && 'Create Lease'}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
}
