import _ from 'lodash';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { PERMISSIONS } from '../constants';
import { CurrentUserContext } from '../global-contexts';

export default function RequirePermission({ permission, children }) {
  const [currentUser] = useContext(CurrentUserContext);
  const currentPermissions = _.get(currentUser, 'accountPermissions.nodes', []);
  if (currentPermissions.find(n => n.permission === permission)) {
    return children;
  }
  return <noscript />;
}

RequirePermission.propTypes = {
  permission: PropTypes.oneOf(PERMISSIONS.map(p => p.value)).isRequired,
  children: PropTypes.node.isRequired
};
