import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Descriptions, notification, Popconfirm, Skeleton, Typography } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PageError from '../components/page-error';
import PageNotFound from '../components/page-not-found';
import RequirePermission from '../components/require-permission';
import SectionHeader from '../components/section-header';
import {
  DELETE_BILLING_MONTHLY_LINE_ITEM,
  GET_BILLING_MONTHLY_CURRENT_RATES,
  GET_BILLING_MONTHLY_LINE_ITEM
} from '../queries/billing-queries';
import { renderDatetimeAsDate } from '../utils/datetime';
import getLoadingState from '../utils/get-loading-state';
import { renderMoney } from '../utils/money';

export default function BillingMonthlyLineItemDetailPage(props) {
  const {
    match: {
      params: { id }
    },
    history
  } = props;
  const [deleting, setDeleting] = useState(false);

  const [deleteNode] = useMutation(DELETE_BILLING_MONTHLY_LINE_ITEM);
  const response = useQuery(GET_BILLING_MONTHLY_LINE_ITEM, { variables: { id: Number(id) } });
  const { data, error } = response;

  async function handleDelete() {
    try {
      await deleteNode({
        variables: { id: Number(id) },
        refetchQueries: [
          { query: GET_BILLING_MONTHLY_CURRENT_RATES, variables: { clientId: data?.billingMonthlyLineItem?.id } }
        ]
      });
      notification.success({ message: 'Successfully deleted monthly billing item' });
      history.push('/billing-monthly-line-items');
    } catch (err) {
      notification.error({ message: `Unable to delete monthly billing item: ${err.message}` });
    } finally {
      setDeleting(false);
    }
  }

  const loadingState = getLoadingState(response);

  if (error) {
    return <PageError error={error} />;
  }
  if (loadingState === 'FIRST_LOADING') {
    return <Skeleton />;
  }
  if (!data?.billingMonthlyLineItem?.id) {
    return <PageNotFound entity="Monthly Billing Line Item" />;
  }

  return (
    <>
      <SectionHeader
        left={<Typography.Title style={{ marginBottom: 0 }}>Monthly Billing Line Item (Misc)</Typography.Title>}
        right={
          <RequirePermission permission="MANAGE_ACCOUNTS">
            <Popconfirm title={'Are you sure you want to delete this billing item?'} onConfirm={() => handleDelete()}>
              <Button icon={<DeleteOutlined />} loading={deleting} />
            </Popconfirm>
          </RequirePermission>
        }
      />

      <Descriptions title={'Details'}>
        <Descriptions.Item label="Client">
          <Link to={`/clients/${data?.billingMonthlyLineItem.client.id}`}>
            {data?.billingMonthlyLineItem.client.name}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Name">{data?.billingMonthlyLineItem.name}</Descriptions.Item>
        <Descriptions.Item label="Amount">{renderMoney(data?.billingMonthlyLineItem.amount)}</Descriptions.Item>
        <Descriptions.Item label="Created">
          {renderDatetimeAsDate(data?.billingMonthlyLineItem.createdAt)}
        </Descriptions.Item>
        {data?.billingMonthlyLineItem.deletedAt && (
          <Descriptions.Item label="Deleted">
            {renderDatetimeAsDate(data?.billingMonthlyLineItem.deletedAt)}
          </Descriptions.Item>
        )}
      </Descriptions>
    </>
  );
}
