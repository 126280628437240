import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Button, Typography, Modal, Table, Popconfirm, notification, Descriptions, Skeleton } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import HardwareSaleForm from '../components/forms/hardware-sale-form';
import HardwareSaleLineItemForm from '../components/forms/hardware-sale-line-item-form';
import SectionHeader from '../components/section-header';
import { renderDatetimeAsDate } from '../utils/datetime';
import { renderMoney } from '../utils/money';
import getLoadingState from '../utils/get-loading-state';
import PageError from '../components/page-error';
import PageNotFound from '../components/page-not-found';

import { GET_HARDWARE_SALE, DELETE_HARDWARE_SALE } from '../queries/hardware/hardware-sale-queries';
import RequirePermission from '../components/require-permission';

export default function HardwareSalePage({
  match: {
    params: { id }
  },
  history
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [deleteSale] = useMutation(DELETE_HARDWARE_SALE);
  const response = useQuery(GET_HARDWARE_SALE, {
    variables: { id: Number(id) },
    notifyOnNetworkStatusChange: true
  });
  const { data, error, loading } = response;
  const [deleting, setDeleting] = useState(false);
  const loadingState = getLoadingState(response);
  async function handleDeleteSale() {
    setDeleting(true);
    try {
      await deleteSale({ variables: { id: Number(id) } });
      notification.success({ message: `successfully deleted sale` });
      history.push('/hardware-sales');
    } catch (err) {
      notification.error({ message: `Unable to delete sale: ${err.message}` });
    } finally {
      setDeleting(false);
    }
  }
  if (loadingState === 'FIRST_LOADING') {
    return <Skeleton />;
  }
  if (error) {
    return <PageError error={error} />;
  }
  if (!_.get(data, 'hardwareSale.id') || _.get(data, 'hardwareSale.deletedAt')) {
    return <PageNotFound entity="hardware sale" />;
  }

  const description = _.get(data, 'hardwareSale.description');

  return (
    <>
      <SectionHeader
        left={
          <Typography.Title style={{ marginBottom: 0 }}>{description ? description : 'Hardware Sale'}</Typography.Title>
        }
        right={
          <>
            <Button
              icon={<EditOutlined />}
              style={{ flex: '0 0 auto', justifySelf: 'flex-end' }}
              onClick={() => setIsEditing(true)}
            />
            <RequirePermission permission="MANAGE_ACCOUNTS">
              <Popconfirm title={'Are you sure you want to delete this sale?'} onConfirm={() => handleDeleteSale()}>
                <Button icon={<DeleteOutlined />} loading={deleting} />
              </Popconfirm>
            </RequirePermission>
          </>
        }
      />
      <Typography.Title level={4}>Details</Typography.Title>
      <Descriptions>
        <Descriptions.Item label="Client">
          <Link to={`/clients/${_.get(data, 'hardwareSale.client.id')}`}>
            {_.get(data, 'hardwareSale.client.name')}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Total Price">{renderMoney(_.get(data, 'hardwareSale.totalPrice'))}</Descriptions.Item>
        <Descriptions.Item label="Item Count">{_.get(data, 'hardwareSale.itemCount')}</Descriptions.Item>
        <Descriptions.Item label="Invoice Date">
          {renderDatetimeAsDate(_.get(data, 'hardwareSale.invoiceDate'))}
        </Descriptions.Item>
        <Descriptions.Item label="Invoice Link">
          <a href={_.get(data, 'hardwareSale.invoiceLink')}>{_.get(data, 'hardwareSale.invoiceLink')}</a>
        </Descriptions.Item>
        <Descriptions.Item label="Created">
          {renderDatetimeAsDate(_.get(data, 'hardwareSale.createdAt'))}
        </Descriptions.Item>
        <Descriptions.Item label="Updated">
          {renderDatetimeAsDate(_.get(data, 'hardwareSale.updatedAt'))}
        </Descriptions.Item>
      </Descriptions>
      <SectionHeader
        left={<Typography.Title level={4}>Line Items</Typography.Title>}
        right={
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Add Line Item
          </Button>
        }
      />
      <Table
        dataSource={data?.hardwareSale?.hardwareSaleLineItems.nodes}
        pagination={false}
        size="middle"
        bordered
        loading={loading}
        rowKey={row => row.id}
        onRow={record => {
          return {
            style: { cursor: 'pointer' },
            onClick: () => setIsCreateModalVisible(record.id)
          };
        }}
      >
        <Table.Column title="ID" dataIndex="hardware" key="id" render={node => node.id} />
        <Table.Column
          title="Type"
          dataIndex="hardware"
          key="hardwareType"
          render={node => node.hardwareType.typeName}
        />
        <Table.Column title="Physical ID" dataIndex="hardware" key="physicalId" render={node => node.physicalId} />
        <Table.Column title="Cost" dataIndex="hardware" key="cost" render={node => renderMoney(node.cost)} />
        <Table.Column title="Price" dataIndex="price" key="price" render={renderMoney} />
      </Table>
      <Modal destroyOnClose footer={null} centered visible={isEditing} onCancel={() => setIsEditing(false)}>
        <HardwareSaleForm
          hardwareSale={data?.hardwareSale}
          onSuccess={client => {
            setIsEditing(false);
          }}
        />
      </Modal>
      <Modal
        destroyOnClose
        footer={null}
        centered
        visible={!!isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
      >
        <HardwareSaleLineItemForm
          hardwareSaleLineItem={data?.hardwareSale?.hardwareSaleLineItems?.nodes.find(
            node => node.id === isCreateModalVisible
          )}
          hardwareSaleId={Number(id)}
          onSuccess={() => {
            setIsCreateModalVisible(false);
          }}
        />
      </Modal>
    </>
  );
}

HardwareSalePage.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired }).isRequired
};
