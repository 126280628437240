import React from 'react';
import { Result, Button } from 'antd';
import { withRouter } from 'react-router-dom';

function PageNotFound({ entityName = 'page', history, ...passThroughProps }) {
  return (
    <Result
      status="404"
      title="Not Found"
      subTitle={`Sorry that ${entityName} was not found.`}
      extra={<Button onClick={() => history.goBack()}>Go back</Button>}
      {...passThroughProps}
    />
  );
}

export default withRouter(PageNotFound);
