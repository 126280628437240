import { gql } from '@apollo/client';
import { CLIENT_CONTACT_FRAGMENT } from './client-contact-queries';
import { CLIENT_ORGANIZATION_FIELDS_FRAGMENT } from './client-organization-queries';

export const CLIENT_FIELDS_FRAGMENT = gql`
  fragment ClientFieldsFragment on Client {
    id
    name
    size
    createdAt
    updatedAt
    deletedAt
    companyTypes
    status
    primaryOrganization {
      ...ClientOrganizationFields
    }
    primaryContact {
      ...ClientContactFields
    }
    billingContact {
      ...ClientContactFields
    }
  }
  ${CLIENT_CONTACT_FRAGMENT}
  ${CLIENT_ORGANIZATION_FIELDS_FRAGMENT}
`;

export const GET_CLIENTS = gql`
  query GetClients(
    $first: Int = 20
    $after: Cursor
    $filter: ClientFilter
    $orderBy: [ClientsOrderBy!] = [ID_DESC]
    $includeArchived: IncludeArchivedOption = NO
  ) {
    clients(first: $first, after: $after, filter: $filter, orderBy: $orderBy, includeArchived: $includeArchived) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...ClientFieldsFragment
        }
      }
    }
  }
  ${CLIENT_FIELDS_FRAGMENT}
`;

export const GET_CLIENT_NAMES = gql`
  query GetClients(
    $first: Int = 200
    $after: Cursor
    $filter: ClientFilter
    $orderBy: [ClientsOrderBy!] = [ID_DESC]
    $includeArchived: IncludeArchivedOption = YES
  ) {
    clients(first: $first, after: $after, filter: $filter, orderBy: $orderBy, includeArchived: $includeArchived) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_CLIENT = gql`
  query GetClient($id: Int!) {
    client(id: $id) {
      ...ClientFieldsFragment
    }
  }
  ${CLIENT_FIELDS_FRAGMENT}
`;

export const DELETE_CLIENT = gql`
  mutation DeleteClient($id: Int!) {
    deleteClient(input: { id: $id }) {
      client {
        ...ClientFieldsFragment
      }
    }
  }
  ${CLIENT_FIELDS_FRAGMENT}
`;
