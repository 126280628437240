import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const STATUS_TO_COLOR = {
  PRE_LAUNCH: 'blue',
  ACTIVE: 'green',
  CHURNED: 'Red',
  NEVER_LAUNCHED: 'orange'
};

export default function ClientStatusTag({ status }) {
  return <Tag color={STATUS_TO_COLOR[status]}>{status}</Tag>;
}

ClientStatusTag.propTypes = {
  status: PropTypes.oneOf(Object.keys(STATUS_TO_COLOR)).isRequired
};
