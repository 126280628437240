import React from 'react';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import * as Yup from 'yup';

import { Formik, Form } from 'formik';
import { Button } from 'antd';

import { setPassword } from '../../yup-schemas';
import FormikInputGroup from './formik-input-group';
import { Input } from './formik-ant';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import { SET_PASSWORD } from '../../queries/user.js';

const SetPasswordSchema = setPassword.shape({
  passwordConfirmation: Yup.string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(8, 'Password confirmation must be at least ${min} characters long')
    .required('Password confirmation must be specified')
});

function SetPasswordForm(props) {
  const { token, onSuccess } = props;
  const [setPassword] = useMutation(SET_PASSWORD);

  function validate(values) {
    const errors = {};

    if (values.password !== values.passwordConfirmation) {
      errors.password = errors.passwordConfirmation = 'Password and password confirmation must match';
    }

    return errors;
  }

  async function handleFormSubmit(values, formikProps) {
    formikProps.setSubmitting(true);

    try {
      await setPassword({ variables: { ...values, token } });

      notification.success({ message: 'Password set successfully' });
      if (onSuccess) {
        onSuccess();
      }
    } catch (setPasswordError) {
      notification.error({ message: `Password set failed with error: ${setPasswordError.message}` });
      setAnyFormErrorsFromGraphQlError(setPasswordError, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{}}
      validate={validate}
      validationSchema={SetPasswordSchema}
      onSubmit={handleFormSubmit}
      render={formikProps => {
        return (
          <Form>
            <FormikInputGroup
              label="Password"
              name="password"
              component={Input}
              type="password"
              hideErrorsUntilSubmit
              formikProps={formikProps}
            />

            <FormikInputGroup
              label="Confirm Password"
              name="passwordConfirmation"
              component={Input}
              type="password"
              hideErrorsUntilSubmit
              formikProps={formikProps}
            />

            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                Set Password
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default SetPasswordForm;
