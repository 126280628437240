/**
 * Wrapper for apollo reacts query to make pagination easy
 * Just like the apollo Query component except fetchMore is enhanced so just calling it will... fetch more
 *
 * Makes assumptions about variable names used for pagination: first, last, after, before
 */

import { Query } from '@apollo/client/react/components';
import React from 'react';
import produce from 'immer';
import { useQuery } from '@apollo/client';

export function wrapFetchMore({ data, fetchMore, variables = {} }) {
  return function wrappedFetchMore(fetchMoreOverrides) {
    const [resourceName] = Object.keys(data || {});
    const resource = data[resourceName];
    const { edges } = resource;
    const lastEdge = edges[edges.length - 1];
    const { first, last, ...otherVariables } = variables;
    const newVariables = { ...otherVariables };
    if (lastEdge) {
      const { cursor } = lastEdge;
      if (first) {
        newVariables.after = cursor;
      } else if (last) {
        newVariables.before = cursor;
        // note: this is assuming that pagination is going forward
        // in absence of knowing from variable name
      } else {
        newVariables.after = cursor;
      }
    }
    fetchMore({
      variables: newVariables,
      updateQuery: (prev, { fetchMoreResult }) => {
        const { edges, pageInfo } = fetchMoreResult[resourceName];
        if (edges.length === 0) {
          return prev;
        }
        return produce(state => {
          const returnedIds = {};
          edges.forEach(e => {
            returnedIds[e.node.id] = true;
          });
          // remove any ids from the previous state that were returned in the new page
          state[resourceName].edges = state[resourceName].edges.filter(e => !returnedIds[e.node.id]);
          if (first) {
            state[resourceName].edges = [...state[resourceName].edges, ...edges];
          } else if (last) {
            state[resourceName].edges = [...edges, ...state[resourceName].edges];
          } else {
            state[resourceName].edges = [...state[resourceName].edges, ...edges];
          }
          state[resourceName].pageInfo = pageInfo;
        })(prev);
      },
      ...fetchMoreOverrides
    });
  };
}
function PaginatedQuery({ children, variables, ...otherProps }) {
  return (
    <Query fetchPolicy="network-only" variables={variables} {...otherProps}>
      {({ data, fetchMore, ...otherArgs }) =>
        children({
          data,
          fetchMore: wrapFetchMore({ data, fetchMore, variables }),
          ...otherArgs
        })
      }
    </Query>
  );
}

export function usePaginatedQuery(query, options) {
  const queryProps = useQuery(query, {
    fetchPolicy: 'network-only',
    ...options
  });
  const fetchMore = wrapFetchMore(queryProps);
  return {
    ...queryProps,
    fetchMore
  };
}

export default PaginatedQuery;
