import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Typography, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import PaginatedQuery from '../paginated-query';
import SectionHeader from '../section-header';
import ClientOrganizationForm from '../forms/client-organization-form';
import ClientOrganizationCard from './client-organization-card';
import getLoadingState from '../../utils/get-loading-state';
import { GET_CLIENT_ORGANIZATIONS } from '../../queries/client/client-organization-queries';

function ClientOrganizationsList(props) {
  const { id } = props;
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const handleClientOrganizationCreateSuccess = () => {
    setIsCreateModalVisible(false);
  };

  return (
    <>
      <SectionHeader
        left={
          <Typography.Title level={2} style={{ marginBottom: 0 }}>
            Organizations
          </Typography.Title>
        }
        right={
          <Button type="secondary" size="small" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Add Organization
          </Button>
        }
      />
      <PaginatedQuery
        query={GET_CLIENT_ORGANIZATIONS}
        variables={{ filter: { clientId: { equalTo: id } }, first: 9 }}
        notifyOnNetworkStatusChange={true}
      >
        {response => {
          const loadingState = getLoadingState(response);
          if (loadingState === 'FIRST_LOADING') {
            return <Skeleton />;
          } else if (loadingState === 'EMPTY') {
            return <p>No organizations found</p>;
          } else {
            return (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '15px' }}>
                {response.data?.clientOrganizations?.edges.map(({ node: clientOrganization }) => (
                  <ClientOrganizationCard
                    clientOrganization={clientOrganization}
                    key={clientOrganization.id}
                    clientId={id}
                  />
                ))}
              </div>
            );
          }
        }}
      </PaginatedQuery>
      <Modal
        destroyOnClose
        footer={null}
        centered
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
      >
        <ClientOrganizationForm clientId={id} onSuccess={handleClientOrganizationCreateSuccess} />
      </Modal>
    </>
  );
}

ClientOrganizationsList.propTypes = {
  id: PropTypes.number.isRequired
};

export default ClientOrganizationsList;
