/**
 * Wraps the children component with authenticated user controls (IE top bar with logout)
 * Checks if the user is logged in according to a cookie and retrieves the current user
 */

import React, { useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import cookie from 'cookie';
import { withRouter } from 'react-router-dom';

import { CurrentUserContext } from '../global-contexts';

import AuthenticatedLayout from './layouts/authenticated-layout';
import Centered from './centered';
import Spinner from './spinner';

import { ME_QUERY } from '../queries/account';

function Authenticated({ children, history }) {
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);
  const apollo = useApolloClient();

  useEffect(() => {
    async function getCurrentUser() {
      const cookies = cookie.parse(document.cookie);
      if (cookies['meadow-internal-client-dir-logged-in']) {
        try {
          const result = await apollo.query({
            query: ME_QUERY,
            fetchPolicy: 'network-only'
          });
          setCurrentUser(result.data?.me);
        } catch (error) {
          history.push('/login');
        }
      } else {
        history.push('/login');
      }
    }
    if (!currentUser && document && typeof document.cookie === 'string') {
      getCurrentUser();
    }
  }, [apollo, history, setCurrentUser, currentUser]);

  if (!currentUser) {
    return (
      <Centered>
        <Spinner active />
      </Centered>
    );
  }

  return <AuthenticatedLayout>{children}</AuthenticatedLayout>;
}

export default withRouter(Authenticated);
