import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Button, Typography, Modal, Table, Popconfirm, notification, Descriptions, Skeleton } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import HardwareLeaseForm from '../components/forms/hardware-lease-form';
import HardwareLeaseLineItemForm from '../components/forms/hardware-lease-line-item-form';
import SectionHeader from '../components/section-header';
import { renderDatetimeAsDate } from '../utils/datetime';
import { renderMoney } from '../utils/money';
import getLoadingState from '../utils/get-loading-state';
import PageError from '../components/page-error';
import PageNotFound from '../components/page-not-found';
import RequirePermission from '../components/require-permission';

import { GET_HARDWARE_LEASE, DELETE_HARDWARE_LEASE } from '../queries/hardware/hardware-lease-queries';

export default function HardwareLeasePage({
  match: {
    params: { id }
  },
  history
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [deleteLease] = useMutation(DELETE_HARDWARE_LEASE);
  const response = useQuery(GET_HARDWARE_LEASE, {
    variables: { id: Number(id) },
    notifyOnNetworkStatusChange: true
  });
  const { data, error, loading } = response;
  const loadingState = getLoadingState(response);
  const [deleting, setDeleting] = useState(false);
  async function handleDeleteLease() {
    setDeleting(true);
    try {
      await deleteLease({ variables: { id: Number(id) } });
      notification.success({ message: `successfully deleted lease` });
      history.push('/hardware-leases');
    } catch (err) {
      notification.error({ message: `Unable to delete lease: ${err.message}` });
    } finally {
      setDeleting(false);
    }
  }
  if (error) {
    return <PageError error={error} />;
  }
  if (loadingState === 'FIRST_LOADING') {
    return <Skeleton />;
  }
  if (!_.get(data, 'hardwareLease.id') || _.get(data, 'hardwareLease.deleteAt')) {
    return <PageNotFound entity="hardware lease" />;
  }

  const description = _.get(data, 'hardwareLease.description');

  return (
    <>
      <SectionHeader
        left={
          <Typography.Title style={{ marginBottom: 0 }}>
            {description ? description : 'Hardware Lease'}
          </Typography.Title>
        }
        right={
          <>
            <Button
              icon={<EditOutlined />}
              style={{ flex: '0 0 auto', justifySelf: 'flex-end' }}
              onClick={() => setIsEditing(true)}
            />
            <RequirePermission permission="MANAGE_ACCOUNTS">
              <Popconfirm title={'Are you sure you want to delete this lease?'} onConfirm={() => handleDeleteLease()}>
                <Button icon={<DeleteOutlined />} loading={deleting} />
              </Popconfirm>
            </RequirePermission>
          </>
        }
      />
      <Descriptions title={'Details'}>
        <Descriptions.Item label="Client">
          <Link to={`/clients/${_.get(data, 'hardwareLease.client.id')}`}>
            {_.get(data, 'hardwareLease.client.name')}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Price">{renderMoney(_.get(data, 'hardwareLease.price'))}</Descriptions.Item>
        <Descriptions.Item label="Item Count">{_.get(data, 'hardwareLease.itemCount')}</Descriptions.Item>
        <Descriptions.Item label="Invoice Date">
          {renderDatetimeAsDate(_.get(data, 'hardwareLease.invoiceDate'))}
        </Descriptions.Item>
        <Descriptions.Item label="Invoice Link">
          <a href={_.get(data, 'hardwareLease.invoiceLink')}>{_.get(data, 'hardwareLease.invoiceLink')}</a>
        </Descriptions.Item>
        <Descriptions.Item label="Created">
          {renderDatetimeAsDate(_.get(data, 'hardwareLease.createdAt'))}
        </Descriptions.Item>
        <Descriptions.Item label="Updated">
          {renderDatetimeAsDate(_.get(data, 'hardwareLease.updatedAt'))}
        </Descriptions.Item>
      </Descriptions>
      <SectionHeader
        left={<Typography.Title level={3}>Line Items</Typography.Title>}
        right={
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Add Line Item
          </Button>
        }
      />
      <Table
        dataSource={data && data?.hardwareLease && data?.hardwareLease.hardwareLeaseLineItems.nodes}
        pagination={false}
        size="middle"
        bordered
        loading={loading}
        rowKey={row => row.id}
        onRow={record => {
          return {
            style: { cursor: 'pointer' },
            onClick: () => setIsCreateModalVisible(record.id)
          };
        }}
      >
        <Table.Column title="ID" dataIndex="hardware" key="id" render={node => node.id} />
        <Table.Column
          title="Type"
          dataIndex="hardware"
          key="hardwareType"
          render={node => node.hardwareType.typeName}
        />
        <Table.Column title="Physical ID" dataIndex="hardware" key="physicalId" render={node => node.physicalId} />
        <Table.Column title="Cost" dataIndex="hardware" key="cost" render={node => renderMoney(node.cost)} />
        <Table.Column title="Returned" dataIndex="returned" key="returned" render={value => (value ? 'Yes' : 'No')} />
      </Table>
      <Modal footer={null} centered visible={isEditing} onCancel={() => setIsEditing(false)}>
        <HardwareLeaseForm
          hardwareLease={data?.hardwareLease}
          onSuccess={client => {
            setIsEditing(false);
          }}
        />
      </Modal>
      <Modal
        destroyOnClose
        footer={null}
        centered
        visible={!!isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
      >
        <HardwareLeaseLineItemForm
          hardwareLeaseLineItem={
            data &&
            data?.hardwareLease &&
            data?.hardwareLease.hardwareLeaseLineItems &&
            data?.hardwareLease.hardwareLeaseLineItems.nodes.find(node => node.id === isCreateModalVisible)
          }
          hardwareLeaseId={Number(id)}
          onSuccess={() => {
            setIsCreateModalVisible(false);
          }}
        />
      </Modal>
    </>
  );
}

HardwareLeasePage.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired }).isRequired
};
