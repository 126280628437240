import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Button, DatePicker, notification, Typography } from 'antd';
import { Formik, Form } from 'formik';
import moment from 'moment';

import { createContract as createContractSchema, updateContract as updateContractSchema } from '../../yup-schemas';
import { InputMoney } from './formik-ant';
import FormikInputGroup from './formik-input-group';
import {
  GET_CLIENT_CONTRACT,
  CREATE_CLIENT_CONTRACT,
  UPDATE_CLIENT_CONTRACT
} from '../../queries/client/client-contract-queries';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import { FormikClientSelector } from '../searchable-selectors';

export default function ContractForm(props) {
  const { clientId, contract = {}, onSuccess } = props;
  const { id, startDate, endDate, amount } = contract;
  const [createContract] = useMutation(CREATE_CLIENT_CONTRACT);
  const [updateContract] = useMutation(UPDATE_CLIENT_CONTRACT);
  const isEditing = !!id;

  async function handleFormSubmit(values, formikProps) {
    // default set through initial values on form
    const { clientId } = values;

    formikProps.setSubmitting(true);
    try {
      const mutation = isEditing ? updateContract : createContract;
      const res = await mutation({
        variables: { ...values, id, clientId },
        refetchQueries: isEditing && [{ query: GET_CLIENT_CONTRACT, variables: { id } }]
      });
      const data = res.data[isEditing ? 'updateContract' : 'createContract'].contract;
      onSuccess(data);
      notification.success({
        message: `Client contract ${isEditing ? 'updated' : 'created'} successfully`
      });
    } catch (err) {
      notification.error({
        message: `Error on  ${isEditing ? 'updating' : 'creating'} Client contract: ${err}`
      });
      setAnyFormErrorsFromGraphQlError(err, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  return (
    <>
      <Typography.Title level={3}>{isEditing ? 'Edit' : 'Create'} Contract</Typography.Title>
      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        validationSchema={isEditing ? updateContractSchema : createContractSchema}
        initialValues={{
          clientId,
          ...(isEditing && {
            amount,
            startDate: moment(startDate),
            endDate: moment(endDate)
          })
        }}
        render={formikProps => {
          return (
            <Form className="client-contract-form" onSubmit={formikProps.handleSubmit}>
              {!clientId && !isEditing && (
                <FormikInputGroup
                  name="clientId"
                  label="Client"
                  component={FormikClientSelector}
                  formikProps={formikProps}
                />
              )}
              <FormikInputGroup
                label="Contract Start and End"
                render={({ form }) => (
                  <DatePicker.RangePicker
                    value={[form.values.startDate, form.values.endDate]}
                    onChange={([start, end]) => {
                      form.setFieldValue('startDate', start);
                      form.setFieldValue('endDate', end);
                    }}
                  />
                )}
                name="dateRange"
                formikProps={formikProps}
              />
              <FormikInputGroup
                label="Annual Contract Value (USD)"
                component={InputMoney}
                name="amount"
                formikProps={formikProps}
              />
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                {isEditing ? 'Update Contract' : 'Create Contract'}
              </Button>
            </Form>
          );
        }}
      />
    </>
  );
}

ContractForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  clientId: PropTypes.number,
  contract: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    amount: PropTypes.string
  })
};
