import { gql } from '@apollo/client';

export const GET_ORGANIZATION = gql`
  query GetOrganization($id: Int!) {
    organization(id: $id) {
      name
    }
  }
`;

export const CLIENT_ORGANIZATION_STATUS_FIELDS_FRAGMENT = gql`
  fragment ClientOrganizationStatusFields on ClientOrganizationStatus {
    id
    statusDatetime
    createdAt
    updatedAt
    status
    churnReason
    description
  }
`;

export const CLIENT_ORGANIZATION_TYPE_FIELDS_FRAGMENT = gql`
  fragment ClientOrganizationTypeFields on ClientOrganizationType {
    id
    companyType
  }
`;

export const CLIENT_ORGANIZATION_FIELDS_FRAGMENT = gql`
  fragment ClientOrganizationFields on ClientOrganization {
    clientId
    createdAt
    deletedAt
    description
    id
    organizationId
    updatedAt
    isPrimary
    status
    companyTypes
    clientOrganizationTypes {
      nodes {
        id
        companyType
      }
    }
    client {
      id
      name
    }
    clientOrganizationStatuses(orderBy: [ID_DESC]) {
      nodes {
        ...ClientOrganizationStatusFields
      }
    }
  }
  ${CLIENT_ORGANIZATION_STATUS_FIELDS_FRAGMENT}
`;

export const GET_CLIENT_ORGANIZATION = gql`
  query GetClientOrganization($id: Int!) {
    clientOrganization(id: $id) {
      ...ClientOrganizationFields
    }
  }
  ${CLIENT_ORGANIZATION_FIELDS_FRAGMENT}
`;

export const GET_CLIENT_ORGANIZATIONS = gql`
  query GetClientOrganizations(
    $first: Int = 20
    $after: Cursor
    $filter: ClientOrganizationFilter
    $orderBy: [ClientOrganizationsOrderBy!] = [ID_DESC]
    $includeArchived: IncludeArchivedOption
  ) {
    clientOrganizations(
      first: $first
      after: $after
      filter: $filter
      orderBy: $orderBy
      includeArchived: $includeArchived
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...ClientOrganizationFields
        }
      }
    }
  }
  ${CLIENT_ORGANIZATION_FIELDS_FRAGMENT}
`;

export const CREATE_CLIENT_ORGANIZATION = gql`
  mutation CreateClientOrganization(
    $clientId: Int!
    $organizationId: Int!
    $description: String!
    $isPrimary: Boolean
  ) {
    createClientOrganization(
      input: {
        clientOrganization: {
          clientId: $clientId
          organizationId: $organizationId
          description: $description
          isPrimary: $isPrimary
        }
      }
    ) {
      clientOrganization {
        ...ClientOrganizationFields
      }
    }
  }
  ${CLIENT_ORGANIZATION_FIELDS_FRAGMENT}
`;

export const UPDATE_CLIENT_ORGANIZATION = gql`
  mutation UpdateClientOrganization($id: Int!, $organizationId: Int, $description: String, $isPrimary: Boolean) {
    updateClientOrganization(
      input: { patch: { organizationId: $organizationId, description: $description, isPrimary: $isPrimary }, id: $id }
    ) {
      clientOrganization {
        ...ClientOrganizationFields
      }
    }
  }
  ${CLIENT_ORGANIZATION_FIELDS_FRAGMENT}
`;

export const DELETE_CLIENT_ORGANIZATION = gql`
  mutation DeleteClientOrganization($id: Int!) {
    deleteClientOrganization(input: { id: $id }) {
      clientOrganization {
        ...ClientOrganizationFields
      }
    }
  }
  ${CLIENT_ORGANIZATION_FIELDS_FRAGMENT}
`;

export const CREATE_CLIENT_ORGANIZATION_STATUS = gql`
  mutation CreateClientOrganizationStatus(
    $clientOrganizationId: Int!
    $status: ClientState!
    $statusDatetime: Datetime!
    $churnReason: String
    $description: String
  ) {
    createClientOrganizationStatus(
      input: {
        clientOrganizationStatus: {
          clientOrganizationId: $clientOrganizationId
          status: $status
          statusDatetime: $statusDatetime
          description: $description
          churnReason: $churnReason
        }
      }
    ) {
      clientOrganizationStatus {
        ...ClientOrganizationStatusFields
      }
    }
  }
  ${CLIENT_ORGANIZATION_STATUS_FIELDS_FRAGMENT}
`;

export const UPDATE_CLIENT_ORGANIZATION_STATUS = gql`
  mutation UpdateClientOrganizationStatus(
    $id: Int!
    $status: ClientState!
    $statusDatetime: Datetime!
    $churnReason: String
    $description: String
  ) {
    updateClientOrganizationStatus(
      input: {
        id: $id
        patch: {
          status: $status
          statusDatetime: $statusDatetime
          churnReason: $churnReason
          description: $description
        }
      }
    ) {
      clientOrganizationStatus {
        ...ClientOrganizationStatusFields
      }
    }
  }
  ${CLIENT_ORGANIZATION_STATUS_FIELDS_FRAGMENT}
`;

export const DELETE_CLIENT_ORGANIZATION_STATUS = gql`
  mutation DeleteClientOrganizationStatus($id: Int!) {
    deleteClientOrganizationStatus(input: { id: $id }) {
      clientOrganizationStatus {
        ...ClientOrganizationStatusFields
      }
    }
  }
  ${CLIENT_ORGANIZATION_STATUS_FIELDS_FRAGMENT}
`;

export const CREATE_CLIENT_ORGANIZATION_TYPE = gql`
  mutation CreateClientOrganizationStatus($clientOrganizationId: Int!, $companyType: CompanyType!) {
    createClientOrganizationType(
      input: { clientOrganizationType: { clientOrganizationId: $clientOrganizationId, companyType: $companyType } }
    ) {
      clientOrganizationType {
        ...ClientOrganizationTypeFields
      }
    }
  }
  ${CLIENT_ORGANIZATION_TYPE_FIELDS_FRAGMENT}
`;

export const DELETE_CLIENT_ORGANIZATION_TYPE = gql`
  mutation DeleteClientOrganizationStatus($id: Int!) {
    deleteClientOrganizationType(input: { id: $id }) {
      clientOrganizationType {
        ...ClientOrganizationTypeFields
      }
    }
  }
  ${CLIENT_ORGANIZATION_TYPE_FIELDS_FRAGMENT}
`;
