import _ from 'lodash';
import React, { useState } from 'react';
import { Button, DatePicker, Modal, Table, Typography, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import getLoadingState from '../utils/get-loading-state';
import { usePaginatedQuery } from '../components/paginated-query';
import SectionHeader from '../components/section-header';
import ClientContractForm from '../components/forms/contract-form';
import { renderDatetimeAsDate, renderDate } from '../utils/datetime';
import { renderMoney } from '../utils/money';
import SelectFilter, { isFiltered } from '../components/tables/select-filter';
import PageError from '../components/page-error';
import { setGraphqlOrderFromAntSorter, getAntSortOrderFromGraphqlVariables } from '../utils/graphql';
import { GET_CLIENT_CONTRACTS } from '../queries/client/client-contract-queries';
import HeaderFilters from '../components/tables/header-filters';

export default function ContractsListPage(props) {
  const { history } = props;
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [variables, setVariables] = useState({});

  const res = usePaginatedQuery(GET_CLIENT_CONTRACTS, {
    variables,
    notifyOnNetworkStatusChange: true
  });

  function handleTableChange(pagination, filters, sorter) {
    setGraphqlOrderFromAntSorter({
      setVariables,
      sorter
    });
    return true;
  }

  const { data, loading, fetchMore, error } = res;
  const loadingState = getLoadingState(res);
  const dataSource = data?.contracts.edges.map(({ node }) => ({ ...node, key: node.id }));

  return (
    <>
      <SectionHeader
        left={<Typography.Title style={{ marginBottom: 0 }}>Contracts</Typography.Title>}
        right={
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Create Contract
          </Button>
        }
      />
      {error && <PageError error={error} />}
      {!error && <HeaderFilters variables={variables} setVariables={setVariables} searchClient="id" />}
      {!error && loadingState === 'FIRST_LOADING' && <Skeleton />}
      {!error && loadingState !== 'FIRST_LOADING' && (
        <>
          <Table
            dataSource={dataSource}
            pagination={false}
            size="middle"
            bordered
            loading={loading}
            onChange={handleTableChange}
            onRow={record => {
              return {
                style: { cursor: 'pointer' },
                onClick: e => {
                  props.history.push(`/contracts/${record.id}`);
                }
              };
            }}
          >
            <Table.Column defaultSortOrder="descend" sorter title="ID" dataIndex="id" key="id" />

            <Table.Column
              title="Client"
              dataIndex="client"
              key="client"
              render={client => <strong>{client.name}</strong>}
            />

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'createdAt' })}
              title="Created"
              dataIndex="createdAt"
              key="createdAt"
              render={renderDatetimeAsDate}
              filtered={isFiltered({ variables, dateRange: true, fieldName: 'createdAt' })}
              filterDropdown={
                <SelectFilter
                  dateRange
                  fieldName="createdAt"
                  Selector={DatePicker.RangePicker}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
            />

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'startDate' })}
              title="Start"
              dataIndex="startDate"
              key="startDate"
              filtered={isFiltered({ dateRange: true, fieldName: 'startDate', variables })}
              render={renderDate}
              filterDropdown={
                <SelectFilter
                  dateRange
                  fieldName="startDate"
                  Selector={DatePicker.RangePicker}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
            />

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'endDate' })}
              title="End"
              dataIndex="endDate"
              key="endDate"
              filtered={isFiltered({ dateRange: true, fieldName: 'endDate', variables })}
              render={renderDate}
              filterDropdown={
                <SelectFilter
                  dateRange
                  fieldName="endDate"
                  Selector={DatePicker.RangePicker}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
            />

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'amount' })}
              title="ACV"
              dataIndex="amount"
              render={renderMoney}
              key="amount"
            />
          </Table>
          <Button
            block
            loading={loading}
            size="large"
            disabled={!_.get(data, 'contracts.pageInfo.hasNextPage')}
            onClick={() => fetchMore()}
          >
            Load More
          </Button>
        </>
      )}

      <Modal
        destroyOnClose
        footer={null}
        centered
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
      >
        <ClientContractForm
          onSuccess={node => {
            history.push(`/contracts/${node.id}`);
          }}
        />
      </Modal>
    </>
  );
}
