import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { notification, Avatar, Card, Tag, Modal, Popconfirm, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';

import ClientContactForm from '../forms/client-contact-form';
import { DELETE_CLIENT_CONTACT, GET_CLIENT_CONTACTS } from '../../queries/client/client-contact-queries';
import RequirePermission from '../require-permission';
import { GET_CLIENT } from '../../queries/client/client-queries';

function ClientContactCard(props) {
  const {
    clientId,
    clientContact,
    clientContact: { id, firstName, lastName, title, email, phone, isPrimary, isBilling }
  } = props;
  const [deleteClientContact] = useMutation(DELETE_CLIENT_CONTACT);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const handleEditClick = () => {
    setIsEditModalVisible(!isEditModalVisible);
  };

  const handleDeleteClick = () => {
    deleteClientContact({
      variables: { id },
      refetchQueries: [
        {
          query: GET_CLIENT_CONTACTS,
          variables: { clientId, first: 9 }
        },
        {
          query: GET_CLIENT,
          variables: { id: clientId }
        }
      ]
    })
      .then(() => {
        notification.success({
          message: 'Client contact deleted successfully.'
        });
      })
      .catch(error => {
        notification.error({
          message: `Client contact deletion failed with error: ${error.message}`
        });
      });
  };

  const handleEditSuccess = () => {
    setIsEditModalVisible(false);
  };

  return (
    <Fragment>
      <Card
        actions={[
          <span onClick={handleEditClick}>
            <EditOutlined /> Edit
          </span>,
          <RequirePermission permission="MANAGE_ACCOUNTS">
            <Popconfirm
              title={`Are you sure you want to delete ${firstName} ${lastName}?`}
              onConfirm={handleDeleteClick}
            >
              <DeleteOutlined /> Delete
            </Popconfirm>
          </RequirePermission>
        ]}
      >
        <Card.Meta
          avatar={<Avatar />}
          title={
            <span>
              {`${firstName} ${lastName}`}
              &nbsp;
              {isPrimary && <Tag color="geekblue">Primary</Tag>}
              {isBilling && <Tag color="green">Billing</Tag>}
            </span>
          }
          description={
            <div>
              <em>{title}</em>
              <br />
              {email && (
                <a href={`mailto:${email}`}>
                  <MailOutlined /> {email}
                </a>
              )}
              <br />
              {phone && (
                <a href={`tel:${phone}`}>
                  <PhoneOutlined /> {phone}
                </a>
              )}
            </div>
          }
        />
        {!email && !phone && <Typography.Text type="warning">No contact details provided</Typography.Text>}
      </Card>
      <Modal footer={null} centered visible={isEditModalVisible} onCancel={() => setIsEditModalVisible(false)}>
        <ClientContactForm clientId={clientId} clientContact={clientContact} onSuccess={handleEditSuccess} />
      </Modal>
    </Fragment>
  );
}

ClientContactCard.propTypes = {
  clientId: PropTypes.number.isRequired
};

export default ClientContactCard;
