/**
 * heavily inspired by
 * https://ant.design/components/table/#components-table-demo-custom-filter-panel
 */

import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';
import produce from 'immer';
import { SearchOutlined } from '@ant-design/icons';

export function useSearchGraphQlState({ variables, setVariables, fieldName }) {
  const [searchText, setSearchText] = useState(variables?.filter?.[fieldName]?.includesInsensitive);

  function updateStateWithSearch(state, searchValue) {
    if (searchValue) {
      state.filter = {
        ...state.filter,
        [fieldName]: { includesInsensitive: searchValue }
      };
    } else if (state.filter?.[fieldName]) {
      delete state.filter[fieldName];
      if (_.isEmpty(state.filter)) {
        delete state.filter;
      }
    }
  }

  function updateVariables(searchValue) {
    setVariables(produce(state => updateStateWithSearch(state, searchValue)));
  }

  return [searchText, setSearchText, updateVariables];
}

export default function SearchFilter(props) {
  const { variables, setVariables, fieldName } = props;
  const [searchText, setSearchText, updateVariables] = useSearchGraphQlState({ variables, setVariables, fieldName });

  function handleInputChange(e) {
    setSearchText(e.target.value);
  }

  function handleReset() {
    updateVariables(null);
  }

  function handleSubmit() {
    updateVariables(searchText);
  }

  return (
    <div style={{ padding: 8 }}>
      <Input
        autoFocus
        value={searchText}
        onChange={handleInputChange}
        onPressEnter={handleSubmit}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={handleSubmit}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={handleReset} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  );
}

SearchFilter.propTypes = {
  variables: PropTypes.any,
  setVariables: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired
};
