import _ from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import getLoadingState from '../utils/get-loading-state';

import { Button, Modal, Table, Typography, Skeleton } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';

import PaginatedQuery from '../components/paginated-query';
import SectionHeader from '../components/section-header';
import HardwareForm from '../components/forms/hardware-stock-form';
import PageError from '../components/page-error';
import { GET_HARDWARES } from '../queries/hardware/hardware-stock-queries';
import { HardwareTypeSelector } from '../components/searchable-selectors';
import SelectFilter, { isFiltered } from '../components/tables/select-filter';
import { renderMoney } from '../utils/money';
import { getAntSortOrderFromGraphqlVariables, setGraphqlOrderFromAntSorter } from '../utils/graphql';
import SearchFilter from '../components/tables/search-filter';

function HardwarePage(props) {
  const [modalState, setModalState] = useState({ visible: false, id: null });
  const [variables, setVariables] = useState({});

  function handleTableChange(pagination, filters, sorter) {
    setGraphqlOrderFromAntSorter({
      setVariables,
      sorter
    });
  }
  return (
    <PaginatedQuery query={GET_HARDWARES} variables={variables} notifyOnNetworkStatusChange={true}>
      {getHardwaresResponse => {
        let content;

        const { data, loading, fetchMore, error } = getHardwaresResponse;
        const loadingState = getLoadingState(getHardwaresResponse);

        if (error) {
          return <PageError error={error} />;
        }
        if (loadingState === 'FIRST_LOADING') {
          content = <Skeleton />;
        } else {
          const hardwares = _.map(data?.hardwares.edges, ({ node }) => ({ ...node, key: node.id }));

          content = (
            <div>
              <Table
                dataSource={hardwares}
                pagination={false}
                size="middle"
                loading={loading}
                bordered
                onChange={handleTableChange}
                onRow={record => {
                  return {
                    style: { cursor: 'pointer' },
                    onClick: () => setModalState({ visible: true, id: record.id })
                  };
                }}
              >
                <Table.Column sorter defaultSortOrder="descend" title="ID" dataIndex="id" key="id" />
                <Table.Column
                  sorter={{ multiple: 1 }}
                  sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'hardwareTypeId' })}
                  title="Type"
                  dataIndex="hardwareType"
                  key="hardwareTypeId"
                  render={node => node.typeName}
                  filtered={isFiltered({ fieldName: 'hardwareTypeId', variables, setVariables })}
                  filterDropdown={
                    <SelectFilter
                      fieldName="hardwareTypeId"
                      Selector={HardwareTypeSelector}
                      variables={variables}
                      setVariables={setVariables}
                    />
                  }
                />
                <Table.Column
                  sorter={{ multiple: 1 }}
                  sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'physicalId' })}
                  title="Physical ID"
                  dataIndex="physicalId"
                  key="physicalId"
                  filterDropdown={
                    <SearchFilter fieldName="physicalId" variables={variables} setVariables={setVariables} />
                  }
                  filterIcon={<SearchOutlined />}
                />
                <Table.Column
                  sorter={{ multiple: 1 }}
                  sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'cost' })}
                  title="Cost"
                  dataIndex="cost"
                  key="cost"
                  render={renderMoney}
                />
              </Table>
              <Button
                block
                loading={loading}
                size="large"
                disabled={!_.get(data, 'hardwares.pageInfo.hasNextPage')}
                onClick={() => fetchMore()}
              >
                Load More
              </Button>
              <Typography.Paragraph>
                You can see total stock at <Link to="/hardware-types">Hardware Types Page</Link>.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Only hardware that has not been sold or leased is shown. If leased, you can return hardware to stock by
                going to <Link to="/hardware-pending-return">Pending Returns</Link>.
              </Typography.Paragraph>
            </div>
          );
        }

        return (
          <div>
            <SectionHeader
              left={<Typography.Title style={{ marginBottom: 0 }}>Hardware Stock</Typography.Title>}
              right={
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setModalState({ visible: true, id: null })}
                >
                  Create Hardware Stock
                </Button>
              }
            />
            {content}
            <Modal
              destroyOnClose
              footer={null}
              centered
              visible={modalState.visible}
              onCancel={() => setModalState(state => ({ ...state, visible: false }))}
            >
              <HardwareForm
                hardware={data?.hardwares?.edges.map(({ node }) => node).find(node => node.id === modalState.id)}
                onSuccess={() => setModalState(state => ({ ...state, visible: false }))}
              />
            </Modal>
          </div>
        );
      }}
    </PaginatedQuery>
  );
}

export default HardwarePage;
