import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password, useCookies: true }) {
      message
    }
  }
`;

export const INVITE_USER = gql`
  mutation InviteUser($name: String!, $email: String!, $permissions: [Permission!]) {
    inviteNewAccount(input: { name: $name, email: $email, permissions: $permissions }) {
      message
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation SetPassword($password: String!, $token: String!) {
    setPassword(input: { password: $password, token: $token, useCookies: true }) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(input: { email: $email }) {
      message
    }
  }
`;
