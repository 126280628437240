import '../styles/pages/login.css';

import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { withRouter } from 'react-router';
import cookie from 'cookie';
import * as yup from 'yup';

import { Formik, Form } from 'formik';
import { Alert, Button } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { CurrentUserContext } from '../global-contexts';
import FormikInputGroup from '../components/forms/formik-input-group';
import { Input } from '../components/forms/formik-ant';
import { setAnyFormErrorsFromGraphQlError } from '../utils/graphql';

import { LOGIN } from '../queries/user';

const LoginSchema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  password: yup.string().required()
});

function LoginPage({ history }) {
  const [message, setMessage] = useState('');
  const [currentUser] = useContext(CurrentUserContext);
  const [login, loginState] = useMutation(LOGIN);
  const { loading } = loginState;

  useEffect(() => {
    const cookies = cookie.parse(document.cookie);
    if (cookies['meadow-internal-client-dir-logged-in']) {
      history.push('/');
    }
  }, [currentUser, history]);

  async function handleFormSubmit(values, formikProps) {
    formikProps.setSubmitting(true);

    try {
      const loginResult = await login({
        variables: { ...values }
      });
      const {
        data: {
          login: { message }
        }
      } = loginResult;

      if (message === 'Success') {
        history.push('/');
      } else {
        setMessage(message);
      }
    } catch (loginError) {
      setMessage(loginError.message.replace('GraphQL error: ', ''));
      setAnyFormErrorsFromGraphQlError(loginError, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  return (
    <div className="login">
      <Formik
        initialValues={{}}
        validationSchema={LoginSchema}
        onSubmit={handleFormSubmit}
        render={formikProps => {
          return (
            <Form className="login__form">
              {message && <Alert className="login__form__error" type="error" message={message} />}
              <FormikInputGroup
                name="email"
                label="Email Address"
                component={Input}
                prefix={<MailOutlined />}
                formikProps={formikProps}
              />

              <FormikInputGroup
                name="password"
                label="Password"
                component={Input}
                prefix={<LockOutlined />}
                type="password"
                formikProps={formikProps}
              />

              <div className="form__actions">
                <span className="form__action">
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Login
                  </Button>
                </span>
                <span className="form__action">
                  <Link to="/reset-password">Forgot your password?</Link>
                </span>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
}

export default withRouter(LoginPage);
