import _ from 'lodash';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Button, DatePicker, Modal, Table, Typography, Skeleton } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';

import getLoadingState from '../utils/get-loading-state';
import { usePaginatedQuery } from '../components/paginated-query';
import SectionHeader from '../components/section-header';
import ClientOrganizationForm from '../components/forms/client-organization-form';
import { renderDatetimeAsDate } from '../utils/datetime';
import SelectFilter, { isFiltered } from '../components/tables/select-filter';
import PageError from '../components/page-error';
import ClientStatusTag from '../components/client/client-status-tag';
import ClientStatusSelector from '../components/forms/client-status-selector';
import {
  getAntSortOrderFromGraphqlVariables,
  setGraphqlEqualToFilterVariableFromAntFilterValue,
  setGraphqlOrderFromAntSorter
} from '../utils/graphql';
import { GET_CLIENT_ORGANIZATIONS } from '../queries/client/client-organization-queries';
import { CompanyTypeTags, CompanyTypeSelector } from '../components/company-type';
import SearchFilter from '../components/tables/search-filter';
import HeaderFilters from '../components/tables/header-filters';

function OrganizationsListPage(props) {
  const { history } = props;
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [variables, setVariables] = useState({});

  const res = usePaginatedQuery(GET_CLIENT_ORGANIZATIONS, {
    variables,
    notifyOnNetworkStatusChange: true
  });

  function handleTableChange(pagination, filters, sorter) {
    setGraphqlEqualToFilterVariableFromAntFilterValue({
      fieldName: 'isPrimary',
      setVariables,
      filters
    });
    setGraphqlOrderFromAntSorter({
      setVariables,
      sorter
    });
  }

  const { data, loading, fetchMore, error } = res;
  const loadingState = getLoadingState(res);
  const organizations = data?.clientOrganizations.edges.map(({ node }) => ({ ...node, key: node.id }));

  return (
    <div>
      <SectionHeader
        left={<Typography.Title style={{ marginBottom: 0 }}>Organizations</Typography.Title>}
        right={
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Create Organization
          </Button>
        }
      />
      {error && <PageError error={error} />}
      {!error && (
        <HeaderFilters
          variables={variables}
          setVariables={setVariables}
          searchClient="id"
          searchOrganization="direct"
        />
      )}
      {!error && loadingState === 'FIRST_LOADING' && <Skeleton />}
      {!error && loadingState !== 'FIRST_LOADING' && (
        <>
          <Table
            dataSource={organizations}
            pagination={false}
            size="middle"
            bordered
            loading={loading}
            onChange={handleTableChange}
            onRow={record => {
              return {
                style: { cursor: 'pointer' },
                onClick: e => {
                  props.history.push(`/organizations/${record.id}`);
                }
              };
            }}
          >
            <Table.Column
              title="Description"
              dataIndex="description"
              key="description"
              filterDropdown={
                <SearchFilter fieldName="description" variables={variables} setVariables={setVariables} />
              }
              filterIcon={<SearchOutlined />}
            />
            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'organizationId' })}
              title="Org ID"
              dataIndex="organizationId"
              key="organizationId"
            />

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'createdAt' })}
              title="Created"
              dataIndex="createdAt"
              key="createdAt"
              render={renderDatetimeAsDate}
              filtered={isFiltered({ variables, dateRange: true, fieldName: 'createdAt' })}
              filterDropdown={
                <SelectFilter
                  dateRange
                  fieldName="createdAt"
                  Selector={DatePicker.RangePicker}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
            />

            <Table.Column
              title="Client"
              dataIndex="client"
              key="client"
              render={client => <strong>{client.name}</strong>}
            />

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'companyTypes' })}
              title="Type"
              dataIndex="companyTypes"
              key="companyTypes"
              filtered={isFiltered({ array: true, variables, fieldName: 'companyType' })}
              filterDropdown={
                <SelectFilter
                  array
                  fieldName="companyTypes"
                  Selector={CompanyTypeSelector}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
              render={types => <CompanyTypeTags types={types} />}
            />

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'status' })}
              title="Status"
              dataIndex="status"
              key="status"
              filtered={isFiltered({ variables, fieldName: 'status' })}
              filterDropdown={
                <SelectFilter
                  fieldName="status"
                  Selector={ClientStatusSelector}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
              render={v => <ClientStatusTag status={v} />}
            />
            <Table.Column
              title="Primary"
              dataIndex="isPrimary"
              key="isPrimary"
              render={v => (v ? 'Yes' : 'No')}
              filters={[
                { text: 'Yes', value: true },
                { text: 'No', value: false }
              ]}
            />
          </Table>
          <Button
            block
            loading={loading}
            size="large"
            disabled={!_.get(data, 'clientOrganizations.pageInfo.hasNextPage')}
            onClick={() => fetchMore()}
          >
            Load More
          </Button>
        </>
      )}

      <Modal
        destroyOnClose
        footer={null}
        centered
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
      >
        <ClientOrganizationForm
          onSuccess={node => {
            history.push(`/organizations/${node.id}`);
          }}
        />
      </Modal>
    </div>
  );
}

export default withRouter(OrganizationsListPage);
