import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import { Button, Modal, Typography, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import PaginatedQuery from '../paginated-query';
import SectionHeader from '../section-header';
import ClientContractCard from './client-contract-card';
import ClientContractForm from '../forms/contract-form';
import getLoadingState from '../../utils/get-loading-state';

import { GET_CLIENT_CONTRACTS_BY_CLIENT_ID } from '../../queries/client/client-contract-queries';

function ClientContracts(props) {
  const { id } = props;
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const handleClientContractCreateSuccess = node => {
    props.history.push(`/contracts/${node.id}`);
  };

  return (
    <div className="client-contacts">
      <SectionHeader
        left={
          <Typography.Title level={2} style={{ marginBottom: 0 }}>
            Contracts
          </Typography.Title>
        }
        right={
          <Button type="secondary" size="small" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Add Contract
          </Button>
        }
      />
      <PaginatedQuery
        query={GET_CLIENT_CONTRACTS_BY_CLIENT_ID}
        variables={{ clientId: id }}
        notifyOnNetworkStatusChange={true}
      >
        {response => {
          const loadingState = getLoadingState(response);
          const { loading, data, fetchMore } = response;
          return (
            <div>
              {loadingState === 'FIRST_LOADING' && <Skeleton />}
              {loadingState === 'EMPTY' && <Typography.Text key="none">No contracts found</Typography.Text>}
              {data?.contracts && (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '15px' }}>
                  {data?.contracts.edges.map(({ node }) => (
                    <ClientContractCard key={node.id} contract={node} clientId={id} />
                  ))}
                </div>
              )}
              {data?.contracts?.pageInfo.hasNextPage && (
                <Button loading={loading} onClick={() => fetchMore()}>
                  Load More
                </Button>
              )}
            </div>
          );
        }}
      </PaginatedQuery>
      <Modal
        footer={null}
        centered
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
        destroyOnClose={true}
      >
        <ClientContractForm clientId={id} onSuccess={handleClientContractCreateSuccess} />
      </Modal>
    </div>
  );
}

export default withRouter(ClientContracts);
