import React from 'react';

import { Typography } from 'antd';

import SetPasswordForm from '../components/forms/set-password-form';

function WelcomePage(props) {
  const { history } = props;
  const params = new URLSearchParams(props.location.search);
  const token = params.get('token');

  return (
    <div>
      <Typography.Title level={2}>Welcome to Meadow Internal Tools!</Typography.Title>
      <p>
        Please set your password below. Remember to use{' '}
        <a href="https://1password.com/" target="_blank" rel="noopener noreferrer">
          1Password
        </a>{' '}
        to generate a secure password.
      </p>
      <SetPasswordForm
        token={token}
        onSuccess={() => {
          history.push('/');
        }}
      />
    </div>
  );
}

export default WelcomePage;
