import { gql } from '@apollo/client';

export const BILLING_MONTHLY_RATE_FIELDS_FRAGMENT = gql`
  fragment BillingMonthlyRateFields on BillingMonthlyRate {
    id
    updatedAt
    createdAt
    deletedAt
    type
    subType
    amount
    startDate
    maxLocations
    freeLocations
    legacy2016
    client {
      id
      name
    }
    clientOrganization {
      id
      organizationId
    }
    smsCreditAmount
  }
`;

export const BILLING_MONTHLY_LINE_ITEM_FIELDS_FRAGMENT = gql`
  fragment BillingMonthlyLineItemFields on BillingMonthlyLineItem {
    id
    updatedAt
    createdAt
    deletedAt
    name
    amount
    client {
      id
      name
    }
  }
`;

export const CREATE_BILLING_MONTHLY_RATE = gql`
  mutation CreateBillingMonthlyRate(
    $clientId: Int!
    $clientOrganizationId: Int
    $type: BillingMonthlyType!
    $subType: BillingMonthlySubType
    $startDate: Date!
    $amount: BigFloat!
    $maxLocations: Int
    $freeLocations: Int
    $legacy2016: Boolean
    $smsCreditAmount: Int
  ) {
    createBillingMonthlyRate(
      input: {
        billingMonthlyRate: {
          clientId: $clientId
          clientOrganizationId: $clientOrganizationId
          type: $type
          subType: $subType
          startDate: $startDate
          amount: $amount
          maxLocations: $maxLocations
          freeLocations: $freeLocations
          legacy2016: $legacy2016
          smsCreditAmount: $smsCreditAmount
        }
      }
    ) {
      billingMonthlyRate {
        ...BillingMonthlyRateFields
      }
    }
  }

  ${BILLING_MONTHLY_RATE_FIELDS_FRAGMENT}
`;

export const GET_BILLING_MONTHLY_RATE = gql`
  query GetBillingMonthlyRate($id: Int!) {
    billingMonthlyRate(id: $id) {
      ...BillingMonthlyRateFields
    }
  }

  ${BILLING_MONTHLY_RATE_FIELDS_FRAGMENT}
`;

export const DELETE_BILLING_MONTHLY_RATE = gql`
  mutation DeleteBillingMonthlyRate($id: Int!) {
    deleteBillingMonthlyRate(input: { id: $id }) {
      billingMonthlyRate {
        id
        deletedAt
      }
    }
  }
`;

export const GET_BILLING_MONTHLY_RATES = gql`
  query GetBillingMonthlyRates(
    $first: Int = 20
    $after: Cursor
    $filter: BillingMonthlyRateFilter
    $orderBy: [BillingMonthlyRatesOrderBy!] = [CLIENT_ID_DESC, TYPE_ASC, START_DATE_ASC]
    $includeArchived: IncludeArchivedOption
  ) {
    billingMonthlyRates(
      first: $first
      after: $after
      filter: $filter
      orderBy: $orderBy
      includeArchived: $includeArchived
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...BillingMonthlyRateFields
        }
      }
    }
  }
  ${BILLING_MONTHLY_RATE_FIELDS_FRAGMENT}
`;

export const CREATE_BILLING_MONTHLY_LINE_ITEM = gql`
  mutation createBillingMonthlyLineItem($clientId: Int!, $name: String!, $amount: BigFloat!) {
    createBillingMonthlyLineItem(
      input: { billingMonthlyLineItem: { clientId: $clientId, name: $name, amount: $amount } }
    ) {
      billingMonthlyLineItem {
        ...BillingMonthlyLineItemFields
      }
    }
  }

  ${BILLING_MONTHLY_LINE_ITEM_FIELDS_FRAGMENT}
`;

export const GET_BILLING_MONTHLY_LINE_ITEM = gql`
  query GetBillingMonthlyLineItem($id: Int!) {
    billingMonthlyLineItem(id: $id) {
      ...BillingMonthlyLineItemFields
    }
  }

  ${BILLING_MONTHLY_LINE_ITEM_FIELDS_FRAGMENT}
`;

export const DELETE_BILLING_MONTHLY_LINE_ITEM = gql`
  mutation DeleteBillingMonthlyLineItem($id: Int!) {
    deleteBillingMonthlyLineItem(input: { id: $id }) {
      billingMonthlyLineItem {
        id
        deletedAt
      }
    }
  }
`;

export const GET_BILLING_MONTHLY_LINE_ITEMS = gql`
  query GetBillingMonthlyLineItems(
    $first: Int = 20
    $after: Cursor
    $filter: BillingMonthlyLineItemFilter
    $orderBy: [BillingMonthlyLineItemsOrderBy!] = [CLIENT_ID_DESC, NAME_ASC]
    $includeArchived: IncludeArchivedOption
  ) {
    billingMonthlyLineItems(
      first: $first
      after: $after
      filter: $filter
      orderBy: $orderBy
      includeArchived: $includeArchived
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...BillingMonthlyLineItemFields
        }
      }
    }
  }
  ${BILLING_MONTHLY_LINE_ITEM_FIELDS_FRAGMENT}
`;

export const GET_BILLING_MONTHLY_LINE_ITEM_NAMES = gql`
  query GetBillingMonthlyLineItemNames {
    billingMonthlyLineItemNames {
      nodes {
        name
      }
    }
  }
`;

export const GET_BILLING_MONTHLY_CURRENT_RATES = gql`
  query GetBillingMonthlyCurrentRates($clientId: Int!) {
    billingMonthlyCurrentRates(condition: { clientId: $clientId }) {
      nodes {
        id
        clientId
        name
        type
        subType
        amount
        startDate
        clientOrganizationId
        clientOrganization {
          organizationId
        }
        smsCreditAmount
        maxLocations
        freeLocations
      }
    }
  }
`;
