import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { notification, Button, Typography, Upload, Skeleton } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import PaginatedQuery from './paginated-query';
import SectionHeader from './section-header';
import FileCard from './file-card';
import getLoadingState from '../utils/get-loading-state';

function FileList(props) {
  const { createMutation, getQuery, variables, titleProps, createButtonProps } = props;
  const [createClientFile, createClientFileResponse] = useMutation(createMutation);

  async function handleUpload(info) {
    const { file } = info;

    try {
      await createClientFile({
        variables: { ...variables, file },
        refetchQueries: [{ query: getQuery, variables }]
      });

      notification.success({
        message: 'File uploaded successfully'
      });
    } catch (err) {
      notification.error({
        message: `Client file upload failed with error: ${err.message}`
      });
    }
  }

  return (
    <>
      <SectionHeader
        left={
          <Typography.Title level={2} style={{ marginBottom: 0 }} {...titleProps}>
            Files
          </Typography.Title>
        }
        right={
          <Upload showUploadList={false} customRequest={handleUpload} disabled={createClientFileResponse.loading}>
            <Button type="secondary" size="small" loading={createClientFileResponse.loading} {...createButtonProps}>
              <UploadOutlined /> Upload File
            </Button>
          </Upload>
        }
      />
      <PaginatedQuery query={getQuery} variables={variables} notifyOnNetworkStatusChange={true}>
        {getClientFilesResponse => {
          const { data, error } = getClientFilesResponse;
          const resultKey = data && Object.keys(data || {})[0];
          const loadingState = getLoadingState(getClientFilesResponse);
          if (loadingState === 'ERROR') {
            return <p>Error loading files: {error.message}</p>;
          } else if (loadingState === 'FIRST_LOADING') {
            return <Skeleton />;
          } else if (loadingState === 'EMPTY') {
            return <p>No client files found</p>;
          } else {
            const fileContainers = _.get(data, `${resultKey}.edges`, []).map(({ node }) => node);

            return (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '15px' }}>
                {' '}
                {fileContainers.map(fileContainer => (
                  <FileCard
                    refetchQueries={[{ query: getQuery, variables }]}
                    node={fileContainer.file}
                    key={fileContainer.id}
                  />
                ))}
              </div>
            );
          }
        }}
      </PaginatedQuery>
    </>
  );
}

FileList.propTypes = {
  createMutation: PropTypes.any.isRequired,
  getQuery: PropTypes.any.isRequired,
  titleProps: PropTypes.any,
  createButtonProps: PropTypes.any,
  variables: PropTypes.any
};

export default FileList;
