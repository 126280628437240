import React from 'react';
import { Select } from 'antd';
import { COMPANY_SIZES } from '../constants';

export default function ClientStatusSelector(props) {
  return (
    <Select {...props}>
      {COMPANY_SIZES.map(state => (
        <Select.Option key={state.value} value={state.value}>
          {state.label}
        </Select.Option>
      ))}
    </Select>
  );
}
