import _ from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import { notification } from 'antd';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Button, Typography } from 'antd';

import FormikInputGroup from './formik-input-group';
import { Input, InputNumber, Checkbox } from './formik-ant';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import {
  GET_ORGANIZATION,
  CREATE_CLIENT_ORGANIZATION,
  UPDATE_CLIENT_ORGANIZATION,
  GET_CLIENT_ORGANIZATIONS
} from '../../queries/client/client-organization-queries';
import { FormikClientSelector } from '../searchable-selectors';
import Spinner from '../spinner';

const ClientSchema = yup.object({
  organizationId: yup
    .number()
    .min(1)
    .integer()
    .required(),
  description: yup.string().required()
});

function OrganizationName(props) {
  const { id, formikProps } = props;
  const [getOrg, orgRes] = useLazyQuery(GET_ORGANIZATION);

  // get organization name on input change
  useEffect(() => {
    getOrg({
      variables: { id }
    });
  }, [getOrg, id]);

  // autofill organization name on getting name
  useEffect(() => {
    if (orgRes.data?.organization?.name) {
      formikProps.setFieldValue('description', orgRes.data.organization.name);
    }
  }, [orgRes.data?.organization?.name]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!id) {
    return <Typography.Text>Provide an Org ID</Typography.Text>;
  }
  if (orgRes.loading) {
    return <Spinner />;
  }
  if (orgRes.error) {
    return (
      <Typography.Text type="danger">{orgRes.error.message ?? 'There was an error getting the data.'}</Typography.Text>
    );
  }
  if (!orgRes.data?.organization) {
    return <Typography.Text type="warning">Could not find an organization with that ID</Typography.Text>;
  }
  return <Typography.Text>{orgRes.data.organization.name}</Typography.Text>;
}

function ClientOrganizationForm(props) {
  const { clientOrganization, onSuccess } = props;
  const [createClientOrganization] = useMutation(CREATE_CLIENT_ORGANIZATION);
  const [updateClientOrganization] = useMutation(UPDATE_CLIENT_ORGANIZATION);
  const isEditing = !_.isEmpty(clientOrganization);

  async function handleFormSubmit(values, formikProps) {
    // default set through initial values on form
    const { clientId } = values;

    const { setSubmitting } = formikProps;
    const verb = isEditing ? 'update' : 'create';
    const mutation = isEditing ? updateClientOrganization : createClientOrganization;
    const id = _.get(clientOrganization, 'id');

    setSubmitting(true);

    try {
      const mutationResponse = await mutation({
        variables: { id, clientId, ...values },
        refetchQueries: [
          { query: GET_CLIENT_ORGANIZATIONS, variables: { filter: { clientId: { equalTo: clientId } }, first: 9 } }
        ]
      });

      notification.success({ message: `Client organization ${verb}d successfully` });
      if (onSuccess) {
        const clientData = _.get(mutationResponse, `data.${verb}ClientOrganization.clientOrganization`);
        onSuccess(clientData);
      }
    } catch (mutationError) {
      notification.error({ message: `Client organization ${verb} failed with error: ${mutationError.message}` });
      setAnyFormErrorsFromGraphQlError(mutationError, formikProps);
    } finally {
      setSubmitting(false);
    }
  }

  const { clientId } = props;

  return (
    <Formik
      initialValues={{ ...clientOrganization, clientId }}
      validationSchema={ClientSchema}
      onSubmit={handleFormSubmit}
    >
      {formikProps => {
        return (
          <Form>
            <Typography.Title level={2}>
              {isEditing ? `Edit ${clientOrganization.description}` : 'Create Client Organization'}
            </Typography.Title>

            {!clientId && !isEditing && (
              <FormikInputGroup
                name="clientId"
                label="Client"
                component={FormikClientSelector}
                formikProps={formikProps}
              />
            )}

            <FormikInputGroup
              name="organizationId"
              label="Organization ID"
              component={InputNumber}
              formikProps={formikProps}
            />

            <div className="formik-input-group">
              <label className="formik-input-group__label">Org Name</label>
              <OrganizationName id={formikProps.values.organizationId} formikProps={formikProps} />
            </div>

            <FormikInputGroup
              name="description"
              label="Description"
              component={Input}
              placeholder="ex: Green Day"
              formikProps={formikProps}
            />

            <FormikInputGroup name="isPrimary" label="Primary" component={Checkbox} formikProps={formikProps} />

            <div className="form__actions">
              <Button type="primary" htmlType="submit" loading={formikProps.isSubmitting}>
                {isEditing ? 'Edit Client Organization' : 'Create Client Organization'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

ClientOrganizationForm.propTypes = {
  clientId: PropTypes.number,
  clientOrganization: PropTypes.shape({
    id: PropTypes.number
  }),
  onSuccess: PropTypes.func
};

export default ClientOrganizationForm;
