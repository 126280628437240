import '../../styles/components/layouts/auth-flow-layout.css';

import React from 'react';
import { withRouter } from 'react-router';

import { Card } from 'antd';

import Logo from '../logo';
import Centered from '../centered';

function AuthFlowLayout(props) {
  const { children } = props;

  return (
    <Centered className="auth-flow-layout">
      <Card className="auth-flow-layout__content" title={<Logo className="auth-flow-layout__logo" />}>
        {children}
      </Card>
    </Centered>
  );
}

export default withRouter(AuthFlowLayout);
