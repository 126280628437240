import React, { useState } from 'react';
import { Button, Table, Typography, Skeleton, Modal, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import getLoadingState from '../utils/get-loading-state';
import { usePaginatedQuery } from '../components/paginated-query';
import SectionHeader from '../components/section-header';
import { renderDatetimeAsDate } from '../utils/datetime';
import { renderMoney } from '../utils/money';
import PageError from '../components/page-error';
import { setGraphqlOrderFromAntSorter, getAntSortOrderFromGraphqlVariables } from '../utils/graphql';
import { BILLING_MONTHLY_RATE_SUB_TYPES, BILLING_MONTHLY_RATE_TYPES } from '../constants';
import { GET_BILLING_MONTHLY_RATES } from '../queries/billing-queries';
import SelectFilter, { isFiltered } from '../components/tables/select-filter';
import BillingMonthlyRateForm from '../components/forms/billing-monthly-rate-form';
import { BillingMonthlyRateTypeSelector } from '../components/forms/billing-monthly-rate-selectors';
import HeaderFilters from '../components/tables/header-filters';

export default function BillingMonthlyRatesPage(props) {
  const { history } = props;
  const [variables, setVariables] = useState({});
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const res = usePaginatedQuery(GET_BILLING_MONTHLY_RATES, {
    variables,
    notifyOnNetworkStatusChange: true
  });

  function handleTableChange(pagination, filters, sorter) {
    setGraphqlOrderFromAntSorter({
      setVariables,
      sorter
    });
    return true;
  }

  const { data, loading, fetchMore, error } = res;
  const loadingState = getLoadingState(res);

  return (
    <>
      <SectionHeader
        left={<Typography.Title style={{ marginBottom: 0 }}>Monthly Billing Rates</Typography.Title>}
        right={
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Create Billing
          </Button>
        }
      />
      {error && <PageError error={error} />}
      {!error && (
        <HeaderFilters
          variables={variables}
          setVariables={setVariables}
          searchClient="id"
          searchOrganization="clientOrganizationId"
        />
      )}
      {!error && loadingState === 'FIRST_LOADING' && <Skeleton />}
      {!error && loadingState !== 'FIRST_LOADING' && (
        <>
          <Table
            rowKey={'id'}
            dataSource={data?.billingMonthlyRates.edges.map(({ node }) => node)}
            loading={loading}
            pagination={false}
            size="middle"
            onChange={handleTableChange}
            bordered
            onRow={record => {
              return {
                style: { cursor: 'pointer' },
                onClick: e => {
                  history.push(`/billing-monthly-rates/${record.id}`);
                }
              };
            }}
          >
            <Table.Column
              title="Client"
              dataIndex="client"
              key="client"
              render={client => <strong>{client.name}</strong>}
            />

            <Table.Column
              title="Type"
              dataIndex="type"
              key="type"
              render={(type, row) => {
                let label = BILLING_MONTHLY_RATE_TYPES.find(o => o.value === type)?.label ?? 'N/A';
                if (row.subType) {
                  const subTypeLabel =
                    BILLING_MONTHLY_RATE_SUB_TYPES.find(o => o.value === row.subType)?.label ?? 'N/A';
                  label += ` - ${subTypeLabel}`;
                }
                if (row.legacy2016) {
                  label += ' - Legacy';
                }
                return label;
              }}
              filtered={isFiltered({ variables, fieldName: 'type' })}
              filterDropdown={
                <SelectFilter
                  fieldName="type"
                  Selector={BillingMonthlyRateTypeSelector}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
            />

            <Table.Column
              title="Org"
              dataIndex="clientOrganization"
              render={clientOrganization => clientOrganization?.organizationId}
              key="clientOrganization.organizationId"
            />

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'startDate' })}
              title="Start Date"
              dataIndex="startDate"
              key="startDate"
              filtered={isFiltered({ dateRange: true, fieldName: 'startDate', variables })}
              render={renderDatetimeAsDate}
              filterDropdown={
                <SelectFilter
                  dateRange
                  fieldName="startDate"
                  Selector={DatePicker.RangePicker}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
            />

            {variables?.includeArchived === 'YES' && (
              <Table.Column
                sorter={{ multiple: 1 }}
                sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'deletedAt' })}
                title="Deleted"
                dataIndex="deletedAt"
                key="deletedAt"
                render={renderDatetimeAsDate}
                filtered={isFiltered({ variables, dateRange: true, fieldName: 'deletedAt' })}
                filterDropdown={
                  <SelectFilter
                    dateRange
                    fieldName="deletedAt"
                    Selector={DatePicker.RangePicker}
                    variables={variables}
                    setVariables={setVariables}
                  />
                }
              />
            )}

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'amount' })}
              title="Rate"
              dataIndex="amount"
              key="amount"
              render={renderMoney}
            />
          </Table>
          <Button
            block
            loading={loading}
            size="large"
            disabled={!data?.billingMonthlyRates.pageInfo.hasNextPage}
            onClick={() => fetchMore()}
          >
            Load More
          </Button>
        </>
      )}
      <Modal footer={null} centered visible={isCreateModalVisible} onCancel={() => setIsCreateModalVisible(false)}>
        <BillingMonthlyRateForm onSuccess={() => setIsCreateModalVisible(false)} />
      </Modal>
    </>
  );
}
