import * as yup from 'yup';
import { PERMISSIONS, COMPANY_TYPES } from './constants';

const name = () => yup.string().min(2);
const id = () =>
  yup
    .number()
    .integer()
    .positive();
const percentage = () =>
  yup
    .number()
    .positive()
    .max(100);
const cents = () => yup.number().positive();

export const createClient = yup.object({
  name: name().required(),
  size: yup
    .mixed()
    .oneOf(['S', 'M', 'L', 's', 'm', 'l'])
    .required()
});

export const updateClient = createClient;

export const createClientContact = yup.object({
  firstName: name().required(),
  lastName: name().required(),
  email: yup
    .string()
    .email()
    .required(),
  phone: yup
    .string()
    .min(7)
    .required()
});

export const resetPassword = yup.object({
  email: yup
    .string()
    .email()
    .required()
});

export const setPassword = yup.object({
  password: yup
    .string()
    .min(8)
    .required()
});

const permissions = yup.array().of(yup.mixed().oneOf(PERMISSIONS.map(p => p.value)));

export const inviteNewAccount = yup.object({
  name: name().required(),
  email: yup
    .string()
    .email()
    .required(),
  permissions
});

export const updateAccount = yup.object({
  permissions
});

export const updateMyAccount = yup.object({
  name: name().required(),
  email: yup
    .string()
    .email()
    .required()
});

export const updateClientSeller = yup.object({
  accountId: id().required(),
  percent: percentage().required()
});

export const createClientSeller = updateClientSeller.shape({
  clientId: id().required()
});

export const createHardware = yup.object({
  hardwareId: yup.string(),
  hardwareTypeId: id().required(),
  cost: cents()
});

export const createHardwareLease = yup.object({
  clientId: id().required(),
  price: yup.number().required()
});

export const createHardwareLeaseLineItem = yup.object({
  hardwareId: id().required('Please choose a hardware')
});

export const createHardwareSale = yup.object({
  clientId: id().required()
});

export const createHardwareSaleLineItem = yup.object({
  price: cents().required('the price is required'),
  hardwareId: id().required('Please choose a hardware')
});

export const createHardwareType = yup.object({
  typeName: yup.string().required(),
  physicalIdType: yup.string()
});

export const createBillingMonthlyLineItemSchema = yup.object({
  name: name().required(),
  amount: yup
    .number()
    .min(0)
    .required()
});

export const createBillingMonthlyRateSchema = yup.lazy(value => {
  const base = yup.object({
    startDate: yup.date().required(),
    clientId: id().required(),
    amount: yup
      .number()
      .min(0)
      .required(),
    type: yup.string().required()
  });
  switch (value.type) {
    case 'dynamic-delivery': {
      const dynamicDeliveryBase = base.concat(
        yup.object({
          clientOrganizationId: id().required(),
          subType: yup
            .string()
            .lowercase()
            .oneOf(['flat-rate'])
            .required()
        })
      );

      switch (value.subType) {
        case 'flat-rate':
          return dynamicDeliveryBase.concat(
            yup.object({
              maxLocations: yup
                .number()
                .integer()
                .min(0)
                .required()
            })
          );
        default:
          return dynamicDeliveryBase;
      }
    }

    case 'analytics':
      return base.concat(
        yup.object({
          clientOrganizationId: id().required()
        })
      );

    case 'sms-marketing-subscription':
      return base.concat(
        yup.object({
          clientOrganizationId: id().required(),
          smsCreditAmount: yup
            .number()
            .integer()
            .positive()
            .required()
        })
      );

    case 'saas':
    default:
      return base;
  }
});

export const createContract = yup.object({
  amount: yup.number().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required()
});

export const updateContract = createContract;

export const clientComment = yup.object({
  parentCommentId: id(),
  body: yup.string().required()
});

export const companyType = yup.object({
  companyType: yup
    .mixed()
    .oneOf(COMPANY_TYPES.map(t => t.value))
    .required()
});
