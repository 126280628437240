import { Select, Typography } from 'antd';
import { useState } from 'react';
import PropTypes from 'prop-types';
import BillingMonthlyLineItemForm from './billing-monthly-line-item-form';
import BillingMonthlyRateForm from './billing-monthly-rate-form';

const BILLING_TYPES = [
  {
    value: 'standard',
    label: 'Standard',
    Component: BillingMonthlyRateForm
  },
  {
    value: 'line-items',
    label: 'Misc',
    Component: BillingMonthlyLineItemForm
  }
];

export default function BillingGeneralForm(props) {
  const { clientId, onSuccess } = props;
  const [type, setType] = useState('standard');
  const Component = BILLING_TYPES.find(x => x.value === type)?.Component;

  function handleChangeType(option) {
    setType(option);
  }
  return (
    <>
      <Typography.Title level={3}>Create Monthly Billing Rate</Typography.Title>
      <div className="formik-input-group">
        <label className="formik-input-group__label">Billing Rate Type</label>
        <Select
          style={{ width: '100%' }}
          onChange={handleChangeType}
          value={type}
          options={BILLING_TYPES.map(({ value, label }) => ({ value, label }))}
        />
      </div>
      {Component && <Component clientId={clientId} onSuccess={onSuccess} title={false} />}
    </>
  );
}

BillingGeneralForm.propTypes = {
  clientId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired
};
