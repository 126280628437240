import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';

import { CurrentUserContext } from '../../global-contexts';
import { DELETE_CLIENT_COMMENT } from '../../queries/client/comment';

import { Avatar, Comment, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import ClientCommentForm from '../forms/client-comment';
import Timestamp from '../timestamp';

function ClientComment(props) {
  const { clientId, clientComment } = props;
  const { id, account, body, createdAt, updatedAt, clientCommentsByParentCommentId } = clientComment;
  const [deleteClientComment] = useMutation(DELETE_CLIENT_COMMENT);
  const [currentUser] = useContext(CurrentUserContext);
  const [isEditing, setIsEditing] = useState(false);
  const isEditable = currentUser.id === account.id;
  const isDeletable = currentUser.id === account.id;

  function handleEditClick(event) {
    event.preventDefault();

    setIsEditing(true);
  }

  function handleEditSuccess() {
    setIsEditing(false);
  }

  function handleEditCancel() {
    setIsEditing(false);
  }

  function handleDeleteClick() {
    deleteClientComment({
      variables: { id },
      refetchQueries: ['GetClientComments']
    })
      .then(deleteClientCommentResult => {
        notification.success({
          message: 'Client comment deleted successfully'
        });
      })
      .catch(deleteClientCommentError => {
        notification.error({
          message: `Client comment delete failed with error: ${deleteClientCommentError.message}`
        });
      });
  }

  const actions = [];

  if (isEditable) {
    actions.push(
      <a className="client-comment__edit client-comment__action" href="#edit-comment" onClick={handleEditClick}>
        <EditOutlined /> Edit
      </a>
    );
  }

  if (isDeletable) {
    actions.push(
      <Popconfirm title={`Are you sure you want to delete this comment?`} onConfirm={handleDeleteClick}>
        <a className="client-comment__delete client-comment__action" href="#delete-comment">
          <DeleteOutlined /> Delete
        </a>
      </Popconfirm>
    );
  }

  return (
    <Comment
      actions={actions}
      author={<strong>{account.name}</strong>}
      avatar={
        <Avatar className="user__avatar" src={`https://api.adorable.io/avatars/285/${account.id}@adorable.io.png`} />
      }
      content={
        isEditing ? (
          <ClientCommentForm clientComment={clientComment} onSuccess={handleEditSuccess} onCancel={handleEditCancel} />
        ) : (
          <p className="client-comment__body">{body}</p>
        )
      }
      datetime={
        !_.isNull(updatedAt) ? (
          <span className="client-comment__updated-at">
            <EditOutlined /> Edited at <Timestamp datetime={updatedAt} />
          </span>
        ) : (
          <span className="client-comment__created-at">
            <Timestamp datetime={createdAt} />
          </span>
        )
      }
    >
      {clientCommentsByParentCommentId && (
        <>
          {_.map(clientCommentsByParentCommentId.nodes, subComment => {
            return <ClientComment clientId={clientId} clientComment={subComment} key={subComment.id} />;
          })}
          <Comment
            content={<ClientCommentForm clientId={clientId} parentCommentId={id} />}
            avatar={
              <Avatar
                className="user__avatar"
                src={`https://api.adorable.io/avatars/285/${currentUser.id}@adorable.io.png`}
              />
            }
          />
        </>
      )}
    </Comment>
  );
}

export default ClientComment;
