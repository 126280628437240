import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Field } from 'formik';

function getShouldShowError(formikProps, name, hideErrorsUntilSubmit) {
  const { errors, touched, submitCount } = formikProps;
  const submitCheck = hideErrorsUntilSubmit ? submitCount > 0 : true;
  const hasError = errors[name];
  const isTouched = touched[name];

  if (hasError && submitCount > 0) {
    return true;
  }

  return hasError && isTouched && submitCheck;
}

/**
 * strip out any field names which will by default in yup will be the variable name which is usually not presentable.
 * Examples:
 * * "clientId is a required field" -> "required"
 * * "percent must be greater than zero" -> "must be greater than zero"
 **/
function getErrorString(fieldError) {
  return _.isArray(fieldError)
    ? fieldError.map(getErrorString).join(', ')
    : fieldError.replace(/^\w+\sis a required field/, 'required').replace(/^\w+\smust/, 'must ');
}

function FormikInputGroup(props) {
  const {
    component,
    className,
    hideErrorsUntilSubmit,
    formikProps,
    name,
    label,
    subLabel,
    ...passthroughProps
  } = props;
  const { errors } = formikProps;
  const shouldShowError = getShouldShowError(formikProps, name, hideErrorsUntilSubmit);

  return (
    <div className={cx(className, 'formik-input-group')}>
      {label && (
        <label className="formik-input-group__label">
          {label}
          {subLabel && <span className="formik-input-group__sub-label">{subLabel}</span>}
        </label>
      )}
      <Field className="formik-input-group__input" component={component || 'input'} name={name} {...passthroughProps} />
      {shouldShowError && <span className="formik-input-group__error">{getErrorString(errors[name])}</span>}
    </div>
  );
}

FormikInputGroup.propTypes = {
  className: PropTypes.string,
  hideErrorsUntilSubmit: PropTypes.bool,
  formikProps: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

FormikInputGroup.defaultProps = {
  className: '',
  hideErrorsUntilSubmit: false,
  formikProps: null,
  name: null
};

export default FormikInputGroup;
