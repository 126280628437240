import '../../styles/components/forms/hardware-type-selector.css';

import { Select } from 'antd';
import { BILLING_MONTHLY_RATE_TYPES, BILLING_MONTHLY_RATE_DYNAMIC_DELIVERY_TYPES } from '../../constants';
import { withFormikAnt } from '../../hocs';

export function BillingMonthlyRateTypeSelector(props) {
  return <Select options={BILLING_MONTHLY_RATE_TYPES.map(({ label, value }) => ({ label, value }))} {...props} />;
}

export const BillingMonthlyRateTypeSelectorFormik = withFormikAnt()(BillingMonthlyRateTypeSelector);

export function BillingMonthlyDynamicDeliveryRateTypeSelector(props) {
  return <Select options={BILLING_MONTHLY_RATE_DYNAMIC_DELIVERY_TYPES} {...props} />;
}

export const BillingMonthlyDynamicDeliveryRateTypeSelectorFormik = withFormikAnt()(
  BillingMonthlyDynamicDeliveryRateTypeSelector
);
