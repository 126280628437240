import React from 'react';
import PropTypes from 'prop-types';
import { Select, Tag } from 'antd';
import { withFormikAnt } from '../hocs';

import { COMPANY_TYPES } from '../constants';

export function CompanyTypeIcon({ type, style, iconStyle }) {
  const typeSpec = COMPANY_TYPES.find(t => t.value === type);
  return (
    <span style={style}>
      {typeSpec.icon}
      &nbsp;
      {typeSpec.label}
    </span>
  );
}

CompanyTypeIcon.propTypes = {
  type: PropTypes.oneOf(COMPANY_TYPES.map(t => t.value)).isRequired
};

export function CompanyTypeSelector(props) {
  return (
    <Select {...props}>
      {COMPANY_TYPES.map(state => (
        <Select.Option key={state.value} value={state.value}>
          {state.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export const FormikCompanyTypeSelector = withFormikAnt()(CompanyTypeSelector);

export function CompanyTypeTag({ type }) {
  return (
    <Tag>
      <CompanyTypeIcon type={type} />
    </Tag>
  );
}

export function CompanyTypeTags({ types }) {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {types.map(type => (
        <CompanyTypeTag key={type} type={type} style={{ marginRight: '1em' }} />
      ))}
    </div>
  );
}
