import _ from 'lodash';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Avatar, Comment, Typography, Skeleton } from 'antd';

import { CurrentUserContext } from '../../global-contexts';
import PaginatedQuery from '../paginated-query';
import SectionHeader from '../section-header';
import ClientCommentForm from '../forms/client-comment';
import ClientComment from './comment';

import { GET_CLIENT_COMMENTS } from '../../queries/client/comment';

function ClientComments(props) {
  const { clientId } = props;
  const [currentUser] = useContext(CurrentUserContext);

  return (
    <div className="client-comments">
      <SectionHeader
        left={
          <Typography.Title level={2} style={{ marginBottom: 0 }}>
            Comments
          </Typography.Title>
        }
      />
      <PaginatedQuery query={GET_CLIENT_COMMENTS} variables={{ clientId, first: 9 }} notifyOnNetworkStatusChange={true}>
        {getClientCommentsResponse => {
          let content;

          const { data, networkStatus } = getClientCommentsResponse;

          // loading state
          if (networkStatus === 1) {
            content = <Skeleton />;
          }
          // empty state
          else if (
            networkStatus === 7 &&
            _.isEmpty(_.get(data, 'clientComments.edges')) &&
            !_.get(data, 'clientComments.pageInfo.hasNextPage')
          ) {
            content = <p>No comments found</p>;
          }
          // results state
          else {
            const clientComments = _.map(data?.clientComments?.edges, ({ node }) => ({ ...node, key: node.id }));

            content = _.map(clientComments, clientComment => {
              return <ClientComment clientId={clientId} clientComment={clientComment} key={clientComment.id} />;
            });
          }

          return <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '15px' }}>{content}</div>;
        }}
      </PaginatedQuery>
      <Comment
        content={<ClientCommentForm clientId={clientId} />}
        avatar={
          <Avatar
            className="user__avatar"
            src={`https://api.adorable.io/avatars/285/${currentUser.id}@adorable.io.png`}
          />
        }
      />
    </div>
  );
}

ClientComments.propTypes = {
  clientId: PropTypes.number.isRequired
};

export default ClientComments;
