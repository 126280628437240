import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

function Logo(props) {
  return (
    <Link className={`${props.className} logo`} to="/">
      <img className="logo__image" src="/meadow-logomark-purple.svg" alt="Internal Tools Logo" />
    </Link>
  );
}

Logo.propTypes = {
  className: PropTypes.string
};

Logo.defaultProps = {
  className: ''
};

export default Logo;
