import _ from 'lodash';
import produce from 'immer';

/**
 * Turns a yes / no ant filter in a isNull graphql filter
 * @param {object} params
 * @param {string} params.fieldName - name of the field
 * @param {object} params.filters - ant filters from the table component
 * @param {object} params.variables - hook state to pass into graphql query variables
 * @param {function} params.setVariables - state setter
 */
export function setGraphqlNullFilterVariableFromAntBooleanFilter({ fieldName, filters, setVariables }) {
  if (!filters) {
    return;
  }
  if (filters[fieldName]) {
    if (filters[fieldName].length === 1) {
      setVariables(
        produce(state => {
          state.filter = { ...state.filter, [fieldName]: { isNull: !filters[fieldName][0] } };
        })
      );
    } else {
      setVariables(
        produce(state => {
          if (state.filter) {
            delete state.filter[fieldName];
          }
          if (_.isEmpty(state.filter)) {
            delete state.filter;
          }
        })
      );
    }
  } else if (_.isEmpty(filters)) {
    setVariables(
      produce(state => {
        if (state.filter) {
          delete state.filter[fieldName];
          if (_.isEmpty(state.filter)) {
            delete state.filter;
          }
        }
      })
    );
  }
}

/**
 * Turns a yes / no ant filter in a true false graphql filter
 * @param {object} params
 * @param {string} params.fieldName - name of the field
 * @param {object} params.filters - ant filters from the table component
 * @param {object} params.variables - hook state to pass into graphql query variables
 * @param {function} params.setVariables - state setter
 */
export function setGraphqlEqualToFilterVariableFromAntFilterValue({ fieldName, filters, setVariables }) {
  if (!filters) {
    return;
  }
  if (filters[fieldName]) {
    if (filters[fieldName].length === 1) {
      setVariables(
        produce(state => {
          state.filter = { ...state.filter, [fieldName]: { equalTo: filters[fieldName][0] } };
        })
      );
    } else {
      setVariables(
        produce(state => {
          if (state.filter) {
            delete state.filter[fieldName];
          }
          if (_.isEmpty(state.filter)) {
            delete state.filter;
          }
        })
      );
    }
  } else if (_.isEmpty(filters)) {
    setVariables(
      produce(state => {
        if (state.filter) {
          delete state.filter[fieldName];
          if (_.isEmpty(state.filter)) {
            delete state.filter;
          }
        }
      })
    );
  }
}

export function setGraphqlOrderFromAntSorter({ sorter, setVariables, otherOrderBy = ['ID_DESC'] }) {
  if (!Array.isArray(sorter)) {
    sorter = [sorter];
  }
  const setColumns = sorter.filter(s => !!s.order);

  setVariables(
    produce(state => {
      if (setColumns.length === 0) {
        delete state.orderBy;
        return;
      }
      state.orderBy = _.uniq([
        ...setColumns.map(sort => {
          const field = getGraphqlSortFieldKeyFromAnyKey(sort.columnKey);
          const order = sort.order === 'ascend' ? 'ASC' : 'DESC';
          return `${field}_${order}`;
        }),
        ...otherOrderBy
      ]);
    })
  );
}

function getGraphqlSortFieldKeyFromAnyKey(antKey) {
  return _.snakeCase(antKey).toLocaleUpperCase();
}

function getFieldAndSortFromOrderBy(orderByStr) {
  const parts = orderByStr.split('_');
  const sort = parts.pop();
  const field = parts.join('_');
  return [field, sort];
}

export function getAntSortOrderFromGraphqlVariables({ variables, key }) {
  const field = variables.orderBy?.find(field => {
    return getFieldAndSortFromOrderBy(field)[0] === getGraphqlSortFieldKeyFromAnyKey(key);
  });
  if (field) {
    const sort = field.split('_').pop();
    if (sort === 'ASC') {
      return 'ascend';
    }
    return 'descend';
  }
  return null;
}

export function setAnyFormErrorsFromGraphQlError(error, formikProps) {
  if (error.graphQLErrors) {
    const formErrors = error.graphQLErrors.reduce(
      (accumulatedFormErrors, graphqlError) => ({ ...accumulatedFormErrors, ...graphqlError.userFormFeedback }),
      {}
    );
    const transformedErrors = {};
    for (const prop in formErrors) {
      const lastPart = prop.split('.').pop();
      transformedErrors[lastPart] = formErrors[prop];
    }
    formikProps.setErrors(transformedErrors);
  }
}
