import _ from 'lodash';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { Button, Modal, Typography, Skeleton, Card } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import Account from '../components/accounts/account-card';
import InviteForm from '../components/forms/invite-account-form';
import SectionHeader from '../components/section-header';
import { GET_ACCOUNTS } from '../queries/account';
import PageError from '../components/page-error';
import RequirePermission from '../components/require-permission';

function TeamMeadowPage(props) {
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const getAccountsResponse = useQuery(GET_ACCOUNTS, { notifyOnNetworkStatusChange: true });
  const { data, loading, error } = getAccountsResponse;

  const accounts = _.get(data, 'accounts.nodes', []);

  function handleInviteClick() {
    setIsInviteModalVisible(true);
  }

  function handleInviteSuccess() {
    setIsInviteModalVisible(false);
  }

  if (error) {
    return <PageError error={error} />;
  }

  return (
    <div>
      <SectionHeader
        left={<Typography.Title style={{ marginBottom: 0 }}>Team Meadow</Typography.Title>}
        right={
          <RequirePermission permission="MANAGE_ACCOUNTS">
            <Button type="primary" icon={<UserAddOutlined />} onClick={handleInviteClick}>
              Invite Team Member
            </Button>
          </RequirePermission>
        }
      />
      <div className="accounts-list" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '15px' }}>
        {loading &&
          Array(3)
            .fill(null)
            .map((_, idx) => (
              <Card key={`loading_${idx}`}>
                <Skeleton avatar />
              </Card>
            ))}
        {_.map(accounts, account => {
          return <Account account={account} key={account.id} />;
        })}
      </div>
      <Modal
        destroyOnClose
        footer={null}
        centered
        visible={isInviteModalVisible}
        onCancel={() => setIsInviteModalVisible(false)}
      >
        <Typography.Title level={2}>Invite Team Member</Typography.Title>
        <InviteForm onSuccess={handleInviteSuccess} />
      </Modal>
    </div>
  );
}

export default TeamMeadowPage;
