import _ from 'lodash';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Modal, Table, Tag, Typography, Skeleton, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import getLoadingState from '../utils/get-loading-state';
import { usePaginatedQuery } from '../components/paginated-query';
import SectionHeader from '../components/section-header';
import ClientForm from '../components/forms/client-form';
import ClientStatusTag from '../components/client/client-status-tag';
import { CompanyTypeSelector, CompanyTypeTags } from '../components/company-type';
import CompanySizeSelector from '../components/company-size-selector';
import ClientStatusSelector from '../components/forms/client-status-selector';
import { GET_CLIENTS } from '../queries/client/client-queries';
import SelectFilter, { isFiltered } from '../components/tables/select-filter';
import PageError from '../components/page-error';
import {
  getAntSortOrderFromGraphqlVariables,
  setGraphqlEqualToFilterVariableFromAntFilterValue,
  setGraphqlOrderFromAntSorter
} from '../utils/graphql';
import { renderDatetimeAsDate } from '../utils/datetime';
import HeaderFilters from '../components/tables/header-filters';

function Clients(props) {
  const { history } = props;
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [variables, setVariables] = useState({});

  const res = usePaginatedQuery(GET_CLIENTS, {
    variables,
    notifyOnNetworkStatusChange: true
  });

  function handleTableChange(pagination, filters, sorter) {
    setGraphqlEqualToFilterVariableFromAntFilterValue({ fieldName: 'primaryOrganizationId', filters, setVariables });
    setGraphqlOrderFromAntSorter({ sorter, setVariables });
  }

  const { data, loading, fetchMore, error } = res;
  const loadingState = getLoadingState(res);
  const clients = _.map(data?.clients.edges, ({ node }) => ({ ...node, key: node.id }));
  return (
    <>
      <SectionHeader
        left={<Typography.Title style={{ marginBottom: 0 }}>Clients</Typography.Title>}
        right={
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsCreateModalVisible(true)}>
            Create Client
          </Button>
        }
      />
      {error && <PageError error={error} />}
      {!error && (
        <HeaderFilters
          variables={variables}
          setVariables={setVariables}
          searchClient="direct"
          searchOrganization="id"
        />
      )}
      {!error && loadingState === 'FIRST_LOADING' && <Skeleton />}
      {!error && loadingState !== 'FIRST_LOADING' && (
        <>
          <Table
            dataSource={clients}
            pagination={false}
            size="middle"
            loading={loading}
            bordered
            onChange={handleTableChange}
            onRow={record => {
              return {
                style: { cursor: 'pointer' },
                onClick: e => {
                  props.history.push(`/clients/${record.id}`);
                }
              };
            }}
          >
            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'name' })}
              title="Name"
              dataIndex="name"
              key="name"
              render={(name, record) => {
                return (
                  <Link className="link--normal-text" to={`/clients/${record.id}`} onClick={e => e.preventDefault()}>
                    <strong>{name}</strong>
                  </Link>
                );
              }}
            />

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'createdAt' })}
              title="Created"
              dataIndex="createdAt"
              key="createdAt"
              render={renderDatetimeAsDate}
              filtered={isFiltered({ variables, dateRange: true, fieldName: 'createdAt' })}
              filterDropdown={
                <SelectFilter
                  dateRange
                  fieldName="createdAt"
                  Selector={DatePicker.RangePicker}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
            />

            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'primaryOrganizationId' })}
              title="Primary Org"
              dataIndex="primaryOrganization"
              key="primaryOrganizationId"
              render={primaryOrganization => {
                return <span>{primaryOrganization ? primaryOrganization.organizationId : 'N/A'}</span>;
              }}
            />
            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'companyTypes' })}
              title="Type"
              dataIndex="companyTypes"
              key="companyTypes"
              filtered={isFiltered({ array: true, fieldName: 'companyTypes', variables })}
              filterDropdown={
                <SelectFilter
                  array
                  fieldName="companyTypes"
                  Selector={CompanyTypeSelector}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
              render={v => <CompanyTypeTags types={v} />}
            />
            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'status' })}
              title="Status"
              dataIndex="status"
              key="status"
              filtered={isFiltered({ array: true, fieldName: 'status', variables })}
              filterDropdown={
                <SelectFilter
                  fieldName="status"
                  Selector={ClientStatusSelector}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
              render={v => <ClientStatusTag status={v} />}
            />
            <Table.Column
              sorter={{ multiple: 1 }}
              sortOrder={getAntSortOrderFromGraphqlVariables({ variables, key: 'size' })}
              title="Size"
              dataIndex="size"
              key="size"
              render={size => <Tag>{size}</Tag>}
              filtered={isFiltered({ array: true, fieldName: 'size', variables })}
              filterDropdown={
                <SelectFilter
                  fieldName="size"
                  Selector={CompanySizeSelector}
                  variables={variables}
                  setVariables={setVariables}
                />
              }
            />
          </Table>
          <Button
            block
            loading={loading}
            size="large"
            disabled={!_.get(data, 'clients.pageInfo.hasNextPage')}
            onClick={() => fetchMore()}
          >
            Load More
          </Button>
          <Modal footer={null} centered visible={isCreateModalVisible} onCancel={() => setIsCreateModalVisible(false)}>
            <Typography.Title level={2}>Create a new client</Typography.Title>
            <ClientForm
              onSuccess={clientData => {
                history.push(`/clients/${clientData.id}`);
              }}
            />
          </Modal>
        </>
      )}
    </>
  );
}

export default withRouter(Clients);
