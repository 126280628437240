import _ from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

/**
 * @deprecated use useMutation instead.
 * See https://github.com/trojanowski/react-apollo-hooks/issues/90
 */
export function withMutation(doc) {
  return Component => {
    function WrappedWithMutation(props) {
      return (
        <Mutation mutation={doc}>
          {(mutateFn, mutateState) => <Component mutateFn={mutateFn} mutateState={mutateState} {...props} />}
        </Mutation>
      );
    }
    WrappedWithMutation.displayName = `withMutatation(${Component.displayName || Component.name})`;
    return WrappedWithMutation;
  };
}

function emptyStringToNull(v) {
  if (v === '') {
    return null;
  }
  return v;
}

function getAntValue(argument) {
  if (!_.isUndefined(_.get(argument, 'target.value'))) {
    return emptyStringToNull(argument.target.value);
  }

  return emptyStringToNull(argument);
}

export function withFormikAnt() {
  return Component => {
    function FormikCompatibleAnt(props) {
      const { field, form, ...passthroughProps } = props;

      return (
        <Component
          name={field.name}
          value={field.value}
          variables={{ includeArchived: 'NO' }}
          onChange={argument => {
            const value = getAntValue(argument);

            form.setFieldValue(field.name, value);
          }}
          onBlur={() => {
            form.setFieldTouched(field.name);
          }}
          {...passthroughProps}
        />
      );
    }

    FormikCompatibleAnt.displayName = `withFormikAnt(${Component.displayName || Component.name})`;

    return FormikCompatibleAnt;
  };
}
